export const addItemToCart = (data, netPrice, taxItems) => ({
    type: 'ADD_ITEM_TO_CART',
    data,
    netPrice,
    taxItems
});

export const updateItemCart = (data, netPrice, taxItems) => ({
    type: 'UPDATE_ITEM_CART',
    data,
    netPrice,
    taxItems
});

export const addRemoveItemFromCart = (data, netPrice, taxItems) => ({
    type: 'REMOVE_ITEM_CART',
    data,
    netPrice,
    taxItems
});

export const emptyCart = (data) => ({
    type: 'EMPTY_CART',
    data
});

export const removeFoodcourtdata = (data) => ({
    type: 'REMOVE_FOODCOURT_DATA',
    data
});

export const setRestFoodCourt = (data) => ({
    type: 'SET_REST_FOOD_COURT',
    data
});

export const setCustomer = (data) => ({
    type: 'SET_CUSTOMER',
    data
});

export const resetCustomer = () => ({
    type: 'RESET_CUSTOMER'
});

export const setPaymentTxn = (data) => ({
    type: 'SET_PAYMENT_TXN',
    data
});

export const setOrderData = (data) => ({
    type: 'SET_ORDER_DATA',
    data
});

export const setOrderId = (data) => ({
    type: 'SET_ORDER_ID',
    data
});

export const reOrder = (data) => ({
    type: 'REORDER',
    data
});

export const setChecksum = (data) => ({
    type: 'SET_CHECKSUM',
    data
});

export const setFoodcourtInOrder = (data) => ({
    type: 'SET_FOODCOURT_IN_ORDER',
    data
});

export const setGokashInOrder = (data) => ({
    type: 'SET_GOKASH_IN_ORDER',
    data
});

export const setOrderType = (data) => ({
    type: 'SET_ORDER_TYPE',
    data
});

export const setEmpPerkInOrder = (data) => ({
    type: 'SET_EMP_PERK_IN_ORDER',
    data
});

export const setGokashPlusAmount = (data) => ({
    type: 'SET_GOKASH_PLUS_AMOUNT',
    data
});

export const setPerkInfoInOrder = (data) => ({
    type: 'SET_PERK_INFO_IN_ORDER',
    data
})

export const setParcelCharges = (data) => ({
    type: 'SET_PARCEL_CHARGES',
    data
});

export const setDeliveryAddress = (data) => ({
    type: 'SET_DELIVERY_ADDRESS',
    data
});

export const setDiscountSplit = (data) => ({
    type: 'SET_DISCOUNT_SPLIT',
    data
});

export const setDiscountId = (data) => ({
    type: 'SET_DISCOUNT_ID',
    data
});
