import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { withRouter, NavLink } from 'react-router-dom';

import { setSelectedRestaurant } from './../../actions/restActions';
import { removeItemFromCart, setOrderId, setPerkInfoInOrder, setGokashPlusAmount } from './../../actions/orderActions';
import { setError, setPgData, setPaytmInfo } from './../../actions/paymentActions';
import { toggleLoader } from './../../actions/loaderActions';
import { openModal, showTimer } from './../../actions/modalActions';
import { latestGokash, setGokashDiscount, setPerkInfo, setGokashPlus } from './../../actions/customerActions';
import { showButtons } from './../../actions/cartActions';
import { getCreditDiscount, getGokash, getEmployeeOffer, getGokashPlus } from './../../utils/CustomerLibrary';
import _ from 'lodash';
import { loadJsLibraries } from './../../utils/LoadLibrary';
import Modal from 'react-modal';
import { generateTxn, getSavedCard, createFoodOrder, createTempFoodOrder, getResponse } from './../../utils/PaymentGateway';
import { toast } from 'react-toastify';


class FinalOrderSummary extends React.Component {
	sodexo;
	accesstoken = 'iyvclEkLFYAlBHW5O3aJiXqAQuwEZR5IWshjbweLtHbuA1MGjRdLEICmq6xGfCkk';

	constructor(props) {
		super(props);
		let companydiscount;
		// this.props.showButtons(false);
		if (this.props.order.restaurantcustomer) {
			this.gokashBalance();
			this.gokashCreditDiscount();
			this.walletBalance();
		}
		if (this.props.customer.perkInfo && this.props.customer.perkInfo.discountfromcompany) {
			companydiscount = this.props.customer.perkInfo.discountfromcompany;
		}
		else companydiscount = 0;

		// setTimeout(() => {
		// 	if ((companydiscount && companydiscount !== this.props.order.discountfromcompany) && (this.props.customer.discountfromcredit !== this.props.order.discountfromcredit)) {
		// 		this.setState({ error: 'Cart value modified due to change in Perk/GoKash balance' });
		// 	}
		// 	else if (companydiscount && companydiscount !== this.props.order.discountfromcompany) {
		// 		this.setState({ error: 'Cart value modified due to change in Perk discount/Perk balance' });
		// 	}
		// 	else if (this.props.customer.discountfromcredit !== this.props.order.discountfromcredit) {
		// 		this.setState({ error: 'Cart value modified due to change in GoKash discount/GoKash balance' });
		// 	}
		// 	else {
		// 		this.setState({ error: ' ' });
		// 	}
		// }, 1000);

		this.state = { time: {}, seconds: 300 };
		this.timer = 0;
	}

	state = {
		error: ''
	};

	getEmployeePerk = (totalprice) => {
		const { parentId, foodcourt } = this.props.order;
		const cookie = this.props.cookies.cookies;
		const body = {
			restaurantid: parentId,
			foodcourtid: foodcourt,
			ordervalue: totalprice,
			orders: this.props.order
		}
		if (!_.isEmpty(cookie) && cookie.token)
			return getEmployeeOffer(body, cookie.token).then(res => {
				if (res && res.data.discountfromcompany) {
					this.props.setPerkInfo(res.data);
					this.props.setPerkInfoInOrder(res.data);
				}
				else {
					this.props.setPerkInfo({});
					this.props.setPerkInfoInOrder({});
				}
			})
	}

	gokashBalance = () => {
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token)
			return getGokash(cookie.id, cookie.token).then(res => {
				res && res.data ? this.props.latestGokash(res.data) : '';
			})
	}

	walletBalance = () => {
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token)
			return getGokashPlus(cookie.id, cookie.token).then(res => {
				if (res) { res.data ? this.props.setGokashPlus(res.data) : 0 };
			})
	}

	gokashCreditDiscount = () => {
		const restaurantid = this.props.order.parentId;
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token)
			return getCreditDiscount(restaurantid, cookie.token).then(res => {
				res && res.data ? this.props.setGokashDiscount(res.data) : '';
			})
	}


	getSelected = () => {
		const { cc, dc, nb, upi, wallet, mealvouchers } = this.props.paymentpage.paymentoption;
		let selectedOption;

		if (cc.isActive) {
			selectedOption = { paymentgateway: 'paytm', paymentmode: 'credit-card', ...cc };
		} else if (dc.isActive) {
			selectedOption = { paymentgateway: 'paytm', paymentmode: 'debit-card', ...dc };
		} else if (nb.isActive && nb.bankcode) {
			selectedOption = { paymentgateway: 'paytm', paymentmode: 'net-banking', ...nb };
		} else if (upi.isActive) {
			selectedOption = { paymentgateway: 'payu', paymentmode: 'upi', ...upi };
		} else if (upi.isActive && upi.gpay.isActive) {
			selectedOption = { paymentgateway: 'gpay', paymentmode: 'gpay', ...upi };
		} else if (wallet.isActive && wallet.paytm.isActive) {
			selectedOption = { paymentgateway: 'paytm', paymentmode: 'paytm-cash', ...wallet };
		} else if (wallet.isActive && wallet.amazonpay.isActive) {
			selectedOption = { paymentgateway: 'amazonpay', paymentmode: 'amazonpay', ...wallet };
		} else if (wallet.isActive && wallet.gokashplus.isActive) {
			selectedOption = { paymentgateway: 'gokhana', paymentmode: 'gokash-plus', ...wallet };
		} else if (mealvouchers.isActive && mealvouchers.sodexo.isActive) {
			selectedOption = { paymentgateway: 'sodexo', ...mealvouchers.sodexo };
		} else if (mealvouchers.ticketrestaurant) {
			selectedOption = { paymentgateway: 'paytm', paymentmode: 'ticketrestaurant' };
		}
		return selectedOption;
	}

	getDeviceOS = () => {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}

		return "unknown";
	}

	extraNotesChecksum = (_o, checksum) => {
		if (_o && !_.isEmpty(_o.items)) {
			let txt = '';
			let dtxt = '';
			let t_txt = '';
			if (!_.isEmpty(_o.items)) {
				_o.items.forEach((item, index) => {
					if (index > 0) txt += "-"
					txt += item.restaurantmenuitem + "_" + item.count
				});
				txt += "|" + _o.parcelcharge || 0;
			}
			if (!_.isEmpty(_o.taxitems)) {
				_o.taxitems.forEach(item => {
					t_txt += item.name + "_" + item.amount;
				})
			}
			if (!_.isEmpty(_o.discountsplit)) {
				_o.discountsplit.forEach((item, index) => {
					dtxt += (item.id || item.name) + "_" + item.promotedgroup + "_" + item.amount + "_" + item.ontax;
				})
			}
			return { checksum: checksum, ordertext: txt, discounttext: dtxt, tax_texts: t_txt }
		}
	}

	secondsToTime = (secs) => {
		let hours = Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		let obj = {
			"h": hours,
			"m": minutes,
			"s": seconds
		};
		return obj;
	}

	componentDidMount = () => {
		let timeLeftVar = this.secondsToTime(this.state.seconds);
		this.setState({ time: timeLeftVar });
	}

	componentWillUnmount() {
		this.props.showTimer(false);
		setTimeout(() => {
			this.props.toggleLoader(false);
		}, 3000);
	}

	startTimer = () => {
		if (this.timer == 0 && this.state.seconds > 0) {
			this.timer = setInterval(this.countDown, 1000);
		}
	}

	countDown = () => {
		const { cookies } = this.props.cookies;
		// Remove one second, set state so a re-render happens.
		let seconds = this.state.seconds - 1;
		getResponse(this.props.modal.gpayTransactionId, cookies.token || this.accesstoken).then(res => {
			if (res.data.status === 'SUCCESS') {
				const transactionId = res.data.id;

				//stop the timer, close the popup and redirect to pgResponse
				clearInterval(this.timer);
				this.props.showTimer(false);
				this.props.history.push('/pg-response', { response: { txn: transactionId } });
			}

			else if (res.data.status === 'FAILURE' || res.data.status === "DECLINED") {
				clearInterval(this.timer);
				this.props.showTimer(false);
				this.props.history.push('/payment-failed');
			}
		}).catch(e => {
			console.log(e);
			clearInterval(this.timer);
		});

		this.setState({
			time: this.secondsToTime(seconds),
			seconds: seconds,
		});

		// Check if we're at zero.
		if (seconds == 0) {
			clearInterval(this.timer);
			this.props.showTimer(false);
			// request timed out
			this.props.history.push('/payment-failed', { response: 'expired' });
		}
	}



	showTimerModal = (isOpen) => {
		this.startTimer();
		return (
			<Modal
				isOpen={isOpen}
				contentLabel="Common Modal"
				className="Modal"
				overlayClassName="Overlay"
				ariaHideApp={false}

			>
				<div className="modal-dialog">
					<div className="modal-content modal-popup" style={{ height: '300px', width: '250px' }}>
						<h3>Complete the payment within 5 minutes to place your order.</h3>
						<h2>{this.state.time.m}m : {this.state.time.s}s</h2>
					</div>
				</div>
			</Modal>
		)
	}

	goToPG = async () => {
		const { wallet } = this.props.paymentpage.paymentoption;
		const { gokashplus } = wallet;
		let plusBalance = false;
		(wallet.isActive && gokashplus.isActive) ? plusBalance = true : false;
		if (plusBalance && (this.props.customer.gokashPlus >= this.props.order.totalprice)) {
			this.props.setGokashPlusAmount(this.props.order.totalprice);
		}
		this.props.toggleLoader(true);
		const { grossamount, taxamount, parcelcharge } = this.props.order;
		let tlPrice = grossamount + taxamount + parcelcharge;
		if (this.props.order.restaurantcustomer) {
			await this.gokashBalance();
			await this.walletBalance();
			await this.gokashCreditDiscount();
			await this.getEmployeePerk(tlPrice);
		}
		const { items } = this.props.order;
		if (items.length === 0) {
			this.props.history.push('/');
		}
		loadJsLibraries();
		if (this.props.order.customerno) {
			if (this.props.order.totalprice === 0) {
				this.props.toggleLoader(false);
				this.props.history.push('/processing-order');
			}
			else if (plusBalance && (this.props.customer.gokashPlus >= this.props.order.totalprice)) {
				this.props.toggleLoader(false);
				this.props.history.push('/processing-order');
			}
			else {
				const isSelected = this.getSelected();
				const { cookies } = this.props.cookies;
				let order = this.props.order;
				if (this.props.customer.isAuthenticated) this.accesstoken = cookies.token;
				delete order.orderResponse;
				if (order.restaurantcustomer == "") {
					this.sodexo = '';
				} else {
					const cardData = await getSavedCard(order.restaurantcustomer, this.accesstoken);
					this.sodexo = cardData.data.sodexo;
				}

				if (!isSelected) {
					window.scrollTo(0, 430);
					this.props.setError('Please select payment option...');
					this.props.toggleLoader(false);
				}
				else if (isSelected.paymentgateway === 'payu' && !isSelected.vpa.isActive) {
					this.props.setError('Please enter VPA address');
					this.props.toggleLoader(false);
				}
				else {
					if (plusBalance && (this.props.customer.gokashPlus < this.props.order.totalprice)) {
						window.scrollTo(0, 430);
						this.props.setError('Insufficient Balance');
						this.props.toggleLoader(false);
					}
					else {
						let paytminfo = {};
						let pgdata = {};

						const body = {
							customer: cookies.id,
							amount: order.totalprice,
							paymentgateway: isSelected.paymentgateway,
							createdplatform: 'gokhanacustomerweb',
							channel: 'browser',
							customer_mobile: order.customerno,
							customer_email: cookies.email || order.customeremail,
							checksum: [this.props.order.checksum],
							extra_notes: [this.extraNotesChecksum(order, this.props.order.checksum)],
							callbackurl: 'https://order.gokhana.com/pg-response'
						};
						if (isSelected.paymentgateway === 'paytm') {
							body.paymentmodePreselected = true;
							isSelected.bankcode ? body.bankname = isSelected.bankcode : '';

						}
						if (isSelected.paymentmode === 'gpay') {
							body.deviceos = this.getDeviceOS();

						}
						if (isSelected.paymentmode) {
							body.paymentmode = isSelected.paymentmode;
							body.paymenttype = isSelected.paymentmode;
						}

						if (isSelected.paymentgateway === 'payu') {
							body.customer_vpa = this.props.paymentpage.paymentoption.upi.vpa.vpaId;
							body.sourceId = this.props.paymentpage.paymentoption.upi.vpa.vpaId;
						}

						if (isSelected.paymentgateway === 'sodexo' && isSelected.savedcard) {
							this.sodexo.length !== 0 ? (
								body.sourceId = this.sodexo[0].sourceId
							) : ''
						}

						generateTxn(body, this.accesstoken).then(result => {
							if (isSelected.paymentgateway === 'paytm') {
								paytminfo = result.data.paytminfo;
								pgdata.pg = 'paytm';
							} else if (isSelected.paymentgateway === 'sodexo') {
								paytminfo = {};
								pgdata.pg = 'sodexo';
							}
							else if (isSelected.paymentgateway === 'amazonpay') {
								paytminfo = {};
								pgdata.pg = 'amazonpay';
							}
							else if (isSelected.paymentgateway === 'gokhana') {
								paytminfo = {};
								pgdata.pg = 'gokhana';
							}
							else if (isSelected.paymentgateway === 'payu') {
								paytminfo = {};
								pgdata.pg = 'payu';
							}
							pgdata.redirectUrl = result.data.redirectUrl;

							this.props.setPaytmInfo(paytminfo);
							this.props.setPgData(pgdata);

							if (this.props.customer.perkInfo.perk && !this.props.customer.gokashDiscount.length) {
								let perkIndex = this.props.order.discountsplit.findIndex(ds => ds.id == this.props.customer.perkInfo.perk);
								if (perkIndex != -1 && this.props.customer.perkInfo.discountfromcompany === this.props.order.discountfromcompany) {
									if (result.data.paymenttype == "gpay") {
										if (result.data.vpainfo) {
											this.props.showTimer({ showTimer: true, transactionId: result.data.transactionid });
										}
										else {
											this.props.history.push('/payment-failed', { response: 'invalid' });
										}
									}
									else {
										if (result.data.message === "Invalid VPA") {
											this.props.toggleLoader(false);
											this.props.history.push('/payment-options');
											toast.error(result.data.message, {
												position: "bottom-center"
											});
										}
										else {
											createTempFoodOrder(order, this.accesstoken).then(() => {
												setTimeout(() => {
													this.props.history.push('/redirect-to-pg');
												}, 500);
											});
										}
									}
								}
								else {
									this.props.toggleLoader(false);
									this.props.history.push('/payment-options');
								}
							}
							else if (this.props.customer.gokashDiscount.length && !this.props.customer.perkInfo.perk) {
								let goKashIndex = this.props.order.discountsplit.findIndex(ds => ds.id == this.props.customer.gokashDiscount[0].id);
								if (goKashIndex != -1 && this.props.customer.gokash === this.props.customer.latestgokash) {
									if (result.data.paymenttype == "gpay") {
										if (result.data.vpainfo) {
											this.props.showTimer({ showTimer: true, transactionId: result.data.transactionid });
										}
										else {
											this.props.history.push('/payment-failed', { response: 'invalid' });
										}
									}
									else {
										if (result.data.message === "Invalid VPA") {
											this.props.toggleLoader(false);
											this.props.history.push('/payment-options');
											toast.error(result.data.message, {
												position: "bottom-center"
											});
										}
										else {
											createTempFoodOrder(order, this.accesstoken).then(() => {
												setTimeout(() => {
													this.props.history.push('/redirect-to-pg');
												}, 500);
											});
										}
									}
								}
								else {
									this.props.toggleLoader(false);
									this.props.history.push('/payment-options');
								}
							}
							else if (this.props.customer.gokashDiscount.length && this.props.customer.perkInfo.perk) {
								let perkIndex = this.props.order.discountsplit.findIndex(ds => ds.id == this.props.customer.perkInfo.perk);
								let goKashIndex = this.props.order.discountsplit.findIndex(ds => ds.id == this.props.customer.gokashDiscount[0].id);
								if ((perkIndex != -1 && this.props.customer.perkInfo.discountfromcompany === this.props.order.discountfromcompany) && (goKashIndex != -1 && this.props.customer.gokash === this.props.customer.latestgokash)) {
									if (result.data.paymenttype == "gpay") {
										if (result.data.vpainfo) {
											this.props.showTimer({ showTimer: true, transactionId: result.data.transactionid });
										}
										else {
											this.props.history.push('/payment-failed', { response: 'invalid' });
										}
									}
									else {
										if (result.data.message === "Invalid VPA") {
											this.props.toggleLoader(false);
											this.props.history.push('/payment-options');
											toast.error(result.data.message, {
												position: "bottom-center"
											});
										}
										else {
											createTempFoodOrder(order, this.accesstoken).then(() => {
												setTimeout(() => {
													this.props.history.push('/redirect-to-pg');
												}, 500);
											});
										}
									}
								}
								else {
									this.props.toggleLoader(false);
									this.props.history.push('/payment-options');
								}
							}
							else {
								if (result.data.paymenttype == "gpay") {
									if (result.data.vpainfo) {
										this.props.showTimer({ showTimer: true, transactionId: result.data.transactionid });
									}
									else {
										this.props.history.push('/payment-failed', { response: 'invalid' });
									}
								}
								else {
									if (result.data.message === "Invalid VPA") {
										this.props.toggleLoader(false);
										this.props.history.push('/payment-options');
										toast.error(result.data.message, {
											position: "bottom-center"
										});
									}
									else {
										createTempFoodOrder(order, this.accesstoken).then(() => {
											setTimeout(() => {
												this.props.history.push('/redirect-to-pg');
											}, 500);
										});
									}
								}
							}
						});
					}
				}
			}
		} else {
			this.props.toggleLoader(false);
			this.props.openModal('UserLogin', { buttonClick: false });
		}

	}

	render() {
		const { wallet } = this.props.paymentpage.paymentoption;
		const { gokashplus } = wallet;
		let plusBalance = false;
		(wallet.isActive && gokashplus.isActive) ? plusBalance = true : false;
		return (
			<div>
				<div className="col-md-3" id="sidebar">
					<div className="theiaStickySidebar">

						<div id="cart_box" >
							<h3>Order Summary <i className="icon_cart_alt pull-right"></i></h3>
							<table className="table table_summary">
								<tbody>
									{
										this.props.order.items.length !== 0 ? (
											this.props.order.items.map((item, index) => {
												return (

													<tr key={index}>
														<td title={item.menuitemname}>
															<strong>{item.count}x</strong>&nbsp;
															{item.menuitemname.length > 16 ? `${item.menuitemname.substring(0, 16)}...` : item.menuitemname}
															{
																item.features.length !== 0 ? (
																	item.features.map((option, index) => {
																		return <div key={index} title={option.name}><i className="icon-check-1"></i> {option.name.length > 18 ? `${option.name.substring(0, 18)}...` : option.name}</div>
																	})
																)
																	: <div></div>
															}
															{
																item.customeitems.length !== 0 ? (
																	item.customeitems.map((option, index) => {
																		return (
																			<div key={index} title={option.categoryname}>
																				<i className="icon-check-1"></i>
																				{option.categoryname.length > 18 ? `${option.categoryname.substring(0, 18)}...` : option.categoryname}
																				{
																					option.options.map(opt => {
																						return <div className="sub-options" title={opt.name}><i className="icon-minus"></i> {opt.name}</div>
																					})
																				}
																			</div>
																		)
																	})
																)
																	: <div></div>
															}

														</td>
														<td>
															<strong className="pull-right"> &#8377; {item.totalprice}</strong>
														</td>
													</tr>
												)
											})
										) : <tr><td><h4 style={{ textAlign: 'center' }}>Your cart is empty</h4></td></tr>
									}
								</tbody>
							</table>
							<hr />
							<table className="table table_summary">
								<tbody>
									{this.props.order.grossamount > 0 && <tr>
										<td>
											Subtotal <span className="pull-right">&#8377; {this.props.order.grossamount}</span>
										</td>
									</tr>}
									{
										this.props.order.items.length !== 0 ? (
											this.props.order.taxitems.map((item, index) => {
												if (item.amount > 0) {
													return (
														<tr key={index}>
															<td>
																{item.name}
																<span className="pull-right">
																	&#8377; {item.amount}
																</span>
															</td>
														</tr>
													)
												}
											})
										) : <tr></tr>
									}
									{
										(this.props.order.discountfromcompany > 0) ? (
											<tr style={{ color: 'green' }}>
												<td>
													Discount From Company <span className="pull-right">&#8377; {this.props.order.discountfromcompany.toFixed(2)}</span>
												</td>
											</tr>
										) : <tr></tr>
									}
									{
										(this.props.order.discountfromcredit > 0 && this.props.customer.gokashDiscount.length) ? (
											<tr style={{ color: 'green' }}>
												<td>
													Gokash Credit Applied <span className="pull-right">&#8377; {this.props.order.discountfromcredit.toFixed(2)}</span>
												</td>
											</tr>
										) : <tr></tr>
									}
									{
										this.props.order.parcelcharge > 0 ? (
											<tr>
												<td>
													Parcel Charge <span className="pull-right">&#8377; {this.props.order.parcelcharge}</span>
												</td>
											</tr>
										) : <tr></tr>
									}
									<tr>
										<td className="total">
											TOTAL <span className="pull-right">&#8377; {this.props.order.totalprice > 0 ? this.props.order.totalprice.toFixed(2) : 0}</span>
										</td>
									</tr>
								</tbody>
							</table>

							{
								this.props.order.items.length !== 0 ? (
									<div>
										<hr />
										<p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.state.error}</p>
										<p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.props.paymentpage.paymentoption.error}</p>
										<NavLink
											to="#"
											className="btn_full"
											onClick={this.goToPG}
										>{this.props.order.totalprice === 0 || (plusBalance && (this.props.customer.gokashPlus >= this.props.order.totalprice)) ? 'Place Order' : 'Pay Now'}</NavLink>
									</div>
								) : ''
							}

						</div>
					</div>
				</div>
				{this.props.modal.showTimer && <div>
					{this.showTimerModal(this.props.modal.showTimer)}
				</div>}
				<div className="col-md-12">
					<p id="mobile-pay-now-message" style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.props.paymentpage.paymentoption.error}</p>
					<NavLink
						to="#"
						id="mobile-pay-now"
						className="btn_full"
						onClick={this.goToPG}
					>{this.props.order.totalprice === 0 || (plusBalance && (this.props.customer.gokashPlus >= this.props.order.totalprice)) ? 'Place Order' : 'Pay Now'}</NavLink>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = {
	setSelectedRestaurant,
	removeItemFromCart,
	setError,
	setPgData,
	toggleLoader,
	setPaytmInfo,
	createFoodOrder,
	setOrderId,
	openModal,
	showTimer,
	latestGokash,
	setGokashDiscount,
	showButtons,
	setPerkInfo,
	setPerkInfoInOrder,
	setGokashPlusAmount,
	setGokashPlus
};

export default withRouter(withCookies(connect(state => state, mapDispatchToProps)(FinalOrderSummary)));