import React from 'react';
import {connect} from 'react-redux';

import {setNB} from './../../actions/paymentActions';

class NetBanking extends React.Component {

    setNetBanking = (e) => {
       const bankName = e.target.value;
       this.props.setNB(bankName);
    }

    render() {
        const {nb} = this.props;
        return (
            <div className="row">
                <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                        <label>All Banks</label>
                        <select className="form-control" onChange={this.setNetBanking}>
                            <option value="" selected>Select Bank</option>
                            <option value="ANDHRA">Andhra</option>    
                            <option value="AXIS">Axis Bank</option>
                            <option value="BOM">Bank of Maharashtra</option>
                            <option value="BHARAT">Bharat Bank</option>
                            <option value="CANARA">Canara Bank</option>
                            <option value="CSB">Catholic Syrian</option>
                            <option value="CITI">Citi Bank</option>
                            <option value="CITIUB">City Union</option>
                            <option value="CORP">Corporation Bank</option>
                            <option value="COSMOS">Cosmos Bank</option>
                            <option value="DCB">DCB</option>
                            <option value="DENA">DENA</option>
                            <option value="HDFC">HDFC</option>
                            <option value="ICICI">ICICI</option>
                            <option value="IDBI">IDBI</option>
                            <option value="INDB">Indian Bank</option>
                            <option value="IOB">Indian Overseas</option>
                            <option value="INDS">Indus</option>
                            <option value="JKB">Jammu & Kashmir Bank</option>
                            <option value="JSB">Janta Sahkari Bank</option>
                            <option value="KTKB">Karnataka Bank</option>
                            <option value="KOTAK">Kotak</option>
                            <option value="LVB">Laxmi Vilas</option>
                            <option value="OBPRF">Oriental Bank of Commerce</option>
                            <option value="PNB">PNB</option>
                            <option value="PSB">Punjab & Sind Bank</option>
                            <option value="STB">Saraswat Co-operative</option>
                            <option value="SBH">SBH</option>
                            <option value="SBI">SBI</option>
                            <option value="SBJ">SBJ</option>
                            <option value="SBM">SBM</option>
                            <option value="SBOP">SBOP</option>
                            <option value="SIB">South Indian</option>
                            <option value="STB">STB</option>
                            <option value="SVC">SVC</option>
                            <option value="TNMB">TNNB</option>
                            <option value="UCO">UCO Bank</option>
                            <option value="UBI">Union Bank of India</option>
                            <option value="UBI">United Bank of India</option>
                            <option value="VJYA">Vijaya Bank</option>
                            <option value="YES">Yes Bank</option>
                            
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => state.paymentpage.paymentoption,{setNB})(NetBanking);