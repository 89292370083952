import React from 'react';
import Sodexo from './Sodexo';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';

import ticketImg from './../../images/web-images/ticketrest.png';
import sodexImg from './../../images/web-images/sodexo.png';

import { selectMealVoucher } from './../../actions/paymentActions';
import { getSavedCard } from './../../utils/PaymentGateway';


class MealVoucher extends React.Component {
    cardStatus;

    componentDidMount() {
        this.checkCardSaved();
    }

    checkCardSaved = async () => {
        const { cookies } = this.props.cookies;
        const card = await getSavedCard(cookies.id, cookies.token);
        const { sodexo } = card.data;

        this.cardStatus = sodexo.length === 0 ? 0 : 1;
    }

    render() {
        const { sodexo, ticketrestaurant } = this.props;
        return (
            <div>
                <div className="row" style={{ marginBottom: '20px' }}>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ textAlign: 'center' }}>
                        <input
                            type="radio"
                            name="mealvoucher"
                            {...sodexo.isActive ? { checked: true } : {}}
                            onChange={() => this.props.selectMealVoucher('sodexo')}
                            style={{ verticalAlign: 'middle', height: '15px', width: '15px' }}
                        />
                        <img src={sodexImg} style={{ marginLeft: '10px', height: '30px' }} />
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ textAlign: 'center' }}>
                        <input
                            type="radio"
                            name="mealvoucher"
                            {...ticketrestaurant ? { checked: true } : {}}
                            onChange={() => this.props.selectMealVoucher('ticketrestaurant')}
                            style={{ verticalAlign: 'middle', height: '15px', width: '15px' }}
                        />
                        <img src={ticketImg} style={{ marginLeft: '10px', height: '30px' }} />
                    </div>
                </div>

                {
                    sodexo.isActive && this.cardStatus ? (
                        <Sodexo />
                    ) : ''
                }

            </div>
        );
    }
}

const mapStateToProps = state => state.paymentpage.paymentoption.mealvouchers;
const mapDispatchToProps = { selectMealVoucher };

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(MealVoucher));