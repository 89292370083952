import React from 'react';
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import { toggleLoader } from './../../actions/loaderActions';
require("bootstrap/dist/css/bootstrap.min.css");

import Order from './Order';


class OrderHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      countPerPage: 7
    };

  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  }
  componentDidMount() {
    this.props.toggleLoader(true);
    setTimeout(() => {
      this.props.toggleLoader(false);
    }, 3500);
  }

  render() {
    const myOrdersCss = {
      textAlign: "center",
      marginBottom: "60px",
      fontWeight: 'bold'
    };
    const paginationCss = {
      float: "right"
    };
    const { activePage, countPerPage } = this.state;
    let filteredOrderList = this.props.orderHistory.filter((order, index) => (index >= ((activePage * countPerPage) - countPerPage) && (index < countPerPage * activePage)))
    return (
      <div> <h1 style={myOrdersCss}>My Orders</h1>
        {
          filteredOrderList.map((order, index) => <Order key={index} singleOrder={order} />)
        }
        <div style={paginationCss}>
          <Pagination
            pageRangeDisplayed={10}
            activePage={activePage}
            itemsCountPerPage={countPerPage}
            totalItemsCount={this.props.orderHistory.length}
            onChange={this.handlePageChange}
          /></div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  toggleLoader
}

export default connect(state => state, mapDispatchToProps)(OrderHistory);