const paymentReducer = (state = [], { type, data }) => {
    switch (type) {
        case 'SELECT_INFO':
            return { ...state, selectinfo: data };
        case 'SHOW_PAYMENT_INFO_MODAL':
            return { ...state, showPaymentInfoModal: data };
        case 'SELECT_OPTION':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    cc: { ...state.paymentoption.cc, isActive: data === 'cc' ? true : false },
                    dc: { ...state.paymentoption.dc, isActive: data === 'dc' ? true : false },
                    nb: { ...state.paymentoption.nb, isActive: data === 'nb' ? true : false },
                    upi: { ...state.paymentoption.upi, isActive: data === 'upi' ? true : false },
                    wallet: { ...state.paymentoption.wallet, isActive: data === 'wallet' ? true : false },
                    mealvouchers: { ...state.paymentoption.mealvouchers, isActive: data === 'mealvouchers' ? true : false }
                }
            }
        case 'SET_WALLET':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    wallet: { ...state.paymentoption.wallet, paytm: { isActive: data === 'paytm' ? true : false }, amazonpay: { isActive: data === 'amazonpay' ? true : false }, gokashplus: { isActive: data === 'gokashplus' ? true : false } }
                }
            };

        case 'SET_UPI':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    upi: { ...state.paymentoption.upi, gpay: { isActive: data.type === 'gpay' ? true : false }, vpa: { isActive: (data.type === 'vpa' && data.vpaAddress) ? true : false, vpaId: data.vpaAddress } }
                }
            };
        case 'SELECT_MEALVOUCHER':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    mealvouchers: {
                        ...state.paymentoption.mealvouchers,
                        sodexo: {
                            ...state.paymentoption.mealvouchers.sodexo,
                            isActive: data === 'sodexo' ? true : false
                        },
                        ticketrestaurant: data === 'ticketrestaurant' ? true : false
                    }
                }
            }

        case 'SODEXO_TYPE':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    mealvouchers: {
                        ...state.paymentoption.mealvouchers,
                        sodexo: {
                            ...state.paymentoption.mealvouchers.sodexo,
                            savedcard: data
                        }
                    }
                }
            }

        case 'SET_NB':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    nb: {
                        ...state.paymentoption.nb,
                        bankcode: data
                    }
                }
            }
        case 'SET_CC_TYPE':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    cc: {
                        ...state.paymentoption.cc,
                        cardtype: data
                    }
                }
            }
        case 'SET_CC_BANK':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    cc: {
                        ...state.paymentoption.cc,
                        bankcode: data
                    }
                }
            }
        case 'SET_DC_TYPE':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    dc: {
                        ...state.paymentoption.dc,
                        cardtype: data
                    }
                }
            }
        case 'SET_DC_BANK':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: '',
                    dc: {
                        ...state.paymentoption.dc,
                        bankcode: data
                    }
                }
            }

        case 'SET_ERROR':
            return {
                ...state,
                paymentoption: {
                    ...state.paymentoption,
                    error: data
                }
            }
        case 'SET_PGDATA':
            return {
                ...state,
                pgdata: data
            }
        case 'SET_PAYTMINFO':
            return {
                ...state,
                paytminfo: data
            }
        default:
            return state;
    }
}

export default paymentReducer;