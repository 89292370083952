import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import validator from 'validator';
import { resetPassword } from './../../utils/LoginApiLibrary';
import { setLoginType } from './../../actions/customerActions';
import { openModal, closeModal } from './../../actions/modalActions';


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            isValid: false,
            error: '',
            message: ''
        }
    }

    validatePassword = (e) => {
        const password = e.target.value;

        const isValid = validator.isLength(password, { min: 6, max: undefined });
        !isValid ?
            this.setState({ value: password, isValid: false, error: 'Password must be atleast 6 characters long' }) :
            this.setState({ value: password, isValid: true, error: '' });
    }

    matchPassword = () => {
        var password = document.getElementById("newpassword").value;
        var confirmPassword = document.getElementById("confirmpassword").value;
        if (password != confirmPassword) {
            this.setState({ message: 'Passwords do not match.' });
        }
        else if (!password && !confirmPassword) {
            this.setState({ message: 'Please enter password' });
        }
        else this.handlePasswordReset();
    }
    handlePasswordReset = (e) => {
        const newpassword = document.getElementById("newpassword").value;
        const { cookies } = this.props.cookies;
        const { msg } = this.props.modal.data;
        const accountid = cookies.userid;

        resetPassword(newpassword, accountid).then(res => {
            if (res.data.message === "Password has been changed successfully") {
                if (msg === 'set password') {
                    this.setState({ message: 'Password has been set successfully.' });
                    setTimeout(() => {
                        this.props.closeModal();
                    }, 2000);
                }
                else {
                    this.setState({ message: 'Password has been changed successfully.' });
                    setTimeout(() => {
                        this.props.setLoginType('password');
                        this.props.openModal('UserLogin', { buttonClick: false, msg: 'Password login' });
                    }, 2000);
                }

            }
        }).catch(e => {
            e.response.data.message === 'Invalid Information' || e.response.status === 401 ?
                this.setState({ message: 'Passwords do not match.' }) : ''
        });
    }

    setAuthUser = (id, token) => {
        getOrders(id, token).then(result => this.props.setOrderHistory(result.data)),
            this.props.setSelectedOrder('')
    }

    render() {
        const { msg } = this.props.modal.data;
        return (
            <form action="javascript:void(0)" class="popup-form" id="myLogin">
                <div class="login_icon"><i class="icon_lock_alt"></i></div>
                {msg === 'set password' ? <strong style={{ color: 'green' }}>Close the popup if you don't want to set password now.</strong> : ''}
                <input
                    type="password"
                    id="newpassword"
                    class="form-control form-white"
                    placeholder="New Password"
                    onInput={this.validatePassword}
                    {...this.state.error ? { style: { borderColor: '#fd0000' } } : {}}
                />
                <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.state.error}</p>

                <input
                    type="password"
                    id="confirmpassword"
                    class="form-control form-white"
                    placeholder="Confirm Password"
                    {...this.state.error ? { style: { borderColor: '#fd0000' } } : {}}
                />
                <p {...this.state.message === 'Password has been set successfully.' || this.state.message === 'Password has been changed successfully.' ? { style: { fontSize: '14px', color: '#0a820f', textAlign: 'left' } } : { style: { fontSize: '14px', color: '#fd0000', textAlign: 'left' } }}>{this.state.message}</p>

                <button
                    type="submit"
                    class="btn btn-submit"
                    onClick={this.matchPassword}
                >Reset Password</button>
            </form>
        );

    }
}
const mapDispatchToProps = {
    setLoginType,
    openModal,
    closeModal
};

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));