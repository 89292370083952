import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import Header from './sections/Header';
import Footer from './sections/Footer';
import OrderSummary from './sections/OrderSummary';
import RestaurantBanner from './sections/RestaurantBanner';
import SearchBarPopup from './sections/SearchBarPopup';

class MobileOrderSummary extends React.Component {

    backToMenu = () => {
        const { parentId } = this.props.order;
        this.props.history.push(`/restaurant/${parentId}`);
        window.location.reload();
    }

    render() {
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header />
                <RestaurantBanner />
                <div className="col-sm-12" id="mobile-back-rest-button">
                    <p>
                        <NavLink
                            to="#"
                            className="btn_side"
                            onClick={this.backToMenu}
                        >Back to Menu
                                </NavLink>
                    </p>
                </div>
                <div className="container margin_60_35">
                    <OrderSummary />
                </div>
                <Footer />
            </div>
        );
    }

}


export default withCookies(withRouter(connect(state => state)(MobileOrderSummary)));