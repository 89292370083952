import axios from 'axios';

const baseURL = 'https://api.ampcome.com/api';

export const generateTxn = (body, accesstoken) => {
    const api = '/gokhana/paymenttransaction/record';
    return axios.post(`${baseURL}${api}`, body, {
        headers: { "Authorization": accesstoken, "Content-Type": "application/json" }
    }).then(res => res);
}

export const getSavedCard = (id, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${id}/savedcards?cardtypes=["sodexo"]`;

    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res);
}

export const getResponse = (id, accesstoken) => {
    const api = `/gokhana/paymenttransaction/${id}/update`;

    return axios.post(`${baseURL}${api}`, null, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res);
}

export const createFoodOrder = (restId, body, accesstoken) => {
    const api = `/gokhana/restaurant/${restId}/foodorder?clientapp=gokhanacustomerweb`;

    return axios.post(`${baseURL}${api}`, body, {
        headers: { 'Authorization': accesstoken, 'Content-Type': 'application/json' }
    }).then(res => res);
}

export const createTempFoodOrder = (order, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/cartorder?clientapp=gokhanacustomerweb`;

    return axios.post(`${baseURL}${api}`, order, {
        headers: { 'Authorization': accesstoken, 'Content-Type': 'application/json' }
    }).then(res => res);
}

export const getOrderDetails = (customerid, orderid, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${customerid}/foodorder/${orderid}?clientapp=gokhanacustomerweb`;

    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken, 'Content-Type': 'application/json' }
    }).then(res => res);
}

export const getAnonymousOrder = (restaurantid, orderid, accesstoken) => {
    const api = `/gokhana/restaurant/${restaurantid}/foodorder/${orderid}?clientapp=gokhanacustomerweb`;

    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken, 'Content-Type': 'application/json' }
    }).then(res => res);
}

export const sendNotification = (body, accesstoken) => {
    const api = `/gokhana/notification/notify`;

    return axios.post(`${baseURL}${api}`, body, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res);
}