import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import uuid4 from 'uuid';
import _ from 'lodash';
import Modal from 'react-modal';

import { setMenuItem, toggleMenuCustomItem, toggleMenuAddonItem } from './../../actions/categoryActions';
import { addItemToCart, updateItemCart, setChecksum } from './../../actions/orderActions';
import { calculateGokashDiscount } from './../../utils/CalculateGokash';
import { addRemoveItemFromCart, setGokashInOrder, setDiscountSplit, setDiscountId, setParcelCharges, setEmpPerkInOrder, setPerkInfoInOrder } from './../../actions/orderActions'
import { getGokash, getCreditDiscount, getEmployeeOffer } from './../../utils/CustomerLibrary';
import { setGokash, setGokashDiscount, setGokashConsumed, setPerkInfo } from './../../actions/customerActions';

import Mitem from './Mitem';

class MenuItems extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.customer.isAuthenticated) {
            this.gokashCreditDiscount();
            this.gokashBalance();
        }
        this.state = {
            items: [],
            itemscount: 0,
            totalprice: 0,
            grossamount: 0,
            taxamount: 0,
            taxitems: [],
            parcelcharge: 0,
            open: false,
            maxchoice: 0
        }
    }
    netPrice = {};
    muItem = {};

    getMenuItem = (id) => {
        let menu = [...this.props.menu];
        let menuItem = {};
        menu.forEach(category => {
            if (category.menuitems.length !== 0) {
                category.menuitems.forEach(item => item.id === id ? menuItem = { ...item } : '')
            }
            if (category.subcategories.length !== 0) {
                category.subcategories.forEach(subcat => {
                    subcat.menuitems.forEach(item => item.id === id ? menuItem = { ...item } : '')
                })
            }
        });
        return menuItem;
    }

    gokashCreditDiscount = () => {
        const restaurantid = this.props.order.parentId;
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token) {
            return getCreditDiscount(restaurantid, cookie.token).then(res => {
                if (res && res.data.length) {
                    this.setState({ gokashDiscount: res.data });
                    this.props.setGokashDiscount(res.data);
                    return res.data;
                }
                else return [];
            })
        }
        else {
            return [];
        }
    }

    gokashBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token) {
            return getGokash(cookie.id, cookie.token).then(res => {
                if (res && res.data) {
                    this.setState({ gokashBalance: res.data.credit });
                    this.props.setGokash(res.data);
                    return res.data.credit;
                }
                else return 0;
            })
        }
        else return 0;
    }

    getEmployeePerk = async (totalprice) => {
        const { parentId, foodcourt } = this.props.order;
        const cookie = this.props.cookies.cookies;
        const body = {
            restaurantid: parentId,
            foodcourtid: foodcourt,
            ordervalue: totalprice,
            orders: this.props.order
        }
        if (!_.isEmpty(cookie) && cookie.token)
            return getEmployeeOffer(body, cookie.token).then(res => {
                if (res && res.data.discountfromcompany) {
                    this.props.setEmpPerkInOrder(res.data.discountfromcompany);
                    this.props.setPerkInfo(res.data);
                    this.props.setPerkInfoInOrder(res.data);
                    return res.data;
                }
                else {
                    this.props.setPerkInfo({});
                    this.props.setPerkInfoInOrder({});
                    return {};
                }
            })
    }

    handleDispAddons = (e) => {
        let menuItems = {};
        let newAddonItems = [];
        let newAddonItem = {};
        let toBeIncluded = true;
        let newCustomeItems = [];
        let newOptions = [];

        let mi = this.getMenuItem(e.currentTarget.id);
        mi.addonchoicetype === 'singlechoice' ? (
            mi.addonitems.forEach(it => {
                newAddonItem = { ...it };
                newAddonItem.included = (toBeIncluded && it.included) ? true : false;
                newAddonItems = [...newAddonItems, newAddonItem];
                toBeIncluded = false;
            })
        ) : ''

        mi.customeitems.length !== 0 ? (
            mi.customeitems.forEach(item => {
                newOptions = [];
                item.options.forEach(option => {
                    newOptions = [...newOptions, { ...option }];
                });
                newCustomeItems = [...newCustomeItems, { ...item, options: newOptions }];
            })
        ) : ''

        menuItems = { ...mi, addonitems: newAddonItems, customeitems: newCustomeItems };
        this.muItem = menuItems;
        this.props.setMenuItem(menuItems);
        if (!menuItems.addonitems.length && !menuItems.customeitems.length) {
            this.addToCart(e);
        }
    }

    handleMenuCustomItem = (e) => {
        const id = e.currentTarget.id;
        const name = e.currentTarget.name;
        const maxChoice = e.currentTarget.value;

        if (maxChoice && maxChoice > 0) {
            const selectedCategory = this.props.category.menuitem.customeitems.find(item => item.categoryname === name);
            // let count = 0;
            const selectedOptions = selectedCategory.options.filter(option => option.included);
            const selectedCount = selectedOptions.length;
            if (selectedCount < maxChoice) {
                this.props.toggleMenuCustomItem(id);
            }
            else {
                const selectedOption = selectedOptions.find(option => option.id === id);
                if (selectedOption) {
                    this.props.toggleMenuCustomItem(id);
                }
                // show popup "You can't select more than maxChoice items in this category"
                else {
                    this.setState({ open: true, maxchoice: maxChoice });
                }
            }
        }
        else {
            this.props.toggleMenuCustomItem(id);
        }
    }

    handleMenuAddonItem = (e) => {
        const value = e.currentTarget.value;
        this.props.toggleMenuAddonItem(value);
    }

    updateDiscountSplit = (discount, discountAmount) => {
        const store = this.props;
        let discountSplit = JSON.parse(JSON.stringify(this.props.order.discountsplit));
        if (!discountSplit.length && discount.discountfromcompany) {
            let perkDiscount = {
                id: store.customer.perkInfo.perk ? store.customer.perkInfo.perk : '',
                name: "Discount From Company",
                promotedby: "company",
                promotedgroup: "company",
                amount: store.order.discountfromcompany ? discountAmount : '',
                ontax: 0,
                isfremeal: store.order.discountfromcompany && store.order.isfreemeal
            }
            const index = discountSplit.findIndex(ds => ds.id == perkDiscount.id);
            if (index != -1) { discountSplit.splice(index, 1); }
            discountSplit.push(perkDiscount);
            this.props.setDiscountSplit(discountSplit);
        }
        else if (!discountSplit.length && _.isArray(discount) && discount.length && discount[0].id && discountAmount) {
            let gokashDiscount = {
                id: discount[0].id,
                name: "Gokash Credit Applied",
                promotedby: "gokhana",
                promotedgroup: "gokhana",
                amount: store.order.discountfromcredit ? store.order.discountfromcredit : '',
                ontax: 0
            }
            gokashDiscount[discount[0].valuetype] = discount[0].value;
            const index = discountSplit.findIndex(ds => ds.id == gokashDiscount.id);
            if (index != -1) { discountSplit.splice(index, 1); }
            discountSplit.push(gokashDiscount);
            this.props.setDiscountSplit(discountSplit);
            if (store.customer.gokashDiscount && store.customer.gokashDiscount.length) {
                this.props.setDiscountId(store.customer.gokashDiscount[0].id);
            }
            else this.props.setDiscountId("");
        }
        else if (discountSplit.length && (discount || discount.length)) {
            let compDisIndex;
            let gokashDisIndex;
            let compDiscount = {};
            let goDiscount = {};
            if (discount.perk) {
                compDisIndex = discountSplit.findIndex(dis => dis.id === discount.perk);
                if (compDisIndex != -1) {
                    compDiscount = discountSplit.find(dis => dis.id === discount.perk);
                    compDiscount = JSON.parse(JSON.stringify(compDiscount));
                    compDiscount.amount = discountAmount;
                    discountSplit.splice(compDisIndex, 1);
                    if (discountAmount) discountSplit.push(compDiscount);
                    this.props.setDiscountSplit(discountSplit);
                }
                else {
                    let pIndex = discountSplit.findIndex(dis => dis.name == "Discount From Company");
                    if (pIndex != -1) {
                        discountSplit.splice(pIndex, 1);
                    }
                    let perkDiscount = {
                        id: store.customer.perkInfo.perk ? store.customer.perkInfo.perk : '',
                        name: "Discount From Company",
                        promotedby: "company",
                        promotedgroup: "company",
                        amount: store.order.discountfromcompany ? discountAmount : '',
                        ontax: 0,
                        isfremeal: store.order.discountfromcompany && store.order.isfreemeal
                    }
                    if (discountAmount) discountSplit.push(perkDiscount);
                    this.props.setDiscountSplit(discountSplit);

                }
            }
            else if (_.isArray(discount) && discount.length) {
                gokashDisIndex = discountSplit.findIndex(dis => dis.id === discount[0].id);
                if (gokashDisIndex != -1) {
                    goDiscount = discountSplit.find(dis => dis.id === discount[0].id);
                    goDiscount = JSON.parse(JSON.stringify(goDiscount));
                    goDiscount.amount = discountAmount;
                    goDiscount[discount[0].valuetype] = discount[0].value;
                    discountSplit.splice(gokashDisIndex, 1);
                    if (discountAmount) discountSplit.push(goDiscount);
                    this.props.setDiscountSplit(discountSplit);
                    if (store.customer.gokashDiscount && store.customer.gokashDiscount.length) {
                        this.props.setDiscountId(store.customer.gokashDiscount[0].id);
                    }
                    else this.props.setDiscountId("");
                }
                else {
                    let goIndex = discountSplit.findIndex(dis => dis.name == "Gokash Credit Applied");
                    if (goIndex != -1) {
                        discountSplit.splice(goIndex, 1);
                    }
                    let gokashDiscount = {
                        id: discount[0].id,
                        name: "Gokash Credit Applied",
                        promotedby: "gokhana",
                        promotedgroup: "gokhana",
                        amount: store.order.discountfromcredit ? store.order.discountfromcredit : '',
                        ontax: 0
                    }
                    gokashDiscount[discount[0].valuetype] = discount[0].value;
                    if (store.order.discountfromcredit) discountSplit.push(gokashDiscount);
                    this.props.setDiscountSplit(discountSplit);
                    if (store.customer.gokashDiscount && store.customer.gokashDiscount.length) {
                        this.props.setDiscountId(store.customer.gokashDiscount[0].id);
                    }
                    else this.props.setDiscountId("");

                }
            }
            else {
                // this.props.setDiscountSplit([]);
                this.props.setDiscountId("");
            }
        }
    }

    checkDiscounts = async (updatedItems) => {
        const store = this.props;
        let netPrice = {
            itemscount: 0,
            totalprice: 0,
            grossamount: 0,
            taxamount: 0,
            discountapplied: 0
        };
        netPrice.grossamount = this.state.grossamount;
        netPrice.taxamount = this.getTax(this.state.items);
        netPrice.totalprice = this.state.grossamount + this.state.taxamount + this.state.parcelcharge;
        netPrice.itemscount = this.state.itemscount;
        let gdiscount = [];
        let gbalance = 0;
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token) {
            gdiscount = await this.gokashCreditDiscount();
            gbalance = await this.gokashBalance();
        }
        if (this.props.customer.isAuthenticated) {
            if (store.customer.companyInfo && store.customer.companyInfo.employeeid) {
                const perkInformation = await this.getEmployeePerk(netPrice.totalprice);
                if (perkInformation && perkInformation.discountfromcompany) {
                    this.updateDiscountSplit(perkInformation, perkInformation.discountfromcompany);
                    netPrice.totalprice = netPrice.totalprice - perkInformation.discountfromcompany;
                    // if (netPrice.totalprice) {
                    netPrice.discountapplied = calculateGokashDiscount(gdiscount, netPrice.totalprice, gbalance);
                    netPrice.totalprice = netPrice.totalprice - netPrice.discountapplied;

                    this.props.setGokashInOrder(netPrice.discountapplied);
                    this.props.setGokashConsumed(netPrice.discountapplied);
                    this.updateDiscountSplit(gdiscount, netPrice.discountapplied);
                    // }
                }
                else {
                    netPrice.discountapplied = calculateGokashDiscount(gdiscount, netPrice.totalprice, gbalance);
                    netPrice.totalprice = netPrice.grossamount + netPrice.taxamount - netPrice.discountapplied + this.state.parcelcharge;
                    this.props.setGokashInOrder(netPrice.discountapplied);
                    this.props.setGokashConsumed(netPrice.discountapplied);
                    this.updateDiscountSplit(gdiscount, netPrice.discountapplied);
                }
            }
            else {
                netPrice.discountapplied = calculateGokashDiscount(gdiscount, netPrice.totalprice, gbalance);
                netPrice.totalprice = netPrice.grossamount + netPrice.taxamount - netPrice.discountapplied + this.state.parcelcharge;
                this.props.setGokashInOrder(netPrice.discountapplied);
                this.props.setGokashConsumed(netPrice.discountapplied);
                this.updateDiscountSplit(gdiscount, netPrice.discountapplied);
            }
        }
        const taxItems = this.createTaxItems(updatedItems);
        this.props.addRemoveItemFromCart(updatedItems, netPrice, taxItems);

    }

    addToCart = async (e) => {
        const store = this.props;
        e.preventDefault();
        const id = e.currentTarget.id;
        const sameornot = e.currentTarget.name;
        const taxGroup = (store.category.menuitem && store.category.menuitem.hasOwnProperty('taxgroup')) ? store.category.menuitem.taxgroup.items.filter(item => {
            if (!_.isEmpty(item)) return item;
        }) : this.muItem.taxgroup.items.filter(item => {
            if (!_.isEmpty(item)) return item;
        })
        let taxItems = this.props.order.taxitems;
        let taxAmount = 0;
        let storeItems = [...this.props.order.items];
        let updatedItems = storeItems;
        let netPrice;
        let addTax;

        const checksum = uuid4();
        this.props.setChecksum(checksum);
        let order = this.props.order;
        order = { ...order, checksum: checksum }
        let item = await this.cmi(id);

        // if (!updatedItems.length) {
        //     let item = await this.cmi(id);
        //     updatedItems.push(item);
        // }
        let existItems = order.items.filter(item => item.restaurantmenuitem === id);
        const existItem = existItems[existItems.length - 1];
        if (existItems.length === 0) {
            updatedItems.push(item);
        }
        else {
            let itemFound = this.checkItemAlredyExists(existItems, item);
            if (itemFound) {
                let addItemId = storeItems.find(item => {
                    return item.restaurantmenuitem === id;
                });
                let price = (addItemId.totalprice / addItemId.count);
                let parcelcharge = (addItemId.parcelcharge / addItemId.count)
                updatedItems.forEach(item => {
                    if (item.id === addItemId.id) {
                        item.count = item.count + 1;
                        item.parcelcharge = addItemId.parcelcharge + parcelcharge;
                        item.totalprice += price;
                        addTax = this.getTaxAmountArray(item.totalprice, taxGroup);
                        addTax.forEach(tax => taxAmount += tax.amount);
                        item.taxAmount = taxAmount;
                    };
                });
            }
            else if (sameornot === "add" && existItem) {
                let price = (existItem.totalprice / existItem.count);
                let parcelcharge = (existItem.parcelcharge / existItem.count)
                updatedItems.forEach(item => {
                    if (item.id === existItem.id) {
                        item.count = item.count + 1;
                        item.parcelcharge = existItem.parcelcharge + parcelcharge;
                        item.totalprice += price;
                        addTax = this.getTaxAmountArray(item.totalprice, taxGroup);
                        addTax.forEach(tax => taxAmount += tax.amount);
                        item.taxAmount = taxAmount;
                    };
                });
            }
            else {
                updatedItems.push(item);
            }
        }
        netPrice = await this.billing(updatedItems);
        taxItems = this.createTaxItems(updatedItems);
        this.setState({
            items: updatedItems,
            itemscount: netPrice.itemscount,
            totalprice: netPrice.totalprice,
            grossamount: netPrice.grossamount,
            taxamount: netPrice.taxamount,
            taxitems: taxItems,
            parcelcharge: netPrice.parcelcharges
        });
        this.props.setMenuItem({});
        this.checkDiscounts(updatedItems);

    }

    displayItems = (e) => {
        let menuItems = {};
        let newAddonItems = [];
        let newAddonItem = {};
        let toBeIncluded = true;
        let newCustomeItems = [];
        let newOptions = [];

        let mi = this.getMenuItem(e.currentTarget.id);
        mi.addonchoicetype === 'singlechoice' ? (
            mi.addonitems.forEach(it => {
                newAddonItem = { ...it };
                newAddonItem.included = (toBeIncluded && it.included) ? true : false;
                newAddonItems = [...newAddonItems, newAddonItem];
                toBeIncluded = false;
            })
        ) : ''

        mi.customeitems.length !== 0 ? (
            mi.customeitems.forEach(item => {
                newOptions = [];
                item.options.forEach(option => {
                    newOptions = [...newOptions, { ...option }];
                });
                newCustomeItems = [...newCustomeItems, { ...item, options: newOptions }];
            })
        ) : ''

        menuItems = { ...mi, addonitems: newAddonItems, customeitems: newCustomeItems };
        this.muItem = menuItems;
        this.props.setMenuItem(menuItems);
        // const id = e.currentTarget.id;
        // const addonItem = this.props.order.items.find(ite => (ite.restaurantmenuitem == id) && (ite.customeitems.length || ite.features.length));
        if (!menuItems.addonitems.length && !menuItems.customeitems.length) {
            this.removeFromCart(e);
        }
    }

    removeFromCart = async (e) => {
        const store = this.props;
        let itemscount = 0;
        this.props.order.items.forEach(item => {
            itemscount = itemscount + item.count;
        });
        if (itemscount == 1) {
            this.props.setDiscountSplit([]);
            this.props.setEmpPerkInOrder(0);
        }
        e.preventDefault();
        const id = e.currentTarget.id;
        // const menuItem = this.getMenuItem(id);
        // const taxGroup = menuItem.taxgroup.items.filter(item => {
        //     if (!_.isEmpty(item)) return item;
        // });
        const taxGroup = (store.category.menuitem && store.category.menuitem.hasOwnProperty('taxgroup')) ? store.category.menuitem.taxgroup.items.filter(item => {
            if (!_.isEmpty(item)) return item;
        }) : this.muItem.taxgroup.items.filter(item => {
            if (!_.isEmpty(item)) return item;
        })
        let taxItems = this.props.order.taxitems;
        let taxAmount = 0;
        let storeItems = [...this.props.order.items];
        let updatedItems = storeItems;
        let netPrice;
        let deductTax;

        const checksum = uuid4();
        this.props.setChecksum(checksum);
        let order = this.props.order;
        order = { ...order, checksum: checksum }
        let deleteItem = this.props.order.items.find(item => {
            return item.restaurantmenuitem === id;
        });

        let parcelcharge = (deleteItem.parcelcharge / deleteItem.count);
        let price = (deleteItem.totalprice / deleteItem.count);
        deductTax = this.getTaxAmountArray(deleteItem.totalprice, taxGroup);

        if (deleteItem.count > 1) {
            updatedItems.forEach(item => {
                if (item.id === deleteItem.id) {
                    item.count = item.count - 1;
                    item.totalprice -= price;
                    item.parcelcharge = deleteItem.parcelcharge - parcelcharge;
                    deductTax = this.getTaxAmountArray(item.totalprice, taxGroup);
                    deductTax.forEach(tax => taxAmount += tax.amount);
                    item.taxAmount = taxAmount;
                }
            });

        } else {
            updatedItems = storeItems.filter(item => item.id != deleteItem.id);
        }

        netPrice = await this.billing(updatedItems);
        taxItems = this.createTaxItems(updatedItems);

        this.setState({
            items: updatedItems,
            itemscount: netPrice.itemscount,
            totalprice: netPrice.totalprice,
            grossamount: netPrice.grossamount,
            taxamount: netPrice.taxamount,
            taxitems: taxItems,
            parcelcharge: netPrice.parcelcharges
        });
        this.checkDiscounts(updatedItems);
    }

    cmi = (id) => {
        let item = {};
        let menuitem = this.props.category.menuitem;
        let customerid = this.props.order.restaurantcustomer;
        let restaurant = this.props.restaurant;
        if (menuitem && menuitem.hasOwnProperty(id)) {
            menuitem.id === id ? (
                item.restaurantmenuitem = menuitem.id,
                item.menuitemname = menuitem.name,
                item.restaurant = restaurant.id,
                item.restaurantname = restaurant.name,
                menuitem.parcelchargevalue && (item.parcelchargetype = menuitem.parcelchargetype),
                menuitem.parcelchargevalue && (item.parcelchargevalue = menuitem.parcelchargevalue),
                menuitem.parcelchargevalue && (menuitem.parcelchargetype === "flat" ? item.parcelcharge = menuitem.parcelchargevalue : item.parcelcharge = (menuitem.price * 0.01 * menuitem.parcelchargevalue)),
                customerid ? item.restaurantcustomer = customerid : "",
                item.count = 1,
                item.features = menuitem.addonitems.length !== 0 ? this.getAddonItems(menuitem.addonitems) : [],
                item.customeitems = menuitem.customeitems.length !== 0 ? this.getCustomeItems(menuitem.customeitems) : [],
                item.itemprice = menuitem.price,
                item.totalprice = this.getTotalPrice(Number(menuitem.price), item.features, item.customeitems),
                item.taxAmount = this.getTaxAmount(item.totalprice, menuitem.taxgroup.items),
                item.id = uuidv4(),
                item.status = 'requested',
                item.placedtime = moment().toISOString()

            ) : '';
        }
        else {
            this.muItem.id === id ? (
                item.restaurantmenuitem = this.muItem.id,
                item.menuitemname = this.muItem.name,
                item.restaurant = restaurant.id,
                item.restaurantname = restaurant.name,
                this.muItem.parcelchargevalue && (item.parcelchargetype = this.muItem.parcelchargetype),
                this.muItem.parcelchargevalue && (item.parcelchargevalue = this.muItem.parcelchargevalue),
                this.muItem.parcelchargevalue && (this.muItem.parcelchargetype === "flat" ? item.parcelcharge = this.muItem.parcelchargevalue : item.parcelcharge = (this.muItem.price * 0.01 * this.muItem.parcelchargevalue)),
                customerid ? item.restaurantcustomer = customerid : "",
                item.count = 1,
                item.features = this.muItem.addonitems.length !== 0 ? this.getAddonItems(this.muItem.addonitems) : [],
                item.customeitems = this.muItem.customeitems.length !== 0 ? this.getCustomeItems(this.muItem.customeitems) : [],
                item.itemprice = this.muItem.price,
                item.totalprice = this.getTotalPrice(Number(this.muItem.price), item.features, item.customeitems),
                item.taxAmount = this.getTaxAmount(item.totalprice, this.muItem.taxgroup.items),
                item.id = uuidv4(),
                item.status = 'requested',
                item.placedtime = moment().toISOString()

            ) : '';
        }

        return Promise.resolve(item);
    }
    getItemsCount = (items) => {
        let itemscount = 0;
        if (items.length !== 0) {
            items.forEach(item => {
                itemscount = itemscount + item.count;
            })
        }
        return itemscount;
    }

    billing = async (items) => {
        let netPrice = {
            itemscount: 0,
            totalprice: 0,
            grossamount: 0,
            taxamount: 0,
            discountapplied: 0,
            parcelcharges: 0
        };

        netPrice.grossamount = this.getGrossAmount(items);
        netPrice.taxamount = this.getTax(items);
        netPrice.itemscount = this.getItemsCount(items);

        netPrice.parcelcharges = this.getParcelCharges(items);
        this.props.setParcelCharges(netPrice.parcelcharges);
        netPrice.totalprice = netPrice.grossamount + netPrice.taxamount + netPrice.parcelcharges;

        return netPrice;
    }

    getGrossAmount = (items) => {
        let amt = 0;
        items.length !== 0 ? (
            items.forEach(item => amt += item.totalprice)
        ) : '';
        return amt;
    }

    getParcelCharges = (items) => {
        let parcelcharges = 0;
        items.length !== 0 ? (
            items.forEach(item => {
                if (item.parcelchargetype === "flat" && item.parcelchargevalue > 0) {
                    parcelcharges = parcelcharges + (item.count * item.parcelchargevalue);
                }
                else if (item.parcelchargetype === "percentage" && item.parcelchargevalue > 0) {
                    parcelcharges = parcelcharges + (item.parcelchargevalue * 0.01 * item.totalprice);
                }
            })
        ) : '';
        return parcelcharges;
    }

    getTax = (items) => {
        let amt = 0;
        items.length !== 0 ? (
            items.forEach(item => amt += !_.isNaN(item.taxAmount) ? item.taxAmount : 0)
        ) : '';
        return amt;
    }

    createTaxItems = (items) => {
        let newTax = [];
        let singleItemTax;
        items.forEach(item => {
            let taxRule = this.getMenuItem(item.restaurantmenuitem);
            if (taxRule) {
                singleItemTax = this.getTaxAmountArray(item.totalprice, taxRule.taxgroup.items);
            }
            if (singleItemTax && newTax.length === 0) {
                newTax = singleItemTax;
            } else {
                for (let i = 0; i < newTax.length; i++) {
                    if (newTax[i].name === singleItemTax[i].name) newTax[i].amount += singleItemTax[i].amount;
                }
            }
        });
        return newTax;
    }
    getTaxAmountArray = (price, taxgroup) => {
        let taxAmount = [];
        let taxobj = {};
        if (taxgroup.length !== 0) {
            taxgroup.forEach(tax => {
                taxobj = {};
                taxobj.name = tax.name;
                if (tax.type === 'percentage') {
                    taxobj.amount = Math.round(price * tax.value * 0.01);
                } else if (tax.type === 'flat') {
                    taxobj.amount = Number(tax.value);
                }
                taxAmount = [...taxAmount, taxobj];
            })
        }

        return taxAmount;
    }
    checkItemAlredyExists = (existItem, newItem) => {

        let isSID = false;
        let isSF = false;
        let isSC = false;
        let isSame = false;
        let foundItem = undefined;

        existItem.forEach(item => {
            let eF = item.features;
            let nF = newItem.features;
            let eC = item.customeitems;
            let nC = newItem.customeitems;

            if (item.restaurantmenuitem === newItem.restaurantmenuitem) {
                isSID = true;
                if (eF.length === nF.length) {
                    isSF = true;
                    for (let i = 0; i < eF.length; i++) {
                        isSF = eF[i].name === nF[i].name ? true : false;
                    }
                }
                if (eC.length === nC.length) {
                    isSC = true;
                    for (let i = 0; i < eC.length; i++) {
                        isSC = eC[i].categoryname === nC[i].categoryname ? true : false;
                        if (eC[i].options.length === nC[i].options.length) {
                            for (let j = 0; j < eC[i].options.length; j++) {
                                isSC = eC[i].options[j].id === nC[i].options[j].id ? true : false;
                            }
                        } else { isSC = false }
                    }
                }
            }

            if (isSID && isSF && isSC) { foundItem = item }
        });
        return foundItem;
    }

    getTotalPrice = (price, addonitems, customeitems) => {
        if (addonitems.length !== 0) {
            addonitems.forEach(item => price += Number(item.price));
        }

        if (customeitems.length !== 0) {
            customeitems.forEach(item => {
                (item.options.length > item.freechoicescount) ? (
                    price += item.totalprice
                ) : '';
            });
        }

        return price;
    }

    getTaxAmount = (price, taxes) => {
        let taxAmount = 0;
        taxes && taxes.length !== 0 ? (
            taxes.forEach(tax => {
                if (tax.type === 'percentage') {
                    taxAmount += Math.round(price * tax.value * 0.01);
                } else if (tax.type === 'flat') {
                    taxAmount += Number(tax.value);
                }
            })
        )
            : '';
        return taxAmount;
    }

    getAddonItems = (addons) => {
        let addonItems = [];
        let addonItem = {};

        addons.forEach(addon => {
            addonItem = {};
            addon.included ? (
                addonItem.name = addon.name,
                addonItem.price = addon.price,
                addonItem.isIncluded = addon.included,
                addonItems = [...addonItems, addonItem]

            ) : '';
        });

        return addonItems;
    }

    getCustomeItems = (customes) => {
        let customeItems = [];
        let customeItem = {};

        customes.forEach(item => {
            customeItem = {};
            customeItem = { ...item };
            customeItem.options = this.getOptions(item.options);
            customeItem.totalprice = this.getCustomeTotalPrice(customeItem.freechoicescount, customeItem.options);

            (customeItem.options.length !== 0) ?
                customeItems = [...customeItems, customeItem]
                : '';
        });

        return customeItems;
    }

    getCustomeTotalPrice = (choices, options) => {
        let price = 0;
        if (options.length > choices) {
            if (choices > 0) {
                let paidOptions = options.slice(choices, options.length);
                paidOptions.forEach(option => {
                    option.included && (price += option.price);
                })
            } else {
                options.forEach(option => {
                    option.included && (price += option.price);
                })
            }

            return price;
        }
        else {
            return price;
        }
    }

    getOptions = (options) => {
        let newOptions = [];
        options.forEach(option => {
            option.included ? newOptions = [...newOptions, option] : ''
        });

        return newOptions;
    }

    cancel = (e) => {
        this.props.setMenuItem({});
    }

    filterItems = (items) => {
        let filteredItems = items.filter(item => {
            return item.isVeg;
        });

        return filteredItems;
    }

    displayMenuItem = (menuitems, handleDispAddons, handleMenuAddonItem, handleMenuCustomItem, addToCart, removeFromCart, displayItems, cancel) => {
        return (
            <table className="table table-striped cart-list">
                <thead>
                    <tr>
                        <th></th>
                        <th>Item</th>
                        <th>Price</th>
                        <th style={{ textAlign: 'center' }}>Order</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        menuitems.map(item => {
                            return (
                                <Mitem
                                    key={item.id}
                                    item={item}
                                    handleDispAddons={handleDispAddons}
                                    handleMenuAddonItem={handleMenuAddonItem}
                                    handleMenuCustomItem={handleMenuCustomItem}
                                    addToCart={addToCart}
                                    displayItems={displayItems}
                                    removeFromCart={removeFromCart}
                                    cancel={cancel}
                                />
                            )
                        })
                    }
                </tbody>

            </table>
        )
    }

    closeModal = () => {
        this.setState({ open: false });
    }

    render() {
        const { cat } = this.props;
        const { subcategories, menuitems } = cat;
        return (
            <div>
                {
                    (menuitems.length !== 0) && (
                        this.displayMenuItem(menuitems, this.handleDispAddons, this.handleMenuAddonItem, this.handleMenuCustomItem, this.addToCart, this.removeFromCart, this.displayItems, this.cancel)
                    )
                }
                {
                    (subcategories.length !== 0) && (
                        subcategories.map(subcat => {
                            return (
                                <div key={subcat.id}>
                                    <h3 className="nomargin_top">{subcat.name}</h3>
                                    {this.displayMenuItem(subcat.menuitems, this.handleDispAddons, this.handleMenuAddonItem, this.handleMenuCustomItem, this.addToCart, this.removeFromCart, this.displayItems, this.cancel)}
                                </div>
                            )
                        })
                    )
                }
                <Modal
                    isOpen={this.state.open}
                    contentLabel="Common Modal"
                    className="Modal"
                    overlayClassName="Overlay"
                    ariaHideApp={false}

                >
                    <div className="modal-content modal-popup" id="customizationModal">
                        <h3>You cannnot select more than {this.state.maxchoice} items for this category.</h3>
                        <button className="okButton" onClick={this.closeModal}>OK</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setMenuItem,
    toggleMenuCustomItem,
    toggleMenuAddonItem,
    addItemToCart,
    updateItemCart,
    setChecksum,
    setGokashInOrder,
    setGokash,
    setDiscountSplit,
    setDiscountId,
    setGokashDiscount,
    setGokashConsumed,
    setParcelCharges,
    setEmpPerkInOrder,
    setPerkInfo,
    setPerkInfoInOrder,
    addRemoveItemFromCart
};

export default withCookies(connect(state => state, mapDispatchToProps)(MenuItems));