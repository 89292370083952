import React from 'react';
import { connect } from 'react-redux';

import { setCcType, setCcBank } from './../../actions/paymentActions';

class CreditCard extends React.Component {

    setCardType = (e) => {
        const cardType = e.target.value;
        this.props.setCcType(cardType);
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 col-sm-6">
                </div>
            </div>

        );
    }
}

export default connect(state => state.paymentpage.paymentoption, { setCcType, setCcBank })(CreditCard);