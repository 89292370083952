import axios from 'axios';

const baseURL = 'https://api.ampcome.com/api';

export const getOrders = (id, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${id}/foodorder?loadrelationrecord=false&clientapp=gokhanacustomerweb`;
    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}

export const getGokash = (customerid, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${customerid}/gokashcredit`;
    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}

export const getGokashTransactions = (customerid, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${customerid}/gokashcredittransaction`;
    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}

export const getGokashPlus = (customerid, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${customerid}/gokashplus`;
    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}

export const getGokashPlusTransactions = (customerid, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${customerid}/gokashplustransaction`;
    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}

export const getCreditDiscount = (restaurantid, accesstoken) => {
    const api = `/gokhana/gokashcreditdiscount?restaurantid=${restaurantid}`;
    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}

export const getEmployeeOffer = (body, accesstoken) => {
    const api = '/gokhana/employeeoffer?clientapp=gokhanacustomer'

    return axios.post(`${baseURL}${api}`, body, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}

export const getCreditBackOffer = (restaurantid, accesstoken) => {
    const api = `/gokhana/gokashcreditbackoffer?restaurantid=${restaurantid}`;
    return axios.get(`${baseURL}${api}`, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}

export const updateCustDetails = (id, body, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${id}`;

    return axios.post(`${baseURL}${api}`, body, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}
export const updateCompanyDetails = (id, body, accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${id}?clientapp=gokhanacustomerweb`;

    return axios.post(`${baseURL}${api}`, body, {
        headers: { 'Authorization': accesstoken }
    }).then(res => res).catch(e => console.log(e));
}
