const categoryReducer = (state = [], {type, data}) => {
    switch(type) {
        case 'SET_CATEGORY':
            return data;
        
        case 'SET_MENUITEM':
            return {...state,menuitem:data};

        case 'TOGGLE_MENU_CUSTOMITEM':
            const customeitems = [...state.menuitem.customeitems];
            customeitems.forEach(item => {
                item.options.map(option => {
                    option.id === data ? option.included = !option.included : ''
                })
            });
            return {...state,menuitem:{...state.menuitem,customeitems: customeitems}}
        
        case 'TOGGLE_MENU_ADDONITEM':
            const addonitems = [...state.menuitem.addonitems];
            state.menuitem.addonchoicetype === 'singlechoice' ? (
                addonitems.forEach(item => {
                    item.name === data ? item.included = true : item.included = false;
                })
            ) : (
                addonitems.forEach(item => {
                    item.name === data ? item.included = !item.included : '';
                })
            );
            return {...state,menuitem:{...state.menuitem,addonitems}};
        
        default: 
            return state;
    }
}

export default categoryReducer;