export const selectInfo = (data) => ({
    type: 'SELECT_INFO',
    data
});

export const showPaymentInfoModal = (data) => ({
    type: 'SHOW_PAYMENT_INFO_MODAL',
    data
});

export const selectOption = (data) => ({
    type: 'SELECT_OPTION',
    data
});

export const setwallet = (data) => ({
    type: 'SET_WALLET',
    data
});

export const setUpi = (data) => ({
    type: 'SET_UPI',
    data
});

export const selectMealVoucher = (data) => ({
    type: 'SELECT_MEALVOUCHER',
    data
});

export const sodexoType = (data) => ({
    type: 'SODEXO_TYPE',
    data
});

export const setNB = (data) => ({
    type: 'SET_NB',
    data
});

export const setCcType = (data) => ({
    type: 'SET_CC_TYPE',
    data
});

export const setCcBank = (data) => ({
    type: 'SET_CC_BANK',
    data
});

export const setDcType = (data) => ({
    type: 'SET_DC_TYPE',
    data
});

export const setDcBank = (data) => ({
    type: 'SET_DC_BANK',
    data
});

export const setError = (data) => ({
    type: 'SET_ERROR',
    data
});

export const setPgData = (data) => ({
    type: 'SET_PGDATA',
    data
});

export const setPaytmInfo = (data) => ({
    type: 'SET_PAYTMINFO',
    data
});