const orderReducer = (state = [], { type, data = "", netPrice = '', taxItems = [] }) => {
    let items;
    switch (type) {
        case 'ADD_ITEM_TO_CART':
            return {
                ...state,
                items: [...state.items, data],
                itemscount: netPrice.itemscount,
                totalprice: netPrice.totalprice,
                grossamount: netPrice.grossamount,
                taxamount: netPrice.taxamount,
                taxitems: taxItems
            };

        case 'UPDATE_ITEM_CART':
            let updatedItems = state.items.filter(item => item.id !== data.id);
            return {
                ...state,
                items: [...updatedItems, data],
                itemscount: netPrice.itemscount,
                totalprice: netPrice.totalprice,
                grossamount: netPrice.grossamount,
                taxamount: netPrice.taxamount,
                taxitems: taxItems
            };

        case 'REMOVE_ITEM_CART':

            return {
                ...state,
                items: data,
                itemscount: netPrice.itemscount,
                totalprice: netPrice.totalprice,
                grossamount: netPrice.grossamount,
                taxamount: netPrice.taxamount,
                taxitems: taxItems
            }

        case 'EMPTY_CART':
            return {
                ...state,
                items: [],
                itemscount: 0,
                grossamount: 0,
                taxamount: 0,
                totalprice: 0,
                taxitems: [],
                checksum: '',
                paid: false,
                paymenttransactions: [],
                discountfromcredit: 0,
                discountfromcompany: 0,
                discountsplit: [],
                creditdiscount: "",
                parcelcharge: 0
            };

        case 'REMOVE_FOODCOURT_DATA':
            return {
                ...state,
                foodcourt: "",
                foodcourtname: ""
            };

        case 'SET_REST_FOOD_COURT':
            return {
                ...state,
                foodcourt: data.foodcourt,
                foodcourtname: data.foodcourtname,
                parentId: data.parentId,
                restaurantname: data.restaurantname,
                imageUrl: data.imageUrl
            }

        case 'SET_CUSTOMER':
            return {
                ...state,
                restaurantcustomer: data.restaurantcustomer,
                customername: data.customername,
                customerno: data.customerno,
                customeremail: data.customeremail,
                createdby: data.restaurantcustomer
            }

        case 'RESET_CUSTOMER':
            return {
                ...state,
                restaurantcustomer: "",
                customername: "",
                customerno: ""
            }
        case 'SET_PAYMENT_TXN':
            return {
                ...state,
                paymenttransactions: [data]
            }
        case 'SET_ORDER_DATA':
            return {
                ...state,
                placedtime: data.placedTime,
                paid: true,
                paidamount: data.paidamount
            }
        case 'SET_ORDER_ID':
            return {
                ...state,
                orderResponse: data
            }
        case 'REORDER':
            return data;

        case 'SET_CHECKSUM':
            return {
                ...state,
                checksum: data
            };

        case 'SET_FOODCOURT_IN_ORDER':
            return {
                ...state,
                foodcourt: data.id,
                foodcourtname: data.name
            };

        case 'SET_GOKASH_IN_ORDER':
            return {
                ...state,
                discountfromcredit: data
            }
        case 'SET_ORDER_TYPE':
            return {
                ...state,
                ordertype: data
            }
        case 'SET_EMP_PERK_IN_ORDER':
            return {
                ...state,
                discountfromcompany: data
            }
        case 'SET_GOKASH_PLUS_AMOUNT':
            return {
                ...state,
                gokashplusamount: data
            }
        case 'SET_PERK_INFO_IN_ORDER':
            return {
                ...state,
                employeeperk: data
            }
        case 'SET_PARCEL_CHARGES':
            return {
                ...state,
                parcelcharge: data
            }

        case 'SET_DELIVERY_ADDRESS':
            return {
                ...state,
                deliveryaddress: data
            }

        case 'SET_DISCOUNT_SPLIT':
            return {
                ...state,
                discountsplit: data ? data : []
            }

        case 'SET_DISCOUNT_ID':
            return {
                ...state,
                creditdiscount: data
            }

        default:
            return state;
    }
}

export default orderReducer;