import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { withRouter, NavLink } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

import { openModal, closeModal } from './../../actions/modalActions';
import { toggleLoader } from './../../actions/loaderActions';
import { setLocation } from './../../actions/locationActions';
import { reOrder } from './../../actions/orderActions';
import { setMenu } from './../../actions/menuActions';
import { selectTab } from './../../actions/customerActions';
import { openCloseCart } from './../../actions/cartActions';
import { setDisabledItems } from './../../actions/itemActions';

import { fetchMenu } from '../../utils/ApiLibrary';

import logoImage from './../../images/gokhana-logo.png';
import ModalPopup from './ModalPopup';
// import OrderSummary from './OrderSummary';

class Header extends React.Component {

    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll);
    // }

    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleOnScroll);
    // }

    // handleScroll = () => {
    //     const topValue = document.body.getBoundingClientRect();
    //     const mobileCart = document.getElementById('m-cart') && document.getElementById('m-cart').classList;
    //     const mobileCartIcon = document.getElementById('m-cart-i') && document.getElementById('m-cart-i').classList;
    //     const displayMobCart = document.getElementById('display-mobile-cart') && document.getElementById('display-mobile-cart').classList;
    //     const pointer = document.getElementById('pointer') && document.getElementById('pointer').classList;

    //     if (topValue.top <= 0) {
    //         mobileCart && mobileCart.add('mobile-cart-scroll');
    //         mobileCartIcon && mobileCartIcon.add('mobile-cart-i-scroll');
    //         displayMobCart && displayMobCart.add('display-mobile-cart-scroll');
    //         pointer && pointer.add('pointer-scroll');
    //     } else {
    //         mobileCart && mobileCart.remove('mobile-cart-scroll');
    //         mobileCartIcon && mobileCartIcon.remove('mobile-cart-i-scroll');
    //         displayMobCart && displayMobCart.remove('display-mobile-cart-scroll');
    //         pointer && pointer.remove('pointer-scroll');
    //     }
    // }

    goToCart = (e) => {
        e.preventDefault();
        if (window.screen.width < 991) {
            this.props.history.push('/order-summary');
        } else {
            this.props.history.push(`/restaurant/${this.props.order.parentId}`);
            window.location.reload();
        }

    }

    handleClose = () => {
        this.props.closeModal();
    }

    selectCart = () => {
        const { parentId } = this.props.order;
        this.props.closeModal();
        if (window.screen.width < 991) {
            this.props.history.push('/order-summary');
        } else {
            this.props.history.push(`/restaurant/${parentId}`);
        }
    }

    reorderLogic = () => {
        const { singleOrder } = this.props.modal.data;
        let menuItem;
        let taxGroup;
        let taxItems;
        let taxes;
        let netPrice;
        let taxAmount = 0;
        let reorderData;

        fetchMenu(singleOrder.parentId).then(result => {
            this.props.setMenu(result);
            let disabledItems = [];
            reorderData = {
                'parentId': singleOrder.parentId,
                'restaurantname': singleOrder.restaurantname,
                'appId': '5825d15cf009c4bc37fd7b9f',
                'entityId': '5825d66df009c4bc37fd7bae',
                'customername': singleOrder.customername,
                'customerno': singleOrder.customerno,
                'items': [],
                'itemscount': 0,
                'totalprice': 0,
                'taxamount': 0,
                'taxitems': [],
                'grossamount': 0,
                'paidamount': 0,
                'paid': false,
                'discountsplit': [],
                'paymenttransactions': [],
                'discountfromcredit': 0,
                'promotion': null,
                'creditdiscount': null,
                'restaurantcustomer': singleOrder.restaurantcustomer,
                'ordertype': 'takeaway',
                'orderstatus': 'requested',
                'createdplatform': 'gokhanacustomerweb'
            }
            if (singleOrder.foodcourt) {
                reorderData['foodcourt'] = singleOrder.foodcourt;
                reorderData['foodcourtname'] = singleOrder.foodcourtname;
            }
            for (let oi of singleOrder.items) {
                if (oi && oi.status != 'cancelled' && oi.status != 'paid-and-cancelled') {
                    let item = _.omit(oi, ['id', 'lastupdatedby', 'status', 'delivered', 'totalprice', 'taxAmount']);
                    item.status = 'requested';
                    item.id = uuidv4();

                    menuItem = this.getMenuItem(item.restaurantmenuitem);
                    if (menuItem) {
                        item.totalprice = this.getTotalPrice(menuItem.price, item.features, item.customeitems) * item.count;
                        reorderData.items.push(item);

                        taxGroup = menuItem.taxgroup.items;
                        taxes = this.getTaxAmountArray(item.totalprice, taxGroup);

                        taxAmount = 0;
                        taxes.forEach(tax => taxAmount += tax.amount);
                        item.taxAmount = taxAmount;
                    }
                    else {
                        if (this.props.disabledItems.length) {
                            disabledItems = JSON.parse(JSON.stringify(this.props.disabledItems));
                            const restItem = this.props.disabledItems.find(it => it.restaurantmenuitem === item.restaurantmenuitem);
                            if (!restItem) {
                                disabledItems.push(item);
                            }
                        }
                        else {
                            disabledItems.push(item);
                        }
                        this.props.setDisabledItems(disabledItems);
                    }
                }
            }

            netPrice = this.billing(reorderData.items);

            reorderData.itemscount = netPrice.itemscount;
            reorderData.totalprice = netPrice.totalprice;
            reorderData.taxamount = netPrice.taxamount;
            reorderData.grossamount = netPrice.grossamount;

            taxItems = this.createTaxItems(reorderData.items);
            reorderData.taxitems = taxItems;

            this.props.reOrder(reorderData);
            this.props.closeModal();
            this.props.history.push(`/restaurant/${reorderData.parentId}`);

        });
    }

    getMenuItem = (id) => {
        let menuItem = {};
        this.props.menu.forEach(category => {
            if (category.menuitems.length !== 0) {
                category.menuitems.forEach(item => item.id === id ? menuItem = { ...item } : '')
            }
            if (category.subcategories.length !== 0) {
                category.subcategories.forEach(subcat => {
                    subcat.menuitems.forEach(item => item.id === id ? menuItem = { ...item } : '')
                })
            }
        });
        return menuItem;
    }

    getTaxAmountArray = (price, taxgroup) => {
        let taxAmount = [];
        let taxobj = {};
        if (taxgroup.length !== 0) {
            taxgroup.forEach(tax => {
                taxobj = {};
                taxobj.name = tax.name;
                if (tax.type === 'percentage') {
                    taxobj.amount = Math.round(price * tax.value * 0.01);
                } else if (tax.type === 'flat') {
                    taxobj.amount = Number(tax.value);
                }
                taxAmount = [...taxAmount, taxobj];
            })
        }
        return taxAmount;
    }

    billing = (items) => {
        let netPrice = {
            itemscount: 0,
            totalprice: 0,
            grossamount: 0,
            taxamount: 0
        };

        netPrice.grossamount = this.getGrossAmount(items);
        netPrice.taxamount = this.getTax(items);
        netPrice.totalprice = netPrice.grossamount + netPrice.taxamount;
        netPrice.itemscount = items.length;

        return netPrice;
    }

    getGrossAmount = (items) => {
        let amt = 0;
        if (items.length !== 0) {
            items.forEach(item => amt += item.totalprice)
        };
        return amt;
    }

    getTax = (items) => {
        let amt = 0;
        if (items.length !== 0) {
            items.forEach(item => amt += item.taxAmount)
        };
        return amt;
    }

    createTaxItems = (items) => {
        let newTax = [];
        let singleItemTax;
        items.forEach(item => {
            let taxRule = this.getMenuItem(item.restaurantmenuitem);

            singleItemTax = this.getTaxAmountArray(item.totalprice, taxRule.taxgroup.items);
            if (newTax.length === 0) {
                newTax = singleItemTax;
            } else {
                for (let i = 0; i < newTax.length; i++) {
                    if (newTax[i].name === singleItemTax[i].name) newTax[i].amount += singleItemTax[i].amount;
                }
            }
        });
        return newTax;
    }

    getTotalPrice = (price, addonitems, customeitems) => {
        if (addonitems && addonitems.length !== 0) {
            addonitems.forEach(item => price += Number(item.price));
        }

        if (customeitems && customeitems.length !== 0) {
            customeitems.forEach(item => {
                if (item.options.length > item.freechoicescount) {
                    price += ((item.options.length - item.freechoicescount) * item.price)
                };
            });
        }

        return price;
    }

    render() {
        let showButton = true;
        if (this.props.restaurant.id == '5ece3e3280184677c45b5075') {
            showButton = false;
        }
        const { city } = this.props.location;
        const mainCookies = this.props.cookies;
        const { cookies } = mainCookies;
        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col--md-4 col-sm-4 col-xs-4">
                            {showButton ? <Link to="/" id="logo">
                                <img src={logoImage} height="20" alt="" data-retina="true" style={{ marginTop: "8px" }} />
                            </Link> :
                                <Link to="/restaurant/5ece3e3280184677c45b5075" id="logo">
                                    <img src={logoImage} height="20" alt="" data-retina="true" style={{ marginTop: "8px" }} />
                                </Link>
                            }
                        </div>
                        <nav className="col--md-8 col-sm-8 col-xs-8">
                            {/* Mobile device */}

                            {showButton && <NavLink
                                to="#"
                                title={this.props.location.city}
                                className="mobile-city"
                                id="mobile-change-location"
                            >
                                <i className="icon-location-7"></i>
                                {this.props.location.city && this.props.location.city.length > 10 ? `${this.props.location.city.substring(0, 10)}...` : this.props.location.city}
                                <i className="icon-down-open-mini"></i>
                            </NavLink>}
                            {!!mainCookies.get('token') ? (<Link to="/my-account" className="mobile-myaccount" onClick={() => this.props.selectTab('profile')}>
                                <i className="icon-user-7"></i>
                            </Link>) : (<Link to="#" className="mobile-myaccount" onClick={(e) => { e.preventDefault(); this.props.openModal('UserLogin', { buttonClick: true }) }}>
                                <i className="icon-user-7"></i>
                            </Link>)}

                            {/* <NavLink
                                to="#"
                                className="mobile-cart"
                                id="m-cart"
                                onClick={(e) => { e.preventDefault(); this.props.openCloseCart(true) }}
                            >
                                <i className="icon_cart_alt" id="m-cart-i"></i>
                                {
                                    this.props.order.items.length !== 0 ? (
                                        <sup>{this.props.order.itemscount}</sup>
                                    ) : ''
                                }
                            </NavLink> */}

                            {/* <div id="display-mobile-cart">
                                {
                                    this.props.cart.isCartOpen && (
                                        <div>
                                            <div id="pointer"></div>
                                            <OrderSummary />
                                        </div>
                                    )
                                }
                            </div> */}

                            {/* Big devices */}
                            <div className="main-menu">
                                <div id="header_menu">
                                    <img src={logoImage} height="30" alt="" data-retina="true" />
                                </div>
                                <NavLink onClick={e => e.preventDefault()} to="#" className="open_close" id="close_in"><i className="icon_close"></i></NavLink>
                                <ul>
                                    {
                                        !!mainCookies.get('token') ? (
                                            <li className="submenu">
                                                <NavLink
                                                    to="#"
                                                    className="show-submenu"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className="icon-user"></i>Account<i className="icon-down-open-mini"></i>
                                                </NavLink>
                                                <ul>
                                                    <li>
                                                        <Link to="/my-account" onClick={() => this.props.selectTab('profile')}>
                                                            {cookies.name !== 'null' ? <p>{cookies.name ? cookies.name : this.props.order.customername}</p> : ''}
                                                            {cookies.phoneno !== 'null' ? <p>{cookies.phoneno}</p> : ''}
                                                            {cookies.email !== 'null' ? <p>{cookies.email}</p> : ''}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/my-account" onClick={() => this.props.selectTab('gokash')}><i className="icon-money"></i>
                                                            GoKash: &nbsp;
₹{this.props.customer.gokash > 0 ? this.props.customer.gokash.toFixed(2) : 0}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/my-account" onClick={() => this.props.selectTab('gokashplus')}><i className="icon-money"></i>
                                                            GoKash+: &nbsp;
₹{this.props.customer.gokashPlus > 0 ? this.props.customer.gokashPlus.toFixed(2) : 0}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/my-account" onClick={() => this.props.selectTab('orders')}><i className="icon-shop"></i>
                                                            My Orders
</Link>
                                                    </li>
                                                    <li><Link to="/logout"><i className="icon-logout"></i>Logout</Link></li>
                                                </ul>
                                            </li>
                                        ) : ''
                                    }

                                    {
                                        !mainCookies.get('token') ? (
                                            <li>
                                                <NavLink
                                                    to="#"
                                                    onClick={(e) => { e.preventDefault(); this.props.openModal('UserLogin', { buttonClick: true }) }}
                                                >
                                                    Login
</NavLink>
                                            </li>
                                        ) : ''
                                    }

                                    {
                                        !mainCookies.get('token') ? (
                                            <li>
                                                <NavLink
                                                    to="#"
                                                    onClick={(e) => { e.preventDefault(); this.props.openModal('UserRegister', { buttonClick: true }) }}
                                                >Register
</NavLink>
                                            </li>
                                        ) : ''
                                    }

                                    {
                                        city ? (
                                            <li>
                                                <NavLink
                                                    to="#"
                                                    title={this.props.location.city}
                                                    className="show-submenu"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className="icon-location-7"></i>
                                                    {this.props.location.city.length > 20 ? `${this.props.location.city.substring(0, 20)}...` : this.props.location.city}
                                                    <i className="icon-down-open-mini"></i>
                                                </NavLink>
                                                <ul>
                                                    <li>
                                                        <NavLink
                                                            to="#"
                                                            id="change-location"
                                                            onClick={e => e.preventDefault()}
                                                        >
                                                            <input
                                                                type="submit"
                                                                className="btn_1"
                                                                value="Change Location"
                                                            />
                                                        </NavLink>
                                                    </li>
                                                </ul>

                                            </li>
                                        ) : ''
                                    }
                                    {
                                        this.props.order.items.length !== 0 ? (
                                            <li>
                                                <NavLink
                                                    to="#"
                                                    onClick={this.goToCart}
                                                    style={{ color: '#ff5722', fontSize: '15px' }}
                                                    title="There are items in your cart"
                                                >
                                                    <i className="icon_cart_alt" style={{ color: '#ff5722', fontSize: '15px' }}></i>
                                                    &nbsp; Go To Cart ({this.props.order.itemscount})
</NavLink>

                                            </li>
                                        ) : (
                                                <li>
                                                    <NavLink
                                                        to="#"
                                                        title="Your cart is empty"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <i className="icon_cart_alt"></i>
                                                        &nbsp; Cart
</NavLink>
                                                </li>
                                            )

                                    }

                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                {((this.props.modal.modalType === "UserRegister"
                    || this.props.modal.modalType === "UserLogin"
                    || this.props.modal.modalType === "VerifyUser"
                    || this.props.modal.modalType === "Filters"
                    || this.props.modal.modalType === "cartNotEmpty"
                    || this.props.modal.modalType === "ResetPassword"
                    || this.props.modal.modalType === "reOrder") && this.props.modal.isModalOpen) ?
                    <ModalPopup
                        isModalOpen={this.props.modal.isModalOpen}
                        handleClose={this.handleClose}
                        modalType={this.props.modal.modalType}
                        data={this.props.modal.data}
                        selectCart={this.selectCart}
                        reOrder={this.reorderLogic}
                        toCart={this.props.toCart}
                        newSelection={this.props.newSelection}
                    /> : <div></div>
                }
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    openModal,
    closeModal,
    toggleLoader,
    setLocation,
    reOrder,
    setMenu,
    selectTab,
    openCloseCart,
    setDisabledItems
};

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(Header)));