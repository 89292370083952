import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

import FilterOptions from './FilterOptions';

import { openModal } from './../../actions/modalActions';

class Filters extends React.Component {

	render() {
		return (
			<div className="col-md-3">
				<div id="filters_col">
					<a
						id="filters_col_bt
						">
						Filters
					</a>

					{/* <div className="collapse in" id="collapseFilters"> */}
					<div>
						<FilterOptions />
					</div>
				</div>

				{/* mobile device */}
				<div id="mobile_filters_col">
					<NavLink
						to="#"
						onClick={e => e.preventDefault()}
					>
						<div aria-hidden="true" data-icon="&#x67;"></div>
					</NavLink>
				</div>
				<div id="disp-mobile-filters" className="row">
					<div className="col-sm-12">
						<h4>Filters</h4>
					</div>
					<div className="col-sm-12">
						<FilterOptions />
					</div>
					<div className="col-sm-12">
						<NavLink to="#" className="btn_side" id="filters-done-button" onClick={e => e.preventDefault()}>Done</NavLink>
					</div>

				</div>
				<div id="filters-overlay"></div>
			</div>
		);
	}
}

const mapStateToProps = {
	openModal
};

export default withRouter(connect(state => state, mapStateToProps)(Filters));