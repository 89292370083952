const filterReducer = (state = [], {type, data}) => {
    switch(type) {
        case 'SET_SELECTEDCUISINE':
            return {...state, cuisines: [...state.cuisines, data]};
        case 'REMOVE_SELECTEDCUISINE':
            let filteredCuisines = state.cuisines.filter((cuisineId) => {
                return cuisineId !== data;
            });
            return {...state, cuisines: filteredCuisines};
        case 'TOGGLE_FOODCOURT':
            return {...state,foodcourtOnly: data};
        case 'TOGGLE_VEGONLY':
            return {...state,vegOnly: data};
        case 'UPDATE_DISTANCE':
            return {...state,distance: data};
        case 'SET_FOODCOURTID':
            return {...state,foodcourtId: data};
        case 'SET_FOODCOURTNAME':
            return {...state,foodcourtName:data};
        case 'SET_CENTRALIZED_BILLING':
            return {...state,foodcourtCB:data};
        default:
            return state;
    }
}

export default filterReducer;