const locationReducer = (state=[], {type, data}) => {
    switch(type) {
        case 'SET_LOCATION':
            return data;
        case 'SET_LOCATION_ERROR':
            return {...state, error: data}
        default:
            return state;
    }
}

export default locationReducer;