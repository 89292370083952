import React from 'react';
import { connect } from 'react-redux';

import MenuItems from './MenuItems';

class MenuItemsGroup extends React.Component {

    displayMenuItems = (category, index) => {
        return (
            <div key={category.id} id={`m${index}`} className="cat_menu_items" data-categoryid={category.id} data-categoryname={category.name}>
                <div className="box_style_2" id="main_menu">
                    <h2 className="inner">{category.name}</h2>
                    <MenuItems cat={category} />
                </div>
            </div>
        )

    }

    //category -> subcategory -> menuitems
    displayCategory = (menu) => {
        const { vegOnly } = this.props.filters;
        if (vegOnly) {
            return menu.map((category, index) => {
                let newCat = {
                    ...category,
                    subcategories: [],
                    menuitems: []
                };

                if (category.subcategories.length) {
                    //subcategories
                    category.subcategories.map((subcat, index) => {
                        const newMI = this.filterItems(subcat.menuitems);
                        newMI.length &&
                            newCat.subcategories.push({
                                id: subcat.id,
                                name: subcat.name,
                                menuitems: newMI
                            });
                    });
                } if (category.menuitems.length) {
                    //menuitems
                    newCat.menuitems = this.filterItems(category.menuitems);
                }

                if (newCat.subcategories.length !== 0 || newCat.menuitems.length !== 0) {
                    return this.displayMenuItems(newCat, index);
                }

            });
        } else {
            return menu.map((category, index) => this.displayMenuItems(category, index));
        }

    }

    filterItems = (items) => {
        let filteredItems = items.filter(item => {
            return item.isVeg;
        });

        return filteredItems;
    }

    render() {
        const { menu } = this.props;
        return (
            <div className="col-md-6" id="menu-items-group-parent">
                <div onScroll={this.props.handleOnScroll} id="menu-items-group">
                    {this.displayCategory(menu)}
                </div>
            </div>
        );
    }
}

export default connect(state => state)(MenuItemsGroup);