import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';

import Header from './sections/Header';
import Footer from './sections/Footer';
import FinalOrderSummary from './sections/FinalOrderSummary';
import ImageSubHeader from './sections/ImageSubHeader';
import OrderUserInfo from './sections/OrderUserInfo';
import SearchBarPopup from './sections/SearchBarPopup';

import { addRemoveItemFromCart } from './../actions/orderActions';

import { loadJsLibraries } from './../utils/LoadLibrary';
import orderImg from './../images/web-images/placeorder.jpg';
import OrderPaymentInfo from './sections/OrderPaymentInfo';
import CheckoutSidebar from './sections/CheckoutSidebar';

class UserDetails extends React.Component {

    UNSAFE_componentWillMount() {
        const { items } = this.props.order;
        if (items.length === 0) {
            this.props.history.push('/');
        }

        loadJsLibraries();
    }
    render() {
        const { contactdetails } = this.props.paymentpage.selectinfo;
        // const { modal } = this.props;
        let orderId = contactdetails ? 1 : 2;
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header />
                <ImageSubHeader title="Place your order" showOrder={true} orderId={orderId} bgImg={orderImg} />
                <div className="container margin_60_35">
                    <div className="row">
                        <CheckoutSidebar />
                        {contactdetails ? <OrderUserInfo /> : <OrderPaymentInfo />}
                        <FinalOrderSummary />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

const mapDispatchToProps = {
    addRemoveItemFromCart
}

export default withCookies(withRouter(connect(state => state, mapDispatchToProps)(UserDetails)));