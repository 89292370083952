export const openModal = (modalType, data) => ({
    type: 'OPEN_MODAL',
    modalType,
    data
});

export const showTimer = (data) => ({
    type: 'SHOW_TIMER',
    data
});

export const closeModal = () => ({
    type: 'CLOSE_MODAL',
    modalType: 'undefined'
});