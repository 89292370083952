import React from 'react';

import Header from './sections/Header';
import ImageSubHeader from './sections/ImageSubHeader';
import BreadCrumb from './sections/BreadCrumb';
import Footer from './sections/Footer';
import ContactContent from './sections/ContactContent';

import contactImg from './../images/web-images/contactus.jpg';

class ContactUs extends React.Component {

    render() {
        return (
            <div>
                <Header />
                <ImageSubHeader title="Contact Us" subTitle="We are 24 X 7 available" bgImg={contactImg} />
                <BreadCrumb />
                <ContactContent />
                <Footer />
            </div>
        );
    }
    
}

export default ContactUs;