import React from 'react';

export default () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 nopadding features-intro-img">
                    <div className="features-bg">
                        <div className="features-img">
                        </div>
                    </div>
                </div>
                <div className="col-md-6 nopadding">
                    <div className="features-content">
                        <h3>"Ex vero mediocrem"</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a lorem quis neque interdum consequat ut sed sem. Duis quis tempor nunc. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                        </p>
                        <p>
                            Per ea erant aeque corpora, an agam tibique nec. At recusabo expetendis vim. Tractatos principes mel te, dolor solet viderer usu ad.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}