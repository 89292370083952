import React from 'react';
import { connect } from 'react-redux';

import GridListFoodCourts from './GridListFoodCourts';
import loaderImg from './../../images/web-images/loader.gif';

class DisplayFoodCourts extends React.Component {
    render() {

        if (!this.props.restaurants.length === 0) {
            return (<div style={{ textAlign: 'center', marginTop: '200px' }}><img src={loaderImg} style={{ width: '50px' }} /><h2>Loading...</h2></div>);
        }

        return (
            <div className="col-md-9">
                {
                    this.props.restaurants.map((foodcourt, index) => {
                        if (foodcourt.isEmpty) {
                            return <div key={index} className="error-msg"><h4>Oops! No FoodCourts Found</h4></div>
                        }
                        return foodcourt.entityName === 'foodcourt' ? <GridListFoodCourts key={index} outlet={foodcourt} /> : '';
                    })
                }
            </div>
        );
    }
}

export default connect(state => state)(DisplayFoodCourts);