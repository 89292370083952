import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from './../../actions/modalActions';
import { setDeliveryAddress } from './../../actions/orderActions';

class AddressForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            door: { value: '', error: '' },
            floor: { value: '', error: '' },
            building: { value: '', error: '' },
            apartment: { value: 'Prestige Shantiniketan', error: '' }
        }
    }

    setDoor = (e) => {
        let door = e.target.value;
        if (!_.isEmpty(door)) {
            this.setState({ door: { value: door } });
        }
        else this.setState({ door: { error: 'Field cannot be empty.' } });
    }
    setFloor = (e) => {
        let floor = e.target.value;
        if (!_.isEmpty(floor))
            this.setState({ floor: { value: floor } });
        else this.setState({ floor: { error: 'Field cannot be empty.' } });
    }
    setBuilding = (e) => {
        let building = e.target.value;
        if (!_.isEmpty(building))
            this.setState({ building: { value: building } });
        else this.setState({ building: { error: 'Field cannot be empty.' } });
    }
    setApartment = (e) => {
        let apartment = e.target.value;
        if (!_.isEmpty(apartment)) this.setState({ apartment: { value: apartment } });
        else this.setState({ apartment: { error: 'Field cannot be empty.' } });
    }

    submitAddress = () => {
        // e.preventDefault();
        _.isEmpty(this.state.door.value) ? this.setState({ door: { error: 'Field cannot be empty.' } }) : '';
        _.isEmpty(this.state.floor.value) ? this.setState({ floor: { error: 'Field cannot be empty.' } }) : '';
        _.isEmpty(this.state.building.value) ? this.setState({ building: { error: 'Field cannot be empty.' } }) : '';
        _.isEmpty(this.state.apartment.value) ? this.setState({ apartment: { error: 'Field cannot be empty.' } }) : '';

        if (!_.isEmpty(this.state.door.value) && !_.isEmpty(this.state.floor.value) && !_.isEmpty(this.state.building.value) && !_.isEmpty(this.state.apartment.value)) {
            let deliveryaddress = {
                door: this.state.door.value,
                floor: this.state.floor.value,
                building: this.state.building.value,
                apartment: this.state.apartment.value
            }
            // setting delivery address in order
            this.props.setDeliveryAddress(deliveryaddress);
            this.props.handleOrderNow();
            this.props.closeModal();
        }

    }

    render() {
        // const { deliveryaddress } = this.props.order;
        return (
            <div
                className="popup-form" id="address">
                <div className="form-row">
                    <div className="form-group col-md-6 col-sm-6 col-xs-6">
                        <label>Apt. Num</label>
                        <input type="text" className="form-control" onInput={this.setDoor}
                            // defaultValue={deliveryaddress ? deliveryaddress.door : ''}
                            {...this.state.door.error ? { style: { borderColor: '#fd0000' } } : {}}
                        />
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.state.door.error}</p>
                    </div>
                    <div className="form-group col-md-6 col-sm-6 col-xs-6">
                        <label>Floor</label>
                        <input type="text" className="form-control" onInput={this.setFloor}
                            // defaultValue={deliveryaddress ? deliveryaddress.floor : ''}
                            {...this.state.floor.error ? { style: { borderColor: '#fd0000' } } : {}}
                        />
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.state.floor.error}</p>
                    </div>
                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <label>Building/Tower</label>
                        <input type="text" className="form-control" onInput={this.setBuilding}
                            // defaultValue={deliveryaddress ? deliveryaddress.building : ''}
                            {...this.state.building.error ? { style: { borderColor: '#fd0000' } } : {}}
                        />
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.state.building.error}</p>
                    </div>
                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <label>Apartment</label>
                        <select
                            className="form-control" id="apartment" name="apartment"
                            onChange={this.setApartment}
                            {...this.state.apartment.error ? { style: { borderColor: '#fd0000' } } : {}}
                        >
                            <option value="Prestige Shantiniketan">Prestige Shantiniketan</option>
                        </select>
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.state.apartment.error}</p>
                    </div>
                </div>
                <button type="submit" className="btn btn-submit" onClick={this.submitAddress}>Submit</button>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setDeliveryAddress,
    closeModal
}

export default connect(state => ({ modal: state.modal, order: state.order }), mapDispatchToProps)(AddressForm);
