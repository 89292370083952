export const authenticateUser = (data) => ({
    type: 'AUTHENTICATE_USER',
    data
});

export const selectTab = (data) => ({
    type: 'SELECT_TAB',
    data
});

export const setSelectedOrder = (data) => ({
    type: 'SET_SELECTED_ORDER',
    data
});

export const getSetCustDetails = (data) => ({
    type: 'GET_SET_CUSTOMER_DETAILS',
    data
});

export const setGokash = (data) => ({
    type: 'SET_GOKASH',
    data
});

export const setGokashPlus = (data) => ({
    type: 'SET_GOKASH_PLUS',
    data
});

export const setGokashConsumed = (data) => ({
    type: 'SET_GOKASH_CONSUMED',
    data
});

export const latestGokash = (data) => ({
    type: 'SET_LATEST_GOKASH',
    data
});
export const setGokashDiscount = (data) => ({
    type: 'SET_GOKASH_DISCOUNT',
    data
});
export const setPerkInfo = (data) => ({
    type: 'SET_PERK_INFO',
    data
})

export const setGokashBackup = (data) => ({
    type: 'SET_GOKASH_BACKUP',
    data
});


export const setGokashCreditBack = (data) => ({
    type: 'SET_GOKASH_CREDIT_BACK',
    data
});

export const setGokashTransaction = (data) => ({
    type: 'SET_GOKASH_TRANSACTION',
    data
});
export const setGokashPlusTransaction = (data) => ({
    type: 'SET_GOKASH_PLUS_TRANSACTION',
    data
});

export const setEmail = (data) => ({
    type: 'SET_EMAIL',
    data
});

export const setName = (data) => ({
    type: 'SET_NAME',
    data
});

export const setEmpId = (data) => ({
    type: 'SET_EMPID',
    data
});
export const setCmpDomain = (data) => ({
    type: 'SET_DOMAIN',
    data
});
export const setWorkEmail = (data) => ({
    type: 'SET_WORKEMAIL',
    data
});
export const setCubical = (data) => ({
    type: 'SET_CUBICAL',
    data
});
export const setFloor = (data) => ({
    type: 'SET_FLOOR',
    data
});
export const setBuilding = (data) => ({
    type: 'SET_BUILDING',
    data
});

export const setCompanyInfo = (data) => ({
    type: 'SET_COMPANY_INFO',
    data
});

export const toggleIsUpdated = (data) => ({
    type: 'TOGGLE_ISUPDATED',
    data
});

export const setLoginType = (data) => ({
    type: 'SET_LOGIN_TYPE',
    data
});
