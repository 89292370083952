import React from 'react';
import PaymentOptions from './PaymentOptions';

class OrderPaymentInfo extends React.Component {

    render() {
        return (
            <div className="col-md-6">
                <div className="box_style_2">
                    <h2 className="inner">Payment Options</h2>

                    <PaymentOptions />

                </div>
            </div>
        );
    }
}

export default OrderPaymentInfo;