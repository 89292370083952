import React from 'react';
import { connect } from 'react-redux';

class FoodcourtBanner extends React.Component {
    render() {
        const { foodcourt } = this.props;
        return (
            <section style={{ "minHeight": "100px", "marginTop": "50px", "backgroundColor": "#f9f9f9", "borderBottom": "1px solid #ddd", "borderTop": "1px solid #ddd" }}>
                <div className="container">
                    <table>
                        <tr>
                            <td style={{ "width": "150px" }}>
                                <div className="thumb" >
                                    <img src={foodcourt[0] ? foodcourt[0].imageUrl : ''} alt="" style={{ width: '120px' }} />
                                </div>
                            </td>
                            <td>
                                <h4>{foodcourt[0] ? foodcourt[0].name : ''}</h4>
                                {/* {foodcourt[0] ? foodcourt[0].address : ''} */}
                            </td>
                        </tr>
                    </table>

                </div>
            </section>
        );
    }
}

export default connect(state => state)(FoodcourtBanner);