const itemReducer = (state = [], { type, data }) => {
    switch (type) {
        case 'SET_DISABLED_ITEMS':
            return data
        default:
            return state;

    }
};

export default itemReducer;