import React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import validator from 'validator';

import { updateCustDetails, updateCompanyDetails } from './../../utils/CustomerLibrary';
import {
    getSetCustDetails, setEmail, setName, toggleIsUpdated, setCompanyInfo, setEmpId,
    setCmpDomain,
    setWorkEmail,
    setCubical,
    setFloor,
    setBuilding
} from './../../actions/customerActions';
import { setCustomer } from './../../actions/orderActions';

import { toast } from 'react-toastify';
import { css } from 'glamor';
import 'react-toastify/dist/ReactToastify.css';

class UpdateCustomerInfo extends React.Component {
    constructor(props) {
        super(props);
        // const { name, email } = props.updateCustomerDetails;
        const cookie = props.cookies.cookies;
        const { cookies } = props;
        let name = cookie.name ? cookie.name : '';
        let email = cookie.email ? cookie.email : '';
        const isNameValid = this.validateFullName(name);
        const isEmailValid = validator.isEmail(email);
        !isNameValid ?
            this.props.setName({ value: name, isValid: false, error: 'Enter a valid name.' }) :
            this.props.setName({ value: name, isValid: true, error: '' });

        !isEmailValid ?
            this.props.setEmail({ value: email, isValid: false, error: 'Enter a valid email address.' }) :
            this.props.setEmail({ value: email, isValid: true, error: '' });
        if (email && name) {
            (isNameValid && isEmailValid) ? (
                updateCustDetails(cookie.id, { name: name, email: email }, cookie.token).then(res => {
                    this.props.toggleIsUpdated(true);
                    this.props.setCustomer({
                        restaurantcustomer: res.data.id,
                        customername: res.data.name,
                        customerno: res.data.phoneno,
                        // customeremail: res.data.email,
                        createdby: res.data.id
                    });
                    cookies.set('name', _.startCase(res.data.name, { path: '/' }));
                    cookies.set('email', res.data.email, { path: '/' });
                    res.data.company ? cookies.set('companyInfo', res.data.company, { path: '/' }) : cookies.remove('companyInfo', { path: '/' });
                })
            ) : ''
        }
        this.state = {
            showEmployeeInfo: false
        }
    }
    componentDidMount() {
        const { cookies } = this.props.cookies;
        if (cookies && cookies.companyInfo) {
            let compInfo = JSON.parse(cookies.companyInfo);
            this.props.setCompanyInfo(compInfo);
        }
        let customerData = {
            name: {
                value: cookies.name,
                isValid: true,
                error: ''
            },
            email: {
                value: cookies.email,
                isValid: true,
                error: ''
            }
        };
        this.props.getSetCustDetails(customerData);
    }

    validateFullName = (name) => {
        const fullName = name.split(" ");
        let isValid = false;

        fullName.length <= 3 ? (
            isValid = validator.isAlpha(fullName[0]) && validator.isLength(fullName[0], { min: 3, max: 30 }),
            fullName[1] && isValid ? isValid = validator.isAlpha(fullName[1]) : '',
            fullName[2] && isValid ? isValid = validator.isAlpha(fullName[2]) : ''
        ) : '';

        return isValid;
    }

    validateName = (e) => {
        const { cookies } = this.props.cookies;
        let name = e.target.value;
        let email = cookies.email ? cookies.email : updateCustomerDetails.email.value;
        const isValid = this.validateFullName(name);
        const isEmailValid = validator.isEmail(email);
        if (isValid) {
            this.props.setName({ value: name, isValid: true, error: '' });
            !isEmailValid ?
                this.props.setEmail({ value: email, isValid: false, error: 'Enter a valid email address.' }) :
                this.props.setEmail({ value: email, isValid: true, error: '' });
        }
        else {
            this.props.setName({ value: name, isValid: false, error: 'Enter a valid name.' });
        }
        // !isValid ?
        //     this.props.setName({ value: name, isValid: false, error: 'Enter a valid name.' }) :
        //     
    }

    validateEmail = (e) => {
        const { cookies } = this.props.cookies;
        let email = e.target.value;
        let name = cookies.name ? cookies.name : updateCustomerDetails.name.value || this.props.order.customername;
        const isValid = validator.isEmail(email);
        const isNameValid = this.validateFullName(name);
        if (isValid) {
            this.props.setEmail({ value: email, isValid: true, error: '' });
            !isNameValid ?
                this.props.setName({ value: name, isValid: false, error: 'Enter a valid name.' }) :
                this.props.setName({ value: name, isValid: true, error: '' });
        }
        else {
            this.props.setEmail({ value: email, isValid: false, error: 'Enter a valid email address.' });
        }
        // !isValid ?
        //     this.props.setEmail({ value: email, isValid: false, error: 'Enter a valid email address.' }) :
        //     this.props.setEmail({ value: email, isValid: true, error: '' });
    }
    getEmpId = (e) => {
        let empid = e.target.value;
        if (empid)
            this.props.setEmpId(empid);
    }
    getCompanyDomain = (e) => {
        let cmpDomain = e.target.value;
        if (cmpDomain)
            this.props.setCmpDomain(cmpDomain);
    }
    getWorkEmail = (e) => {
        let workemail = e.target.value;
        if (workemail)
            this.props.setWorkEmail(workemail);
    }
    getCubicle = (e) => {
        let cubicle = e.target.value;
        if (cubicle)
            this.props.setCubical(cubicle);
    }
    getFloor = (e) => {
        let floor = e.target.value;
        if (floor)
            this.props.setFloor(floor);
    }
    getBuilding = (e) => {
        let building = e.target.value;
        if (building)
            this.props.setBuilding(building);
    }
    handleCompanyInfo = () => {
        this.setState({ showEmployeeInfo: true });
    }
    update = () => {
        const { updateCustomerDetails } = this.props.customer;
        const { name, email } = updateCustomerDetails;
        const cookie = this.props.cookies.cookies;
        const { cookies } = this.props;

        (email.isValid && name.isValid) ? (
            updateCustDetails(cookie.id, { name: name.value, email: email.value }, cookie.token).then(res => {
                this.props.toggleIsUpdated(true);
                this.props.setCustomer({
                    restaurantcustomer: res.data.id,
                    customername: res.data.name,
                    customerno: res.data.phoneno,
                    // customeremail: res.data.email,
                    createdby: res.data.id
                });
                cookies.set('name', _.startCase(res.data.name, { path: '/' }));
                cookies.set('email', res.data.email, { path: '/' });
                toast.success("Profile updated successfully.", {
                    className: css({
                        marginTop: '70px'
                    }),
                    bodyClassName: css({
                        fontSize: '17px'
                    }),
                });
                setTimeout(() => {
                    this.props.toggleIsUpdated(false);
                }, 3000);
            })
        ) : ''
    }

    updateCompanyInfo = () => {
        const { employeeid, workemail, workemaildomain, cubicle, floor, building } = this.props.customer.companyInfo;
        const cookie = this.props.cookies.cookies;
        const { cookies } = this.props;
        updateCompanyDetails(cookie.id, { company: { employeeid: employeeid, workemail: workemail, workemaildomain: workemaildomain, cubicle: cubicle, floor: floor, building: building } }, cookie.token).then(res => {
            this.props.toggleIsUpdated(true);
            res.data.company ? cookies.set('companyInfo', res.data.company, { path: '/' }) : cookies.remove('companyInfo', { path: '/' });
            toast.success("Company Info updated successfully.", {
                className: css({
                    marginTop: '70px'
                }),
                bodyClassName: css({
                    fontSize: '17px'
                }),
            });
            setTimeout(() => {
                this.props.toggleIsUpdated(false);
            }, 3000);
        })
    }

    render() {
        const { cookies } = this.props.cookies;
        const { updateCustomerDetails } = this.props.customer;
        let companyInfo = {};
        if (cookies && cookies.companyInfo) { companyInfo = JSON.parse(cookies.companyInfo); }

        return (
            <div className="col-md-6">
                <div className="box_style_2" id="order_process">
                    <h2 className="inner">Account Information</h2>
                    <div className="form-group col-md-12 col-sm-12 col-xs-6">
                        <label>Mobile</label>
                        <input type="text" id="tel_order" className="form-control" defaultValue={cookies.phoneno} disabled />
                    </div>
                    <div className="form-group col-md-12 col-sm-12 col-xs-6">
                        <label>Name</label>
                        <input
                            type="text"
                            className="form-control"
                            onInput={this.validateName}
                            defaultValue={cookies.name ? cookies.name : (updateCustomerDetails.name ? updateCustomerDetails.name.value : this.props.order.customername)}
                        />
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{updateCustomerDetails.name && updateCustomerDetails.name.error}</p>
                    </div>

                    <div className="form-group col-md-12">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control"
                            onInput={this.validateEmail}
                            defaultValue={cookies.email ? cookies.email : (updateCustomerDetails.email ? updateCustomerDetails.email.value : this.props.order.customeremail)}
                        />
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{updateCustomerDetails.email && updateCustomerDetails.email.error}</p>
                    </div>

                    <button type="submit" className="btn btn-primary" style={{ marginLeft: '15px' }} onClick={this.update}>Update</button>

                </div>
                <button type="submit" className="btn_1 green" onClick={this.handleCompanyInfo}><span style={{ marginRight: '20px' }}>Company Information</span><span className="glyphicon glyphicon-edit"></span></button>
                {
                    this.state.showEmployeeInfo ? (
                        <div className="box_style_2" id="order_process">
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm-6 col-xs-6">
                                    <label>Employee Id</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onInput={this.getEmpId}
                                        defaultValue={companyInfo ? companyInfo.employeeid : ''}
                                    />
                                </div>
                                <div className="form-group col-md-6 col-sm-6 col-xs-6">
                                    <label>Company Domain</label>
                                    <input type="text" className="form-control" onInput={this.getCompanyDomain} defaultValue={companyInfo ? companyInfo.workemaildomain : ''} />
                                </div>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Company Email</label>
                                <input type="email" className="form-control" onInput={this.getWorkEmail} defaultValue={companyInfo ? companyInfo.workemail : ''} />
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4 col-sm-4 col-xs-4">
                                    <label>Cubicle</label>
                                    <input type="text" className="form-control" onInput={this.getCubicle} defaultValue={companyInfo ? companyInfo.cubicle : ''} />
                                </div>
                                <div className="form-group col-md-4 col-sm-4 col-xs-4">
                                    <label>Floor</label>
                                    <input type="text" className="form-control" onInput={this.getFloor} defaultValue={companyInfo ? companyInfo.floor : ''} />
                                </div>
                                <div className="form-group col-md-4 col-sm-4 col-xs-4">
                                    <label>Building</label>
                                    <input type="text" className="form-control" onInput={this.getBuilding} defaultValue={companyInfo ? companyInfo.building : ''} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary" style={{ marginLeft: '15px' }} onClick={this.updateCompanyInfo}>Submit</button>
                        </div>
                    ) : ''
                }
            </div>
        );
    }
}

const mapStateToProps = {
    setEmail,
    setName,
    getSetCustDetails,
    setCustomer,
    toggleIsUpdated,
    setCompanyInfo,
    setEmpId,
    setCmpDomain,
    setWorkEmail,
    setCubical,
    setFloor,
    setBuilding
};
export default withCookies(connect(state => state, mapStateToProps)(UpdateCustomerInfo));