import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import GridListRestaurant from './GridListRestaurant';

import { setFoodCourtId, setFoodCourtName, setCentralizedBilling } from './../../actions/filterActions';
import loaderImg from './../../images/web-images/loader.gif';

class DisplayRestaurants extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    }

    handleOnClick = () => {
        this.props.setFoodCourtId("");
        this.props.setFoodCourtName('');
        this.props.setCentralizedBilling('false');
        this.props.history.push('/restaurants');
    }

    checkIfOpen = (openinghours, open) => {
        let openStatus = '';
        openinghours.some((openingHr) => {
            if (openStatus === '' || openStatus === 'Closed') {
                let hoursArray = openingHr.split('-');
                const currentTime = moment();
                const beforeTime = moment(hoursArray[0].trim(), 'hh:mm A');
                let afterTime = moment(hoursArray[1].trim(), 'hh:mm A');
                if (afterTime.isSameOrBefore(beforeTime)) {
                    afterTime = afterTime.add(1, 'd');
                }
                if (currentTime.isBefore(beforeTime) && open) { openStatus = 'Opened Early'; }
                else if (currentTime.isAfter(afterTime) && open) { openStatus = 'Still Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && open) { openStatus = 'Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && !open) { openStatus = 'Closed'; }
                else { openStatus = 'Closed'; }
            }
        });
        return openStatus;
    }


    render() {
        if (!this.props.restaurants.length === 0) {
            return (<div style={{ textAlign: 'center', marginTop: '200px' }}><img src={loaderImg} style={{ width: '50px' }} /><h2>Loading...</h2></div>);
        }
        return (
            <div className="col-md-9">
                {this.props.filters.foodcourtOnly ?
                    <a className="btn_full" onClick={this.handleOnClick}>Back to FoodCourts</a> : ''}
                {
                    this.props.restaurants.map((restaurant, index) => {
                        if (restaurant.isEmpty) {
                            return <div key={index} className="error-msg"><h4>Oops! No Restaurants Found</h4></div>
                        }

                        let outlets = [...restaurant.outlets];
                        outlets.forEach((outlet) => {
                            outlet.openinghours.length ? outlet.openStatus = this.checkIfOpen(outlet.openinghours, outlet.open) : outlet.openStatus = 'Closed';
                        });
                        return this.props.filters.foodcourtId ? (
                            (outlets.length !== 0 && restaurant.id === this.props.filters.foodcourtId) ? (
                                outlets.map((outlet, index) => (<GridListRestaurant key={index} outlet={outlet} />))
                            ) : ''
                        ) : (
                            outlets.length !== 0 && restaurant.entityName === 'general' ? (
                                outlets.map((outlet, index) => (<GridListRestaurant key={index} outlet={outlet} />))
                            ) : ''
                        )
                    })
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    setFoodCourtId,
    setFoodCourtName,
    setCentralizedBilling
};

export default withRouter(connect(state => state, mapDispatchToProps)(DisplayRestaurants));