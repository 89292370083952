import React from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';

import { setVerifyOTP } from './../../actions/formActions';
import { verifyOTP, sendOTP } from './../../utils/LoginApiLibrary';
import { getOrders } from './../../utils/CustomerLibrary';
import { toggleLoader } from './../../actions/loaderActions';
import { authenticateUser, setLoginType, setCompanyInfo } from './../../actions/customerActions';
import { closeModal, openModal } from './../../actions/modalActions';
import { setCustomer } from './../../actions/orderActions';
import { setOrderHistory } from './../../actions/orderHistoryActions';
import { setSelectedOrder, setGokash } from './../../actions/customerActions';
import { getGokash } from './../../utils/CustomerLibrary';

class VerifyUser extends React.Component {

    validate = (e) => {
        let OTP = e.target.value;
        const isValid = validator.isNumeric(OTP) && validator.isLength(OTP, { min: 6, max: 6 });
        !isValid ?
            this.props.setVerifyOTP({ value: OTP, isValid: false, error: 'Enter a valid 6 digit OTP.' }) :
            this.props.setVerifyOTP({ value: OTP, isValid: true, error: '' });
    }

    componentDidMount() {
        this.props.setVerifyOTP({ value: '', isValid: false, error: '' });
    }
    gokashBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getGokash(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokash(res.data) : 0 };
            })
    }

    showPasswordResetForm = () => {
        this.props.openModal('ResetPassword', { buttonClick: false });
    }


    verifyUser = (e) => {
        e.preventDefault();
        const mobileNum = this.props.mobile.value;
        const verifyotp = this.props.verifyotp.value;
        const { cookies } = this.props;
        const { loginType } = this.props.customer;
        const { path } = this.props.match;
        e.preventDefault();

        verifyOTP(mobileNum, verifyotp).then(res => {
            let expiryTime = res.data.user.accessToken.ttl - 120;
            if (res.data.message === 'Verified') {
                this.props.toggleLoader(true);
                if (loginType === 'resetpassword') {
                    this.props.setVerifyOTP({ value: verifyotp, isValid: true, error: 'OTP Verified' });
                    cookies.set('userid', res.data.user.accessToken.userId, { path: '/' }),
                        setTimeout(() => {
                            this.props.toggleLoader(false),
                                this.showPasswordResetForm()
                        }, 2000)
                }
                else if (loginType === 'register') {
                    this.props.setVerifyOTP({ value: verifyotp, isValid: true, error: 'OTP Verified' }),
                        //set the cookies
                        res.data.user.company ? cookies.set('companyInfo', res.data.user.company, { path: '/', maxAge: expiryTime }) : cookies.remove('companyInfo', { path: '/' }),
                        cookies.set('id', res.data.user.id, { path: '/', maxAge: expiryTime }),
                        cookies.set('name', _.startCase(res.data.user.name, { path: '/', maxAge: expiryTime })),
                        cookies.set('email', res.data.user.email, { path: '/', maxAge: expiryTime }),
                        cookies.set('phoneno', res.data.user.phoneno, { path: '/', maxAge: expiryTime }),
                        cookies.set('token', res.data.user.accessToken.id, { path: '/', maxAge: expiryTime }),
                        cookies.set('userid', res.data.user.accessToken.userId, { path: '/', maxAge: expiryTime })

                    //store the data in redux store
                    this.props.authenticateUser(true),
                        this.setAuthUser(res.data.user.id, res.data.user.accessToken.id),
                        this.props.setCustomer({
                            restaurantcustomer: res.data.user.id,
                            customername: res.data.user.name,
                            customerno: res.data.user.phoneno,
                            // customeremail: res.data.customeremail,
                            createdby: res.data.user.id
                        }),
                        res.data.user.company ? this.props.setCompanyInfo(res.data.user.company) : '',
                        //set gokash
                        this.gokashBalance(),

                        setTimeout(() => {
                            this.props.toggleLoader(false),
                                this.props.openModal('ResetPassword', { buttonClick: false, msg: 'set password' })
                        }, 2000)
                }
                else {
                    this.props.setVerifyOTP({ value: verifyotp, isValid: true, error: 'Login success' }),
                        //set the cookies
                        res.data.user.company ? cookies.set('companyInfo', res.data.user.company, { path: '/', maxAge: expiryTime }) : cookies.remove('companyInfo', { path: '/' }),
                        cookies.set('id', res.data.user.id, { path: '/', maxAge: expiryTime }),
                        cookies.set('name', _.startCase(res.data.user.name, { path: '/', expiryTime })),
                        cookies.set('email', res.data.user.email, { path: '/', maxAge: expiryTime }),
                        cookies.set('phoneno', res.data.user.phoneno, { path: '/', maxAge: expiryTime }),
                        cookies.set('token', res.data.user.accessToken.id, { path: '/', maxAge: expiryTime }),
                        cookies.set('userid', res.data.user.accessToken.userId, { path: '/', maxAge: expiryTime })

                    //store the data in redux store
                    this.props.authenticateUser(true),
                        this.setAuthUser(res.data.user.id, res.data.user.accessToken.id),
                        this.props.setCustomer({
                            restaurantcustomer: res.data.user.id,
                            customername: res.data.user.name,
                            customerno: res.data.user.phoneno,
                            customeremail: res.data.user.email,
                            createdby: res.data.user.id
                        }),
                        res.data.user.company ? this.props.setCompanyInfo(res.data.user.company) : '',
                        //set gokash
                        this.gokashBalance(),
                        (this.props.modal.data && this.props.modal.data.check === 'toPayment') ?
                            setTimeout(() => {
                                this.props.closeModal();
                                this.props.modal.data.toPay ? this.props.history.push('/payment-options') :
                                    this.props.history.push('/processing-order'),
                                    this.props.toggleLoader(false)
                            }, 1200) :
                            setTimeout(() => {
                                this.props.closeModal(),
                                    path == "/payment-options" || path == `/restaurant/${this.props.order.parentId}` ? this.props.history.push(`/restaurant/${this.props.order.parentId}`) : '',
                                    this.props.toggleLoader(false)
                            }, 2000)
                }

            }
        }).catch(e => {
            console.log(e);
            e.response.data.message === 'Invalid Details' || e.response.status === 401 ?
                this.props.setVerifyOTP({ value: verifyotp, isValid: false, error: 'Invalid OTP / OTP expired' }) : ''
        });
    }


    setAuthUser = (id, token) => {
        getOrders(id, token).then(result => this.props.setOrderHistory(result.data)),
            this.props.setSelectedOrder('')
    }

    resendOTP = (e) => {
        e.preventDefault();
        const mobileNum = this.props.mobile.value;
        const email = this.props.email.value;
        sendOTP(mobileNum, email).then(res => {
            this.props.setVerifyOTP({ value: '', isValid: false, error: 'OTP is sent again, please wait a while...' })
        });
    }

    render() {
        const { loginType } = this.props.customer;
        const verifyotp = this.props.verifyotp;
        const loginCss = {
            fontSize: '14px'
        }
        return (
            <form onSubmit={this.verifyUser} className="popup-form" id="myLogin">
                <div className="login_icon"><i className="icon-mobile-6"></i></div>
                <h3>Verify it's you</h3>
                <p>We've sent a verification code to your mobile number and email.</p>
                <input
                    type="text"
                    className="form-control form-white"
                    placeholder="Enter 6 digit code"
                    onInput={this.validate}
                    {...verifyotp.error ? { style: { borderColor: '#fd0000' } } : {}}
                    {...verifyotp.error === 'Login success' || verifyotp.error === 'OTP Verified' ? { style: { borderColor: '#0a820f' } } : {}}
                />
                <p {...verifyotp.error === 'Login success' || verifyotp.error === 'OTP Verified' ? { style: { fontSize: '14px', color: '#0a820f', textAlign: 'left' } } : { style: { fontSize: '14px', color: '#fd0000', textAlign: 'left' } }}>{verifyotp.error}</p>
                <div className="text-left">
                    {/* Didn't received OTP yet?<br /> Wait for a minute & click&nbsp; */}
                    <NavLink style={loginCss}
                        to="#"
                        onClick={this.resendOTP}
                    ><strong>Resend OTP</strong></NavLink>{loginType === 'otp' ? (
                        <strong style={loginCss}>&nbsp; / &nbsp;
                            <NavLink
                                to="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.setLoginType('password');
                                    this.props.openModal('UserLogin', { msg: '' });
                                }
                                }
                            >Login via Password</NavLink></strong>) : ''}<br />
                </div>
                <button
                    type="submit"
                    className="btn btn-submit"
                >Verify</button>
            </form>
        );
    }
}

const mapDispatchToProps = {
    setVerifyOTP,
    sendOTP,
    toggleLoader,
    authenticateUser,
    closeModal,
    openModal,
    setCustomer,
    setSelectedOrder,
    setOrderHistory,
    setGokash,
    setLoginType,
    setCompanyInfo

};
const mapStateToProps = (state) => {
    return {
        mobile: state.formfields.mobile,
        modal: state.modal,
        email: state.formfields.email,
        name: state.formfields.name,
        verifyotp: state.formfields.verifyotp,
        customer: state.customer,
        order: state.order
    }
}
export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(VerifyUser)));