import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import uuid4 from 'uuid';
import moment from 'moment';

import { setSelectedRestaurant } from './../../actions/restActions';
import { setDisabledItems } from './../../actions/itemActions';
import { toggleLoader } from './../../actions/loaderActions';
import { addRemoveItemFromCart, setRestFoodCourt, emptyCart, setChecksum, setGokashInOrder, setDiscountSplit, setParcelCharges, setEmpPerkInOrder, setDiscountId, setPerkInfoInOrder, setOrderType } from './../../actions/orderActions';
import { openCloseCart } from './../../actions/cartActions';
import { setGokash, setGokashPlus, setGokashConsumed, setGokashDiscount, setPerkInfo } from './../../actions/customerActions';
import { getGokash, getCreditDiscount, getEmployeeOffer, getGokashPlus } from './../../utils/CustomerLibrary';
import { calculateGokashDiscount } from './../../utils/CalculateGokash';
import { setRestData } from './../../actions/restAutoCompleteActions';
import { setCuisines } from './../../actions/cuisineActions';
import { setMenu } from './../../actions/menuActions';
import { openModal, closeModal } from './../../actions/modalActions';
import { fetchRestaurant, fetchMenu } from './../../utils/ApiLibrary';
import { disabled } from 'glamor';
import Modal from 'react-modal';
import ModalPopup from './ModalPopup';

class OrderSummary extends React.Component {
	constructor(props) {
		super(props);
		if (this.props.disabledItems.length) {
			if (this.props.disabledItems[0].restaurant !== this.props.order.parentId) { this.props.setDisabledItems([]); }
		}
		this.state = {
			items: [],
			itemscount: 0,
			totalprice: 0,
			grossamount: 0,
			taxamount: 0,
			taxitems: [],
			parcelcharge: 0,
			showDelivery: false
		}
	}

	state = {
		error: ''
	};

	async UNSAFE_componentWillMount() {
		const restaurantid = this.props.order.parentId;
		let menu = await fetchMenu(restaurantid).then(result => {
			this.props.setMenu(result);
			return result;
		});
		let taxGroup;
		let taxAmount;
		let taxes;
		let cartItems = [];
		let disabledItems = [];

		const { items } = this.props.order;
		if (this.props.disabledItems.length) disabledItems = JSON.parse(JSON.stringify(this.props.disabledItems));
		const totalItems = items.concat(disabledItems);
		if (totalItems.length) {
			totalItems.forEach((item) => {
				let menuItem = this.getMenuItem(item.restaurantmenuitem, menu);
				const restItem = disabledItems.filter(it => it.restaurantmenuitem === item.restaurantmenuitem);
				const existItem = items.filter(it => it.restaurantmenuitem === item.restaurantmenuitem);
				const cartItem = this.checkItemAlredyExists(existItem, item);
				if (menuItem && !_.isEmpty(menuItem)) {
					if (cartItem) {
						item.totalprice = this.getTotalPrice(item.itemprice, item.features, item.customeitems) * item.count;
						taxGroup = menuItem.taxgroup.items.filter(item => {
							if (!_.isEmpty(item)) return item;
						});
						taxes = this.getTaxAmountArray(item.totalprice, taxGroup);
						taxAmount = 0;
						taxes.forEach(tax => taxAmount += tax.amount);
						item.taxAmount = taxAmount;

						cartItems.push(item);
					}
					if (restItem && restItem.length) {
						restItem.forEach(item => {
							let index = disabledItems.indexOf(item);
							if (index > -1) {
								disabledItems.splice(index, 1);
							}
						})
					}
				}
				else {
					if (disabledItems.length) {
						if (_.isEmpty(restItem)) {
							disabledItems.push(item);
						}
					}
					else {
						disabledItems.push(item);
					}
				}
				this.props.setDisabledItems(disabledItems);
			})
		}
		let netPrice = await this.billing(cartItems);
		let taxItems = this.createTaxItems(cartItems);
		this.setState({
			items: cartItems,
			itemscount: netPrice.itemscount,
			totalprice: netPrice.totalprice,
			grossamount: netPrice.grossamount,
			taxamount: netPrice.taxamount,
			taxitems: taxItems,
			parcelcharge: netPrice.parcelcharges
		});
		// this.props.addRemoveItemFromCart(cartItems, netPrice, taxItems);
		this.checkDiscounts(cartItems);
	}

	gokashCreditDiscount = () => {
		const restaurantid = this.props.order.parentId;
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token) {
			return getCreditDiscount(restaurantid, cookie.token).then(res => {
				if (res && res.data.length) {
					this.setState({ gokashDiscount: res.data });
					this.props.setGokashDiscount(res.data);
					return res.data;
				}
				else return [];
			})
		}
		else {
			return [];
		}
	}

	gokashBalance = () => {
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token) {
			return getGokash(cookie.id, cookie.token).then(res => {
				if (res && res.data) {
					this.setState({ gokashBalance: res.data.credit });
					this.props.setGokash(res.data);
					return res.data.credit;
				}
				else return 0;
			})
		}
		else return 0;
	}
	walletBalance = () => {
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token)
			getGokashPlus(cookie.id, cookie.token).then(res => {
				if (res) { res.data ? this.props.setGokashPlus(res.data) : 0 };
			})
	}

	emptyCart = (e) => {
		e.preventDefault();
		this.props.emptyCart();
		this.props.setDisabledItems([]);
		if (window.screen.width < 991) {
			this.props.history.go(-1);
		}
	}

	checkItemAlredyExists = (existItem, newItem) => {

		let isSID = false;
		let isSF = false;
		let isSC = false;
		let isSame = false;
		let foundItem = undefined;

		existItem.forEach(item => {
			let eF = item.features;
			let nF = newItem.features;
			let eC = item.customeitems;
			let nC = newItem.customeitems;

			if (item.restaurantmenuitem === newItem.restaurantmenuitem) {
				isSID = true;
				if (eF.length === nF.length) {
					isSF = true;
					for (let i = 0; i < eF.length; i++) {
						isSF = eF[i].name === nF[i].name ? true : false;
					}
				}
				// if (eC.length && nC.length) {
				if (eC.length === nC.length) {
					isSC = true;
					for (let i = 0; i < eC.length; i++) {
						isSC = eC[i].categoryname === nC[i].categoryname ? true : false;
						if (eC[i].options.length === nC[i].options.length) {
							for (let j = 0; j < eC[i].options.length; j++) {
								isSC = eC[i].options[j].id === nC[i].options[j].id ? true : false;
							}
						} else { isSC = false }
					}
				}
				// }
			}

			if (isSID && isSF && isSC) { foundItem = item }
		});
		return foundItem;
	}

	checkIfOpen = (openinghours, open) => {
		if (!open) { open = false; }
		let openStatus = '';
		openinghours.some((openingHr) => {
			if (openStatus === '' || openStatus === 'Closed') {
				let hoursArray = openingHr.split('-');
				const currentTime = moment();
				const beforeTime = moment(hoursArray[0].trim(), 'hh:mm A');
				let afterTime = moment(hoursArray[1].trim(), 'hh:mm A');
				if (afterTime.isSameOrBefore(beforeTime)) {
					afterTime = afterTime.add(1, 'd');
				}
				if (currentTime.isBefore(beforeTime) && open) { openStatus = 'Opened Early'; }
				else if (currentTime.isAfter(afterTime) && open) { openStatus = 'Still Open'; }
				else if (currentTime.isBetween(beforeTime, afterTime) && open) { openStatus = 'Open'; }
				else if (currentTime.isBetween(beforeTime, afterTime) && !open) { openStatus = 'Closed'; }
				else { openStatus = 'Closed'; }
			}
		});
		return openStatus;
	}

	checkDelivery = (e) => {
		e.preventDefault();
		if (this.props.order.parentId == '5ece3e3280184677c45b5075' && (this.props.restaurant.takeaway || this.props.restaurant.delivery)) {
			this.setState({ showDelivery: true })
		} else {
			this.handleOrderNow();
		}
	}

	selectOrderType = (e) => {
		const orderType = e.target.id;
		if (orderType == 'takeaway') {
			// continue with old flow
			this.handleOrderNow();
		}
		else {
			// set ordertype delivery in order and show delivery address popup
			this.props.setOrderType(orderType);
			this.setState({ showDelivery: false });
			this.props.openModal('deliveryAddress');
		}
	}

	handleClose = () => {
		this.setState({ showDelivery: false });
		this.props.closeModal();
	}

	handleOrderNow = async () => {
		this.props.toggleLoader(true);
		if (this.props.customer.isAuthenticated) {
			await this.gokashCreditDiscount();
			await this.gokashBalance();
			this.walletBalance();
			await this.checkDiscounts(this.props.order.items);
		}
		// e.preventDefault();
		const restaurantid = this.props.order.parentId;
		let openinghours = [];

		fetchRestaurant(restaurantid).then(result => {
			if (result.data && result.data.openinghours && result.data.openinghours.length) {
				result.data.openinghours.forEach((openingHr) => {
					if (moment().format('dddd').toLowerCase() === openingHr.day.toLowerCase()) {
						openinghours.push(`${moment(openingHr.from, ['HH:mm']).format('hh:mm A')} - ${moment(openingHr.to, ['HH:mm']).format('hh:mm A')}`);
					}
				});

				let openStatus;
				openinghours.length ? openStatus = this.checkIfOpen(openinghours, result.data.open) : openStatus = 'Closed';

				let restaurantData = {
					id: result.data.id,
					name: result.data.name,
					address: result.data.address,
					imageUrl: result.data.imageUrl,
					cuisines: result.data.cuisines,
					isExist: true,
					openinghours,
					openStatus,
					dinein: result.data.isdineinavailable,
					takeaway: result.data.istakeawayavailable,
					delivery: result.data.isdeliveryavailable
				};

				let setFoodOrderData = {
					parentId: result.data.id,
					restaurantname: result.data.name,
					foodcourt: result.data.foodcourt ? result.data.foodcourt : '',
					foodcourtname: result.data.foodcourtRecord ? result.data.foodcourtRecord.name : '',
					imageUrl: result.data.imageUrl
				};

				(this.props.filters.foodcourtOnly && this.props.filters.foodcourtCB == "true") ? (
					setFoodOrderData.foodcourt = this.props.filters.foodcourtId,
					setFoodOrderData.foodcourtname = this.props.filters.foodcourtName
				) : ''

				this.props.setRestFoodCourt(setFoodOrderData);
				this.props.setSelectedRestaurant(restaurantData);

			}
		});

		setTimeout(() => {
			const { openStatus, openinghours } = this.props.restaurant;
			if (openinghours && openinghours.length) {
				if (openStatus === 'Closed') {
					this.setState({ error: `Oops! Restaurant is closed now. Try between ${this.props.restaurant.openinghours}` });
					this.props.toggleLoader(false);
				} else {
					this.props.openCloseCart(false);
					if (this.props.order.totalprice === 0) {
						this.setState({ error: '' });
						this.props.toggleLoader(false);
						if (!this.props.order.customerno) {
							this.props.openModal('UserLogin', { buttonClick: false, msg: 'checkout', toPay: false });
						}
						else {
							this.props.history.push('/processing-order');
						}

					}
					else {
						this.setState({ error: '' });
						this.props.toggleLoader(false);
						if (!this.props.order.customerno) {
							this.props.openModal('UserLogin', { buttonClick: false, msg: 'checkout', toPay: true });
						}
						else {
							this.props.history.push('/payment-options');
						}
					}
				}
			} else {
				this.setState({ error: `Oops! Restaurant is closed now.` });
				this.props.toggleLoader(false);
			}
		}, 2000);

	}

	getEmployeePerk = async (totalprice) => {
		const { parentId, foodcourt } = this.props.order;
		const cookie = this.props.cookies.cookies;
		const body = {
			restaurantid: parentId,
			foodcourtid: foodcourt,
			ordervalue: totalprice,
			orders: this.props.order
		}
		if (!_.isEmpty(cookie) && cookie.token)
			return getEmployeeOffer(body, cookie.token).then(res => {
				if (res && res.data.discountfromcompany) {
					this.props.setEmpPerkInOrder(res.data.discountfromcompany);
					this.props.setPerkInfo(res.data);
					this.props.setPerkInfoInOrder(res.data);
					return res.data.discountfromcompany;
				}
				else {
					this.props.setPerkInfo({});
					this.props.setPerkInfoInOrder({});
					return 0;
				}
			})
	}

	updateDiscountSplit = (discount, discountAmount) => {
		const store = this.props;
		let discountSplit = JSON.parse(JSON.stringify(this.props.order.discountsplit));
		if (!discountSplit.length && discount.discountfromcompany) {
			let perkDiscount = {
				id: store.customer.perkInfo.perk ? store.customer.perkInfo.perk : '',
				name: "Discount From Company",
				promotedby: "company",
				promotedgroup: "company",
				amount: store.order.discountfromcompany ? discountAmount : '',
				ontax: 0,
				isfremeal: store.order.discountfromcompany && store.order.isfreemeal
			}
			const index = discountSplit.findIndex(ds => ds.id == perkDiscount.id);
			if (index != -1) { discountSplit.splice(index, 1); }
			discountSplit.push(perkDiscount);
			this.props.setDiscountSplit(discountSplit);
		}
		else if (!discountSplit.length && _.isArray(discount) && discount.length && discount[0].id && discountAmount) {
			let gokashDiscount = {
				id: discount[0].id,
				name: "Gokash Credit Applied",
				promotedby: "gokhana",
				promotedgroup: "gokhana",
				amount: store.order.discountfromcredit ? store.order.discountfromcredit : '',
				ontax: 0
			}
			gokashDiscount[discount[0].valuetype] = discount[0].value;
			const index = discountSplit.findIndex(ds => ds.id == gokashDiscount.id);
			if (index != -1) { discountSplit.splice(index, 1); }
			discountSplit.push(gokashDiscount);
			this.props.setDiscountSplit(discountSplit);
			if (store.customer.gokashDiscount && store.customer.gokashDiscount.length) {
				this.props.setDiscountId(store.customer.gokashDiscount[0].id);
			}
			else this.props.setDiscountId("");
		}
		else if (discountSplit.length && (discount || discount.length)) {
			let compDisIndex;
			let gokashDisIndex;
			let compDiscount = {};
			let goDiscount = {};
			if (discount.perk) {
				compDisIndex = discountSplit.findIndex(dis => dis.id === discount.perk);
				if (compDisIndex != -1) {
					compDiscount = discountSplit.find(dis => dis.id === discount.perk);
					compDiscount = JSON.parse(JSON.stringify(compDiscount));
					compDiscount.amount = discountAmount;
					discountSplit.splice(compDisIndex, 1);
					if (discountAmount) discountSplit.push(compDiscount);
					this.props.setDiscountSplit(discountSplit);
				}
				else {
					let pIndex = discountSplit.findIndex(dis => dis.name == "Discount From Company");
					if (pIndex != -1) {
						discountSplit.splice(pIndex, 1);
					}
					let perkDiscount = {
						id: store.customer.perkInfo.perk ? store.customer.perkInfo.perk : '',
						name: "Discount From Company",
						promotedby: "company",
						promotedgroup: "company",
						amount: store.order.discountfromcompany ? discountAmount : '',
						ontax: 0,
						isfremeal: store.order.discountfromcompany && store.order.isfreemeal
					}
					if (discountAmount) discountSplit.push(perkDiscount);
					this.props.setDiscountSplit(discountSplit);

				}
			}
			else if (_.isArray(discount) && discount.length) {
				gokashDisIndex = discountSplit.findIndex(dis => dis.id === discount[0].id);
				if (gokashDisIndex != -1) {
					goDiscount = discountSplit.find(dis => dis.id === discount[0].id);
					goDiscount = JSON.parse(JSON.stringify(goDiscount));
					goDiscount.amount = discountAmount;
					goDiscount[discount[0].valuetype] = discount[0].value;
					discountSplit.splice(gokashDisIndex, 1);
					if (discountAmount) discountSplit.push(goDiscount);
					this.props.setDiscountSplit(discountSplit);
					if (store.customer.gokashDiscount && store.customer.gokashDiscount.length) {
						this.props.setDiscountId(store.customer.gokashDiscount[0].id);
					}
					else this.props.setDiscountId("");
				}
				else {
					let goIndex = discountSplit.findIndex(dis => dis.name == "Gokash Credit Applied");
					if (goIndex != -1) {
						discountSplit.splice(goIndex, 1);
					}
					let gokashDiscount = {
						id: discount[0].id,
						name: "Gokash Credit Applied",
						promotedby: "gokhana",
						promotedgroup: "gokhana",
						amount: store.order.discountfromcredit ? store.order.discountfromcredit : '',
						ontax: 0
					}
					gokashDiscount[discount[0].valuetype] = discount[0].value;
					if (store.order.discountfromcredit) discountSplit.push(gokashDiscount);
					this.props.setDiscountSplit(discountSplit);
					if (store.customer.gokashDiscount && store.customer.gokashDiscount.length) {
						this.props.setDiscountId(store.customer.gokashDiscount[0].id);
					}
					else this.props.setDiscountId("");

				}
			}
			else {
				// this.props.setDiscountSplit([]);
				this.props.setDiscountId("");
			}
		}
	}

	getTaxAmountArray = (price, taxgroup) => {
		let taxAmount = [];
		let taxobj = {};
		if (taxgroup.length !== 0) {
			taxgroup.forEach(tax => {
				taxobj = {};
				taxobj.name = tax.name;
				if (tax.type === 'percentage') {
					taxobj.amount = Math.round(price * tax.value * 0.01);
				} else if (tax.type === 'flat') {
					taxobj.amount = Number(tax.value);
				}
				taxAmount = [...taxAmount, taxobj];
			})
		}

		return taxAmount;
	}

	addItem = async (e) => {
		e.preventDefault();
		const id = e.currentTarget.id;
		const menuId = e.currentTarget.attributes.getNamedItem('data-mid').value;
		let price = e.currentTarget.attributes.getNamedItem('data-price').value;

		const menuItem = this.getMenuItem(menuId);
		const taxGroup = menuItem.taxgroup.items.filter(item => {
			if (!_.isEmpty(item)) return item;
		});
		let taxItems = this.props.order.taxitems;
		let taxAmount = 0;
		let storeItems = [...this.props.order.items];
		let updatedItems = storeItems;
		let netPrice;
		let addTax;

		const checksum = uuid4();
		this.props.setChecksum(checksum);
		let order = this.props.order;
		order = { ...order, checksum: checksum }

		let addItemId = storeItems.find(item => {
			return item.id === id;
		});

		price = (addItemId.totalprice / addItemId.count);
		let parcelcharge = (addItemId.parcelcharge / addItemId.count)
		updatedItems.forEach(item => {
			if (item.id === addItemId.id) {
				item.count = item.count + 1;
				item.parcelcharge = addItemId.parcelcharge + parcelcharge;
				item.totalprice += price;
				addTax = this.getTaxAmountArray(item.totalprice, taxGroup);
				addTax.forEach(tax => taxAmount += tax.amount);
				item.taxAmount = taxAmount;
			};
		});
		netPrice = await this.billing(updatedItems);
		taxItems = this.createTaxItems(updatedItems);

		this.setState({
			items: updatedItems,
			itemscount: netPrice.itemscount,
			totalprice: netPrice.totalprice,
			grossamount: netPrice.grossamount,
			taxamount: netPrice.taxamount,
			taxitems: taxItems,
			parcelcharge: netPrice.parcelcharges
		});
		this.checkDiscounts(updatedItems);

	}

	removeItem = async (e) => {
		let itemscount = 0;
		this.props.order.items.forEach(item => {
			itemscount = itemscount + item.count;
		});
		if (itemscount == 1) {
			this.props.setDiscountSplit([]);
			this.props.setEmpPerkInOrder(0);
		}
		e.preventDefault();
		const id = e.currentTarget.id;
		const menuId = e.currentTarget.attributes.getNamedItem('data-mid').value;
		let price = e.currentTarget.attributes.getNamedItem('data-price').value;

		const menuItem = this.getMenuItem(menuId);
		const taxGroup = menuItem.taxgroup.items.filter(item => {
			if (!_.isEmpty(item)) return item;
		});
		let taxItems = this.props.order.taxitems;
		let taxAmount = 0;
		let storeItems = [...this.props.order.items];
		let updatedItems = storeItems;
		let netPrice;
		let deductTax;

		const checksum = uuid4();
		this.props.setChecksum(checksum);
		let order = this.props.order;
		order = { ...order, checksum: checksum }

		let deleteItem = this.props.order.items.find(item => {
			return item.id === id;
		});

		let parcelcharge = (deleteItem.parcelcharge / deleteItem.count);
		price = (deleteItem.totalprice / deleteItem.count);
		deductTax = this.getTaxAmountArray(deleteItem.totalprice, taxGroup);

		if (deleteItem.count > 1) {
			updatedItems.forEach(item => {
				if (item.id === deleteItem.id) {
					item.count = item.count - 1;
					item.totalprice -= price;
					item.parcelcharge = deleteItem.parcelcharge - parcelcharge;
					deductTax = this.getTaxAmountArray(item.totalprice, taxGroup);
					deductTax.forEach(tax => taxAmount += tax.amount);
					item.taxAmount = taxAmount;
				}
			});

		} else {
			updatedItems = storeItems.filter(item => item.id != deleteItem.id);
		}

		netPrice = await this.billing(updatedItems);
		taxItems = this.createTaxItems(updatedItems);

		this.setState({
			items: updatedItems,
			itemscount: netPrice.itemscount,
			totalprice: netPrice.totalprice,
			grossamount: netPrice.grossamount,
			taxamount: netPrice.taxamount,
			taxitems: taxItems
		});
		this.checkDiscounts(updatedItems);
	}
	removeInactive = (e) => {
		let newInactiveItems = [];
		newInactiveItems = this.props.disabledItems.filter(disItem => disItem.restaurantmenuitem !== e.currentTarget.id);
		this.props.setDisabledItems(newInactiveItems);
	}

	createTaxItems = (items) => {
		let newTax = [];
		let singleItemTax;
		items.forEach(item => {
			let taxRule = this.getMenuItem(item.restaurantmenuitem);
			if (taxRule) {
				singleItemTax = this.getTaxAmountArray(item.totalprice, taxRule.taxgroup.items);
			}
			if (singleItemTax && newTax.length === 0) {
				newTax = singleItemTax;
			} else {
				for (let i = 0; i < newTax.length; i++) {
					if (newTax[i].name === singleItemTax[i].name) newTax[i].amount += singleItemTax[i].amount;
				}
			}
		});
		return newTax;
	}

	getTotalPrice = (price, addonitems, customeitems) => {
		if (addonitems.length !== 0) {
			addonitems.forEach(item => price += Number(item.price));
		}

		if (customeitems.length !== 0) {
			customeitems.forEach(item => {
				(item.options.length > item.freechoicescount) ? (
					price += item.totalprice
				) : '';
			});
		}

		return price;
	}

	getTaxAmountArray = (price, taxgroup) => {
		let taxAmount = [];
		let taxobj = {};
		if (taxgroup.length !== 0) {
			taxgroup.forEach(tax => {
				taxobj = {};
				taxobj.name = tax.name;
				if (tax.type === 'percentage') {
					taxobj.amount = Math.round(price * tax.value * 0.01);
				} else if (tax.type === 'flat') {
					taxobj.amount = Number(tax.value);
				}
				taxAmount = [...taxAmount, taxobj];
			})
		}

		return taxAmount;
	}

	getTaxAmount = (price, taxes) => {
		let taxAmount = 0;
		if (taxes.length !== 0) {
			taxes.forEach(tax => {
				if (tax.type === 'percentage') {
					taxAmount += Math.round(price * tax.value * 0.01);
				} else if (tax.type === 'flat') {
					taxAmount += Number(tax.value);
				}
			})
		}
		return taxAmount;
	}

	getItemsCount = (items) => {
		let itemscount = 0;
		if (items.length !== 0) {
			items.forEach(item => {
				itemscount = itemscount + item.count;
			})
		}
		return itemscount;
	}
	checkDiscounts = async (updatedItems) => {
		const store = this.props;
		let netPrice = {
			itemscount: 0,
			totalprice: 0,
			grossamount: 0,
			taxamount: 0,
			discountapplied: 0
		};
		netPrice.grossamount = this.state.grossamount;
		netPrice.totalprice = this.state.grossamount + this.state.taxamount + this.state.parcelcharge;
		netPrice.taxamount = this.getTax(this.state.items);
		netPrice.itemscount = this.state.itemscount;
		let gdiscount = [];
		let gbalance = 0;
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token) {
			gdiscount = await this.gokashCreditDiscount();
			gbalance = await this.gokashBalance();
		}
		if (this.props.customer.isAuthenticated) {
			if (store.customer.companyInfo && store.customer.companyInfo.employeeid) {
				let perkAmount = netPrice.totalprice ? await this.getEmployeePerk(netPrice.totalprice) : 0;
				if (perkAmount) {
					this.updateDiscountSplit(store.customer.perkInfo, perkAmount);
					netPrice.totalprice = netPrice.totalprice - perkAmount;
					// if (netPrice.totalprice) {
					netPrice.discountapplied = calculateGokashDiscount(gdiscount, netPrice.totalprice, gbalance);
					netPrice.totalprice = netPrice.totalprice - netPrice.discountapplied;

					this.props.setGokashInOrder(netPrice.discountapplied);
					this.props.setGokashConsumed(netPrice.discountapplied);
					this.updateDiscountSplit(gdiscount, netPrice.discountapplied);
					// }
				}
				else {
					netPrice.discountapplied = calculateGokashDiscount(gdiscount, netPrice.totalprice, gbalance);
					netPrice.totalprice = netPrice.grossamount + netPrice.taxamount - netPrice.discountapplied + this.state.parcelcharge;
					this.props.setGokashInOrder(netPrice.discountapplied);
					this.props.setGokashConsumed(netPrice.discountapplied);
					this.updateDiscountSplit(gdiscount, netPrice.discountapplied);
				}
			}
			else {
				netPrice.discountapplied = calculateGokashDiscount(store.customer.gokashDiscount, netPrice.totalprice, store.customer.gokash);
				netPrice.totalprice = netPrice.grossamount + netPrice.taxamount - netPrice.discountapplied + this.state.parcelcharge;
				this.props.setGokashInOrder(netPrice.discountapplied);
				this.props.setGokashConsumed(netPrice.discountapplied);
				this.updateDiscountSplit(gdiscount, netPrice.discountapplied);
			}
		}
		const taxItems = this.createTaxItems(updatedItems);
		this.props.addRemoveItemFromCart(updatedItems, netPrice, taxItems);
	}
	billing = async (items) => {
		const store = this.props;
		let netPrice = {
			itemscount: 0,
			totalprice: 0,
			grossamount: 0,
			taxamount: 0,
			discountapplied: 0,
			parcelcharges: 0
		};
		if (store.customer.isAuthenticated) {
			this.gokashBalance();
		}

		netPrice.grossamount = this.getGrossAmount(items);
		netPrice.taxamount = this.getTax(items);
		netPrice.itemscount = this.getItemsCount(items);

		netPrice.parcelcharges = this.getParcelCharges(items);
		this.props.setParcelCharges(netPrice.parcelcharges);
		netPrice.totalprice = netPrice.grossamount + netPrice.taxamount + netPrice.parcelcharges;

		return netPrice;
	}

	getGrossAmount = (items) => {
		let amt = 0;
		if (items.length !== 0) {
			items.forEach(item => amt += item.totalprice)
		};
		return amt;
	}

	getTax = (items) => {
		let amt = 0;
		if (items.length !== 0) {
			items.forEach(item => amt += !_.isNaN(item.taxAmount) ? item.taxAmount : 0)
		}
		return amt;
	}

	getParcelCharges = (items) => {
		let parcelcharges = 0;
		items.length !== 0 ? (
			items.forEach(item => {
				if (item.parcelchargetype === "flat" && item.parcelchargevalue > 0) {
					parcelcharges = parcelcharges + (item.count * item.parcelchargevalue);
				}
				else if (item.parcelchargetype === "percentage" && item.parcelchargevalue > 0) {
					parcelcharges = parcelcharges + (item.parcelchargevalue * 0.01 * item.totalprice);
				}
			})
		) : '';
		return parcelcharges;
	}

	getMenuItem = (id, menu) => {
		let menuItems;
		menu ? menuItems = menu : menuItems = this.props.menu;
		let menuItem = {};
		menuItems.map(category => {
			if (category.menuitems.length !== 0) {
				category.menuitems.forEach(item => item.id === id ? menuItem = { ...item } : '')
			}
			if (category.subcategories.length !== 0) {
				category.subcategories.forEach(subcat => {
					subcat.menuitems.forEach(item => item.id === id ? menuItem = { ...item } : '')
				})
			}
		});
		return menuItem;
	}

	render() {
		return (
			<div className="theiaStickySidebar" style={{ backgroundColor: '#fff' }}>
				{!this.props.restaurant ? <Redirect to="/restaurants" /> : ''}
				{/* <div className="theiaStickySidebar"> */}
				<div style={{ paddingBottom: '150px' }}>
					<div id="cart_box">
						<h3>Your order<i className="icon_cart_alt pull-right"></i></h3>
						<hr />
						<table className="table table_summary">
							<tbody>
								{
									this.props.order.items.length ? (
										this.props.order.items.map((item, index) => {
											return (
												<tr key={index}>
													<td title={item.menuitemname}>
														<NavLink
															to="#"
															className="remove_item"
															id={item.id}
															data-mid={item.restaurantmenuitem}
															data-price={item.totalprice}
															onClick={this.removeItem}
														>
															<i className="icon_minus_alt"></i>
														</NavLink>
														&nbsp;<strong style={{ verticalAlign: 'text-bottom' }}>{item.count}</strong>&nbsp;
<NavLink
															to="#"
															className="remove_item"
															id={item.id}
															data-mid={item.restaurantmenuitem}
															data-price={item.totalprice}
															onClick={this.addItem}
														>
															<i className="icon_plus_alt"></i>
														</NavLink>
														&nbsp;
{item.menuitemname.length > 20 ? `${item.menuitemname.substring(0, 20)}...` : item.menuitemname}
														{
															item.features.length !== 0 ? (
																item.features.map(option => {
																	return <div title={option.name}><i className="icon-check-1"></i>{option.name.length > 18 ? `${option.name.substring(0, 18)}...` : option.name}</div>
																})
															)
																: <div></div>
														}
														{
															item.customeitems.length !== 0 ? (
																item.customeitems.map(option => {
																	return (
																		<div title={option.categoryname}>
																			<i className="icon-check-1"></i>
																			{option.categoryname.length > 18 ? `${option.categoryname.substring(0, 18)}...` : option.categoryname}
																			{
																				option.options.map(opt => {
																					return <div className="sub-options" title={opt.name}><i className="icon-minus"></i>{opt.name}</div>
																				})
																			}
																		</div>
																	)
																})
															)
																: <div></div>
														}
													</td>
													<td>
														<strong className="pull-right"> &#8377;{item.totalprice}</strong>
													</td>
												</tr>
											)
										})
									) : <tr><td><h4 style={{ textAlign: 'center' }}>Your cart is empty</h4></td></tr>
								}
								{this.props.disabledItems && this.props.disabledItems.length !== 0 && (
									this.props.disabledItems.map((disabledItem, index) => {
										return (<tr key={index}>
											<td style={{ color: '#000000', opacity: '0.4' }} title="Item not available at the moment !">
												<NavLink
													to="#"
													id={disabledItem.restaurantmenuitem}
													onClick={this.removeInactive}
												>
													<i className="icon-cancel"></i>
												</NavLink>
												{disabledItem.menuitemname}
											</td>
										</tr>)
									})
								)}
							</tbody>
						</table>
						<hr />
						{/*}
<div className="row" id="options_2">
<div className="col-lg-6 col-md-12 col-sm-12 col-xs-6">
<label><input type="radio" value="" checked name="option_2" className="icheck" />Delivery</label>
</div>
<div className="col-lg-6 col-md-12 col-sm-12 col-xs-6">
<label><input type="radio" value="" name="option_2" className="icheck" />Take Away</label>
</div>
</div>
*/}

						<table className="table table_summary">
							<tbody>
								{this.props.order.grossamount > 0 && <tr>
									<td>
										Subtotal <span className="pull-right">&#8377; {this.props.order.grossamount}</span>
									</td>
								</tr>}
								{/* <tr>
<td>
Subtotal <span className="pull-right">&#8377; {this.props.order.grossamount}</span>
</td>
</tr> */}
								{
									this.props.order.items.length !== 0 && (
										this.props.order.taxitems.map((item, index) => {
											if (item.amount > 0) {
												return (
													<tr key={index}>
														<td>
															{item.name}
															<span className="pull-right">
																&#8377; {item.amount}
															</span>
														</td>
													</tr>
												)
											}
										})
									)
								}
								{
									(this.props.order.parcelcharge && this.props.order.parcelcharge > 0) ? (
										<tr>
											<td>
												Parcel Charge <span className="pull-right">&#8377; {this.props.order.parcelcharge}</span>
											</td>
										</tr>
									) : <tr></tr>
								}
								{
									(this.props.order.discountfromcompany) ? (
										<tr style={{ color: 'green' }}>
											<td>
												Discount From Company <span className="pull-right">&#8377; {this.props.order.discountfromcompany.toFixed(2)}</span>
											</td>
										</tr>
									) : <tr></tr>
								}
								{
									(this.props.order.discountfromcredit > 0 && this.props.customer.gokashDiscount.length) ? (
										<tr style={{ color: 'green' }}>
											<td>
												Gokash Credit Applied <span className="pull-right">&#8377; {this.props.order.discountfromcredit.toFixed(2)}</span>
											</td>
										</tr>
									) : <tr></tr>
								}
								<tr>
									<td className="total">
										TOTAL <span className="pull-right">&#8377; {this.props.order.totalprice > 0 ? this.props.order.totalprice.toFixed(2) : 0}</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div style={{ maxHeight: '150px', paddingLeft: '20px', paddingRight: '20px', border: '2px solid #ededed', backgroundColor: '#ffffff' }}>
						{
							this.props.order.items.length !== 0 && this.props.cart.showButtons ? (
								<div>
									<p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.state.error}</p>
									<NavLink to="#" className="btn_full" onClick={this.checkDelivery}>{this.props.order.totalprice === 0 ? 'Place Order' : 'Order Now'}</NavLink>
									<NavLink to="#" className="btn_full" onClick={this.emptyCart}>Empty Cart</NavLink>
								</div>
							) : ''
						}
						{/* <NavLink
							to="#"
							id="close-cart-window"
							onClick={(e) => { e.preventDefault(); this.props.openCloseCart(false) }}
						>
							<i className="icon-cancel-circle-2"></i>
						</NavLink> */}
					</div>
					<div>
						{this.state.showDelivery ? <Modal
							isOpen={this.state.showDelivery}
							contentLabel="Common Modal"
							className="Modal"
							overlayClassName="Overlay"
							closeTimeoutMS={200}
							ariaHideApp={false}

						>
							<div className="modal-dialog">
								<div className="modal-content modal-popup" style={{ height: '300px' }}>
									<NavLink
										to="#"
										className="close-link"
										onClick={e => { e.preventDefault(); this.handleClose() }}
									>
										<i className="icon_close_alt2"></i>
									</NavLink>
									<h3 style={{ marginTop: '50px' }}>Select Order Type</h3>
									<div className="row">
										{this.props.restaurant.takeaway && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '25px' }}>
											<button id='takeaway' onClick={this.selectOrderType} style={{ width: '90%', padding: '10px' }}>
												Takeaway
											</button>
										</div>}
										{this.props.restaurant.delivery && <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '25px' }}>
											<button id='delivery' onClick={this.selectOrderType} style={{ width: '90%', padding: '10px' }}>
												Delivery
											</button>
										</div>}
									</div>
								</div>
							</div>
						</Modal> : ''}
					</div>
				</div>
				{/* </div> */}

				{((this.props.modal.modalType === "deliveryAddress"
				) && this.props.modal.isModalOpen) ?
					<ModalPopup
						isModalOpen={this.props.modal.isModalOpen}
						handleClose={this.handleClose}
						modalType={this.props.modal.modalType}
						data={this.props.modal.data}
						handleOrderNow={this.handleOrderNow}
					/> : <div></div>
				}
			</div>
		);
	}
}

const mapDispatchToProps = {
	setSelectedRestaurant,
	addRemoveItemFromCart,
	emptyCart,
	setChecksum,
	openCloseCart,
	setGokashInOrder,
	setGokash,
	setGokashPlus,
	setDiscountSplit,
	setGokashConsumed,
	setGokashDiscount,
	setParcelCharges,
	setDisabledItems,
	setRestData,
	setCuisines,
	setRestFoodCourt,
	setMenu,
	toggleLoader,
	setEmpPerkInOrder,
	setPerkInfo,
	setDiscountId,
	setPerkInfoInOrder,
	openModal,
	closeModal,
	setOrderType
};

export default withCookies(withRouter(connect(state => state, mapDispatchToProps)(OrderSummary)));

