import React from 'react';
import qs from 'query-string';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import uuidv1 from 'uuid/v1';
import _ from 'lodash';

import { emptyCart, setPaymentTxn, setOrderData, setOrderId, removeFoodcourtdata, resetCustomer } from './../../actions/orderActions';
import { selectTab } from './../../actions/customerActions';
import { setDisabledItems } from './../../actions/itemActions';
import { getResponse, createFoodOrder, sendNotification, getOrderDetails, getAnonymousOrder } from './../../utils/PaymentGateway';
import loader from './../../images/web-images/loading.gif';

class PgResponse extends React.Component {
    response;
    constructor() {
        super();
        this.state = {
            message: 'Please wait while we are processing your payment...',
            retry: 0
        }
    }

    componentDidMount() {
        const { state } = this.props.history.location;
        let response = qs.parse(location.search);
        if (state && state.response.txn) {
            response = state.response;
        }

        let notificationmessage = {
            notificationid: uuidv1(),
            appId: '5825d15cf009c4bc37fd7b9f',
            profile: 'gokhana',
            targets: [],
            message: '',
            title: "New food order",
            info: {
                orderid: '',
                restaurant: 'restaurant',
                orderstatus: 'requested'
            }
        };
        const { cookies } = this.props.cookies;
        const accesstoken = 'iyvclEkLFYAlBHW5O3aJiXqAQuwEZR5IWshjbweLtHbuA1MGjRdLEICmq6xGfCkk';
        const order = { ...this.props.order };
        delete order.orderResponse;
        const placedTime = moment().toISOString();
        const orderData = {
            placedTime,
            paidamount: order.paidamount
        };

        if (response.orderid) {
            if (response.orderid && order.restaurantcustomer) {
                getOrderDetails(order.restaurantcustomer, response.orderid, cookies.token).then(result => {
                    notificationmessage.targets.push(result.data.parentId);
                    notificationmessage.info.orderid = result.data.orderid;
                    notificationmessage.info.restaurant = result.data.parentId;
                    notificationmessage.info.orderstatus = result.data.orderstatus;
                    notificationmessage.message = `Customer has placed a new order: ${result.data.orderid}. Please start preparing it`;

                    this.props.emptyCart();
                    order.customerno && !order.restaurantcustomer ? this.props.resetCustomer() : ';'
                    this.props.setDisabledItems([]);
                    this.props.removeFoodcourtdata();
                    this.props.setOrderId(result.data);
                    sendNotification(notificationmessage, cookies.token)
                        .then(() => {
                            this.props.history.push('/order-confirmation');
                        }).catch((e) => {
                        });
                });
            } else {
                getAnonymousOrder(order.parentId, response.orderid, accesstoken).then(result => {
                    notificationmessage.targets.push(result.data.parentId);
                    notificationmessage.info.orderid = result.data.orderid;
                    notificationmessage.info.restaurant = result.data.parentId;
                    notificationmessage.info.orderstatus = result.data.orderstatus;
                    notificationmessage.message = `Customer has placed a new order: ${response.orderid}. Please start preparing it`;

                    this.props.emptyCart();
                    this.props.setDisabledItems([]);
                    this.props.removeFoodcourtdata();
                    this.props.setOrderId(result.data);
                    sendNotification(notificationmessage, accesstoken)
                        .then(() => {
                            this.props.history.push('/order-confirmation');
                        }).catch((e) => { });
                });
            }

        }
        else if (!response.orderid) {
            getResponse(response.txn, cookies.token || accesstoken).then(res => {
                let txnData = {
                    paidamount: res.data.amount,
                    transactionid: res.data.id,
                    externaltransactionid: res.data.externaltransactionid || '',
                    upirrn: res.data.upirrn || '',
                    paymentgateway: res.data.paymentgateway,
                    paymenttype: res.data.paymenttype,
                    paidtime: orderData.placedTime,
                    bankname: res.data.bankname || ''
                };

                if (res.data.status === 'AUTHORIZED' ||
                    res.data.status === 'TXN_SUCCESS' ||
                    res.data.status === 'SUCCESS' ||
                    res.data.status === 'CLEARED' ||
                    res.data.status === 'CLEARANCE_INITIATED') {

                    //Set transaction details in order
                    this.props.setPaymentTxn(txnData);
                    order.paymenttransactions = [txnData];

                    this.props.setOrderData(orderData);
                    order.placedtime = orderData.placedTime;
                    order.paidamount = order.totalprice;
                    order.paid = true;

                    //create the food order
                    this.createOrder(order.parentId, order, cookies.token || accesstoken, notificationmessage);

                }

                else if (res.data.status === 'TXN_FAILURE' || res.data.status === 'FAILURE' || res.data.status === "CANCELLED_BY_USER_AGENT") {
                    this.props.history.push('/payment-failed');
                }
                else {
                    console.log("Neither success nor faliure response received, please review PgResponse.js");
                }
            });
        }
        else
            this.props.history.push('/payment-failed');

    }

    createOrder = (parentId, order, token, notificationmessage) => {
        createFoodOrder(parentId, order, token).then(result => {
            if (result.status == 200) {
                var orderId = ""
                if (result && result.data && result.data.orderid) {
                    orderId = result.data.orderid
                    notificationmessage.targets.push(result.data.parentId);
                    notificationmessage.info.orderid = orderId;
                    notificationmessage.info.restaurant = result.data.parentId;
                    notificationmessage.info.orderstatus = result.data.orderstatus;
                    notificationmessage.message = `Customer has placed a new order: ${orderId}. Please start preparing it`;
                }
                result.data.orderid ? (
                    this.props.emptyCart(),
                    this.props.setDisabledItems([]),
                    this.props.removeFoodcourtdata(),
                    this.props.setOrderId(result.data),
                    sendNotification(notificationmessage, token)
                        .then(res => {
                            this.props.history.push('/order-confirmation');
                        })
                ) : ''
            }
        }).catch(e => {
            if (e.response) {
                if (e.response.status == 404) {
                    this.retryCreateOrder(10000, parentId, order, token, notificationmessage);
                } else if (e.response.status == 504) {
                    this.retryCreateOrder(10000, parentId, order, token, notificationmessage);
                }
            } else if (e.code) {
                if (e.code == 'ECONNABORTED') {
                    this.retryCreateOrder(10000, parentId, order, token, notificationmessage);
                }
            }
        });
    }

    retryCreateOrder = (timeout, parentId, order, token, notificationmessage) => {
        if (this.state.retry < 6) {
            this.setState({ retry: this.state.retry + 1, message: 'Just a moment we are trying to place your order...' });
            setTimeout(() => {
                this.createOrder(parentId, order, token, notificationmessage);
            }, timeout);

        } else {
            this.props.history.push('/order-failed');
        }
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <img src={loader} width="120px" />
                <h4>{this.state.message}</h4>
                <h5>(Do not press the refresh or back button)</h5>
            </div>
        );
    }
}
const mapDispatchToProps = {
    emptyCart,
    setPaymentTxn,
    setOrderData,
    createFoodOrder,
    setOrderId,
    selectTab,
    removeFoodcourtdata,
    setDisabledItems,
    resetCustomer
};

export default withCookies(withRouter(connect(state => state, mapDispatchToProps)(PgResponse)));