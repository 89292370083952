import React from 'react';

import deviceImg from './../../images/devices.jpg';

export default () => {
    return (
        <div className="container margin_60_35">
            <div className="row">
                <div className="col-md-4">
                    <h3 className="nomargin_top">Some words about us</h3>
                    <p>
                        Id pri consul aeterno petentium. Vivendo abhorreant et vim, et quot persecuti mel. Libris hendrerit ex sea. Duo legere evertitur an, pri hinc <strong>doctus definitiones</strong> an, vix id dicam putent. Ius ornatus instructior in.
                    </p>
                    <p>
                        Id pri consul aeterno petentium. Vivendo abhorreant et vim, et quot persecuti mel. Libris hendrerit ex sea. Duo legere evertitur an, pri hinc doctus definitiones an, vix id dicam putent. Ius ornatus instructior in.
                    </p>
                    <h4>Mission</h4>
                    <p>
                        Id pri consul aeterno petentium. Vivendo abhorreant et vim, et quot persecuti mel. <strong>Libris hendrerit</strong> ex sea. Duo legere evertitur an, pri hinc doctus definitiones an, vix id dicam putent. Ius ornatus instructior in.
                    </p>
                    <p>
                        Id pri consul aeterno petentium. Vivendo abhorreant et vim, et quot persecuti mel. Libris hendrerit ex sea. Duo legere evertitur an, pri hinc doctus definitiones an, vix id dicam putent. Ius ornatus instructior in.
                    </p>
                    <h4>Filosofy</h4>
                    <p>
                        Id pri consul aeterno petentium. Vivendo abhorreant et vim, et quot persecuti mel. <strong>Libris hendrerit</strong> ex sea. Duo legere evertitur an, pri hinc doctus definitiones an, vix id dicam putent. Ius ornatus instructior in.
                    </p>
                </div>
                <div className="col-md-7 col-md-offset-1 text-right hidden-sm hidden-xs">
                    <img src={deviceImg} alt="" className="img-responsive" />
                </div>
            </div>
        </div>
    );
}