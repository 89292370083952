import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import LoginForm from './LoginForm';
import { checkUserExist, sendOTP, verifyPassword } from './../../utils/LoginApiLibrary';
import { getOrders } from './../../utils/CustomerLibrary';
import { authenticateUser } from './../../actions/customerActions';
import { closeModal, openModal } from './../../actions/modalActions';
import { toggleLoader } from './../../actions/loaderActions';
import { setCustomer } from './../../actions/orderActions';
import { setPasswordField } from './../../actions/formActions';
import { setOrderHistory } from './../../actions/orderHistoryActions';
import { setGokash, setSelectedOrder, setCompanyInfo } from './../../actions/customerActions';
import { getGokash } from './../../utils/CustomerLibrary';

class Login extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    gokashBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getGokash(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokash(res.data) : 0 };
            })
    }

    login = (e) => {
        const { path } = this.props.match;
        const { loginType } = this.props.customer;
        const mobileNum = this.props.mobile.value;
        const password = this.props.password.value;
        const email = this.props.email.value;
        const name = this.props.name.value;
        const { cookies } = this.props;
        e.preventDefault();
        if (loginType === 'guestLogin') {
            this.props.closeModal();
            this.props.setCustomer({
                restaurantcustomer: '',
                customername: name,
                customerno: mobileNum,
                customeremail: email,
                createdby: ''
            });
            if (this.props.modal.data && this.props.modal.data.msg === 'checkout') {
                setTimeout(() => {
                    if (this.props.modal.data.toPay) this.props.history.push('/payment-options');
                    else this.props.history.push('/processing-order');
                }, 1200);
            }
        } else {
            checkUserExist(mobileNum).then(data => {
                if (data.data.message.toLowerCase() == "exists") {
                    if (loginType === 'otp' || loginType === 'resetpassword') {
                        sendOTP(mobileNum, email).then(res => {
                            this.verifyUser();
                        });
                    }
                    else if (loginType === 'password') {

                        verifyPassword(mobileNum, password).then(res => {
                            let expiryTime = res.data.ttl - 120;
                            if (res.data.user.phoneno === mobileNum) {
                                this.props.toggleLoader(true);
                                this.props.setPasswordField({ value: password, isValid: true, error: 'Login success' }),

                                    res.data.user.company ? cookies.set('companyInfo', res.data.user.company, { path: '/', maxAge: expiryTime }) : cookies.remove('companyInfo', { path: '/' }),
                                    cookies.set('id', res.data.customerId, { path: '/', maxAge: expiryTime }),
                                    cookies.set('name', _.startCase(res.data.user.name, { path: '/', maxAge: expiryTime })),
                                    cookies.set('email', res.data.user.email, { path: '/', maxAge: expiryTime }),
                                    cookies.set('phoneno', res.data.user.phoneno, { path: '/', maxAge: expiryTime }),
                                    cookies.set('token', res.data.id, { path: '/', maxAge: expiryTime }),
                                    cookies.set('userid', res.data.userId, { path: '/', maxAge: expiryTime })

                                //store the data in redux store
                                this.props.authenticateUser(true),
                                    this.setAuthUser(res.data.user.id, res.data.id),
                                    this.props.setCustomer({
                                        restaurantcustomer: res.data.user.id,
                                        customername: res.data.user.name,
                                        customerno: res.data.user.phoneno,
                                        customeremail: res.data.user.email,
                                        createdby: res.data.user.id
                                    }),
                                    res.data.user.company ? this.props.setCompanyInfo(res.data.user.company) : '',
                                    //set gokash
                                    this.gokashBalance(),
                                    (this.props.modal.data && this.props.modal.data.msg === 'checkout') ?
                                        setTimeout(() => {
                                            this.props.closeModal();
                                            this.props.toggleLoader(false);
                                            if (this.props.modal.data.toPay) this.props.history.push('/payment-options');
                                            else this.props.history.push('/processing-order');
                                        }, 1200)
                                        :
                                        setTimeout(() => {
                                            this.props.closeModal(),
                                                path == "/payment-options" || path == `/restaurant/${this.props.order.parentId}` ? this.props.history.push(`/restaurant/${this.props.order.parentId}`) : '',
                                                this.props.toggleLoader(false)
                                        }, 2000)

                            }
                        }).catch(e => {
                            e.response.data.message === 'login failed' || e.response.status === 401 || e.response.status === 500 ?
                                this.props.setPasswordField({ value: password, isValid: false, error: 'Invalid Password' }) : ''
                        });
                    }
                } else this.showRegisterForm('Please register');

            }).catch(e => console.log(e));
        }
    };

    showRegisterForm = (msg = null) => {
        if (this.props.modal.data && this.props.modal.data.msg === 'checkout') {
            this.props.openModal('UserRegister', { buttonClick: false, msg, toPay: this.props.modal.data.toPay, check: 'toPayment' });
        } else {
            this.props.openModal('UserRegister', { buttonClick: false, msg });
        }
    }

    setAuthUser = (id, token) => {
        getOrders(id, token).then(result => this.props.setOrderHistory(result.data)),
            this.props.setSelectedOrder('')
    }

    verifyUser = () => {
        if (this.props.modal.data && this.props.modal.data.msg === 'checkout') {
            this.props.openModal('VerifyUser', { buttonClick: false, toPay: this.props.modal.data.toPay, check: 'toPayment' });
        } else {
            this.props.openModal('VerifyUser', { buttonClick: false });
        }
    }

    render() {
        return (
            <LoginForm
                action={this.login}
                showRegisterForm={this.showRegisterForm}
                verifyUser={this.verifyUser}
            />
        );
    }
}

const mapDispatchToProps = {
    closeModal,
    openModal,
    toggleLoader,
    authenticateUser,
    setGokash,
    setCustomer,
    setSelectedOrder,
    setOrderHistory,
    setPasswordField,
    setCompanyInfo
}

const mapStateToProps = (state) => {
    return {
        mobile: state.formfields.mobile,
        modal: state.modal,
        email: state.formfields.email,
        name: state.formfields.name,
        password: state.formfields.password,
        customer: state.customer,
        order: state.order
    }
}
export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(Login)));