import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import moment from 'moment';

import { setSelectedRestaurant } from './../../actions/restActions';
import { setRestFoodCourt, emptyCart } from './../../actions/orderActions';
import { openModal, closeModal } from './../../actions/modalActions';
import { setMenu } from './../../actions/menuActions';
import { setCategory } from './../../actions/categoryActions';
import { setCuisines } from './../../actions/cuisineActions';
import { toggleLoader } from './../../actions/loaderActions';

import { fetchRestaurant, fetchCuisines } from './../../utils/ApiLibrary';
import { fetchMenu } from './../../utils/ApiLibrary';


class GridListRestaurant extends React.Component {

    getCuisineName = (id) => {
        return this.props.cuisines ? (
            this.props.cuisines.find(cuisine => {
                return cuisine.id === id;
            })
        ) : false
    }


    goToMenuItems = (id) => {
        let openinghours = [];
        fetchCuisines('/gokhana/cuisine').then(result => {
            this.props.setCuisines(result),
                fetchRestaurant(id).then(result => {
                    if (result.data && result.data.openinghours && result.data.openinghours.length) {
                        result.data.openinghours.forEach((openingHr) => {
                            if (moment().format('dddd').toLowerCase() === openingHr.day.toLowerCase()) {
                                openinghours.push(`${moment(openingHr.from, ['HH:mm']).format('hh:mm A')} - ${moment(openingHr.to, ['HH:mm']).format('hh:mm A')}`);
                            }
                        });

                        let openStatus;
                        openinghours.length ? openStatus = this.checkIfOpen(openinghours, result.data.open) : openStatus = 'Closed';

                        let restaurantData = {
                            id: result.data.id,
                            name: result.data.name,
                            address: result.data.address,
                            imageUrl: result.data.imageUrl,
                            cuisines: result.data.cuisines,
                            isExist: true,
                            openinghours,
                            openStatus,
                            dinein: result.data.isdineinavailable,
                            takeaway: result.data.istakeawayavailable,
                            delivery: result.data.isdeliveryavailable
                        };

                        let setFoodOrderData = {
                            parentId: result.data.id,
                            restaurantname: result.data.name,
                            foodcourt: result.data.foodcourt ? result.data.foodcourt : '',
                            foodcourtname: result.data.foodcourtRecord ? result.data.foodcourtRecord.name : '',
                            imageUrl: result.data.imageUrl
                        };

                        (this.props.filters.foodcourtOnly && this.props.filters.foodcourtCB == "true") ? (
                            setFoodOrderData.foodcourt = this.props.filters.foodcourtId,
                            setFoodOrderData.foodcourtname = this.props.filters.foodcourtName
                        ) : ''

                        this.props.setRestFoodCourt(setFoodOrderData);
                        this.props.setSelectedRestaurant(restaurantData);

                        fetchMenu(id).then(result => {
                            this.props.setMenu(result);
                            if (result.length) {
                                const cat = {
                                    id: result[0].id,
                                    name: result[0].name
                                }
                                this.props.setCategory(cat);
                                this.props.history.push(`/restaurant/${id}`);
                            }
                        });

                    };
                })
        });
    }

    truncate = (string, length) => {
        return string.length > length ?
            string.substring(0, length - 3) + "..." :
            string;
    }

    checkIfOpen = (openinghours, open) => {
        if (!open) { open = false; }
        let openStatus = '';
        openinghours.some((openingHr) => {
            if (openStatus === '' || openStatus === 'Closed') {
                let hoursArray = openingHr.split('-');
                const currentTime = moment();
                const beforeTime = moment(hoursArray[0].trim(), 'hh:mm A');
                let afterTime = moment(hoursArray[1].trim(), 'hh:mm A');
                if (afterTime.isSameOrBefore(beforeTime)) {
                    afterTime = afterTime.add(1, 'd');
                }
                if (currentTime.isBefore(beforeTime) && open) { openStatus = 'Opened Early'; }
                else if (currentTime.isAfter(afterTime) && open) { openStatus = 'Still Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && open) { openStatus = 'Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && !open) { openStatus = 'Closed'; }
                else { openStatus = 'Closed'; }
            }
        });
        return openStatus;
    }


    handleOnClick = (e) => {
        const id = e.currentTarget.id;
        {
            this.props.order.items.length === 0 ? (
                this.goToMenuItems(id)
            ) : (
                    id !== this.props.order.parentId ?
                        this.props.openModal('cartNotEmpty', { buttonClick: true, currentSelection: id, prevSelection: this.props.order.parentId })
                        : this.goToMenuItems(id)
                )
        }
    }



    render() {
        const { openStatus } = this.props.outlet;
        const { filters } = this.props;
        const green = {
            color: '#4dbd74'
        }
        const red = {
            color: '#ff0000'
        }
        return (
            <div>
                {/*this.props.restaurant.id ? <Redirect to="/select-menu" /> : ''*/}
                <div className="col-md-12"
                    className="strip_list"
                    onClick={this.handleOnClick}
                    id={this.props.outlet.id} style={{ cursor: "pointer" }}>
                    <div className="col-md-12 col-sm-12" id={this.props.outlet.id} >
                        <div className="desc" id={this.props.outlet.id} >
                            <div className="thumb_strip" id={this.props.outlet.id} >
                                <NavLink to="#" id={this.props.outlet.id} onClick={e => e.preventDefault()}>
                                    <img src={this.props.outlet.imageUrl} alt="" id={this.props.outlet.id} />
                                </NavLink>
                            </div>
                            <div className="desc_text">
                                <h5 style={{ marginBottom: "1px" }} id={this.props.outlet.id} >{this.props.outlet.name}</h5>
                                <div className="type" style={{ marginBottom: "1px" }} id={this.props.outlet.id} >
                                    <ul id={this.props.outlet.id} >
                                        {
                                            this.props.outlet.cuisines
                                                .map((id) => {
                                                    return <li key={id} id={this.props.outlet.id} >{this.getCuisineName(id) ? this.getCuisineName(id).name : ''}</li>
                                                })
                                        }
                                    </ul>
                                </div>
                                {!filters.foodcourtOnly ? <div className="location" id={this.props.outlet.id} >
                                    {this.props.outlet.address}
                                </div> : ''}
                                <ul id={this.props.outlet.id} >
                                    {this.props.outlet.distance ? <li>{this.props.outlet.distance}</li> : ''}
                                    <li id={this.props.outlet.id} >{this.props.outlet.openinghours.length ? <span className="opening">Opening Hours {this.props.outlet.openinghours + '  '}</span> : ''}</li>
                                </ul>
                                <ul>
                                    <li style={openStatus === 'Closed' || openStatus === 'Closed' ? red : green}>{openStatus}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

const mapDispatchToProps = {
    setSelectedRestaurant,
    setRestFoodCourt,
    openModal,
    closeModal,
    emptyCart,
    setMenu,
    setCategory,
    setCuisines,
    toggleLoader
}

export default withRouter(connect(state => state, mapDispatchToProps)(GridListRestaurant));
