import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';

import Header from './sections/Header';
import ImageSubHeader from './sections/ImageSubHeader';
import Footer from './sections/Footer';
import CustomerAccountSidebar from './sections/CustomerAccountSidebar';
import SearchBarPopup from './sections/SearchBarPopup';

import { openModal } from './../actions/modalActions';
import { setOrderHistory } from './../actions/orderHistoryActions';
import { setSelectedOrder } from './../actions/customerActions';


import { loadJsLibraries } from './../utils/LoadLibrary';
import { getOrders } from './../utils/CustomerLibrary';

import myAccountImg from './../images/web-images/myaccount.jpg';
import UpdateCustomerInfo from './sections/UpdateCustomerInfo';
import OrderHistory from './sections/OrderHistory';
import Gokash from './sections/Gokash';
import GokashPlus from './sections/GokashPlus';


class CustomerAccount extends React.Component {
    orders = [];

    constructor() {
        super();
    }
    UNSAFE_componentWillMount() {
        const { action } = this.props;
        const { cookies } = this.props.cookies;

        loadJsLibraries();
        action && action === 'login' ? this.props.openModal('UserLogin', { buttonClick: false }) : '';

        cookies.id && cookies.token ?
            this.setAuthUser(cookies.id, cookies.token)
            : ''
    }

    setAuthUser = (id, token) => {
        getOrders(id, token).then(result => {
            this.props.setOrderHistory(result.data);
        }),
            this.props.setSelectedOrder('');
    }

    render() {
        const { gokash, gokashplus, orders, profile } = this.props.customer.selecttab;
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header />
                <ImageSubHeader title="My Account" subTitle="Welcome to GoKhana - Order your food" bgImg={myAccountImg} />

                <div className="container margin_60">
                    <div className="row">
                        <CustomerAccountSidebar />
                        {
                            orders && (
                                <div className="col-md-9">
                                    <OrderHistory />
                                </div>
                            )
                        }{
                            gokash && (
                                <div className="col-md-9">
                                    <Gokash />
                                </div>
                            )
                        }
                        {
                            gokashplus && (
                                <div className="col-md-9">
                                    <GokashPlus />
                                </div>
                            )
                        }{
                            profile && (
                                <UpdateCustomerInfo />
                            )
                        }
                    </div>
                </div>

                <Footer />
            </div>
        );
    }

}

const mapDispatchToProps = {
    openModal,
    setOrderHistory,
    setSelectedOrder
};

export default withCookies(connect(state => state, mapDispatchToProps)(CustomerAccount));