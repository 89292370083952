import React from 'react';
import { NavLink } from 'react-router-dom';

class ImageSubHeader extends React.Component {

    render() {

        return (
            <section
                className="parallax-window"
                style={{ backgroundImage: 'url(' + this.props.bgImg + ')', backgroundAttachment: 'fixed', backgroundSize: 'auto', backgroundRepeat: 'no-repeat' }}
                id="short"
            >
                <div id="subheader">
                    <div id="sub_content_heading">
                        <h1>{this.props.title}</h1>
                        <p>{this.props.subTitle}</p>
                        {this.props.showSearch &&
                            (<form method="post" action="list_page.html">
                                <div id="custom-search-input">
                                    <div className="input-group ">
                                        <input type="text" className=" search-query" placeholder="Your Address or postal code" />
                                        <span className="input-group-btn">
                                            <input type="submit" className="btn_search" value="submit" />
                                        </span>
                                    </div>
                                </div>
                            </form>)
                        }

                        {this.props.showOrder &&
                            (
                                <div className="bs-wizard">
                                    <div className={(this.props.orderId >= 1) ? "col-xs-4 bs-wizard-step complete" : "col-xs-4 bs-wizard-step disabled"}>
                                        <div className="text-center bs-wizard-stepnum"><strong>1.</strong> Your details</div>
                                        <div className="progress"><div className="progress-bar"></div></div>
                                        <NavLink to="#" onClick={e => e.preventDefault()} className="bs-wizard-dot"></NavLink>
                                    </div>

                                    <div className={(this.props.orderId >= 2) ? "col-xs-4 bs-wizard-step complete" : "col-xs-4 bs-wizard-step disabled"}>
                                        <div className="text-center bs-wizard-stepnum"><strong>2.</strong> Payment</div>
                                        <div className="progress"><div className="progress-bar"></div></div>
                                        <NavLink to="#" onClick={e => e.preventDefault()} className="bs-wizard-dot"></NavLink>
                                    </div>

                                    <div className={(this.props.orderId >= 3) ? "col-xs-4 bs-wizard-step complete" : "col-xs-4 bs-wizard-step disabled"}>
                                        <div className="text-center bs-wizard-stepnum"><strong>3.</strong> Finish!</div>
                                        <div className="progress"><div className="progress-bar"></div></div>
                                        <NavLink to="#" onClick={e => e.preventDefault()} className="bs-wizard-dot"></NavLink>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default ImageSubHeader;