import React from 'react';
import Slider from 'react-rangeslider';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setCentralizedBilling, setSelectedCuisine, removeSelectedCuisine, setUpdate, toggleVegOnly, toggleFoodCourt, updateDistance, setFoodCourtId, setFoodCourtName } from './../../actions/filterActions';
import { toggleLoader } from './../../actions/loaderActions';
import { setRestaurants } from './../../actions/restaurantActions';
import { openModal, closeModal } from './../../actions/modalActions';

import { loadJsLibraries } from './../../utils/LoadLibrary';
import { fetchRestaurants } from './../../utils/ApiLibrary';

class FilterOptions extends React.Component {

	lat = this.props.location.latitude;
	lng = this.props.location.longitude;
	maxDistance = this.props.filters.distance;
	outletType = this.props.filters.foodcourtOnly;
	category = 'nearby';
	limit = 50;
	cuisineIds = [];
	vegOnly;
	restaurantAPI = '/gokhana/restaurant/categorized';

	handleChange = value => {
		this.props.updateDistance(value);
		this.props.toggleLoader(true);

		this.outletType = this.props.filters.foodcourtOnly;
		this.vegOnly = this.props.filters.vegOnly;

		fetchRestaurants(
			this.restaurantAPI,
			this.lat,
			this.lng,
			value,
			this.category,
			this.limit,
			this.cuisineIds,
			this.outletType,
			this.vegOnly
		).then(result => {
			result.length === 0 ? result = [{ isEmpty: true }] : '';
			this.props.setRestaurants(result)
		});

		setTimeout(() => {
			this.props.toggleLoader(false);
		}, 3000);
	};

	cuisineStatus = (id) => {
		const cuisineFound = this.props.filters.cuisines.find((cuisineId) => {
			return cuisineId === id;
		});
		return cuisineFound ? true : false;
	}

	handleCheckBoxChange = (e) => {
		this.props.toggleLoader(true);
		let isChecked = e.target.checked;

		if (isChecked) {
			this.cuisineIds = [...this.props.filters.cuisines, e.target.value];
			this.props.setSelectedCuisine(e.target.value);
		} else {
			this.cuisineIds = this.props.filters.cuisines.filter((cuisineId) => {
				return cuisineId !== e.target.value;
			});
			this.props.removeSelectedCuisine(e.target.value);
		}

		this.maxDistance = this.props.filters.distance;
		this.outletType = this.props.filters.foodcourtOnly;
		this.vegOnly = this.props.filters.vegOnly;

		fetchRestaurants(
			this.restaurantAPI,
			this.lat,
			this.lng,
			this.maxDistance,
			this.category,
			this.limit,
			this.cuisineIds,
			this.outletType,
			this.vegOnly
		).then(result => {
			result.length === 0 ? result = [{ isEmpty: true }] : '';
			this.props.setRestaurants(result);
		});

		setTimeout(() => {
			this.props.toggleLoader(false);
		}, 2000);
	}

	handleFoodCourtChange = (e) => {
		this.props.toggleLoader(true);

		let isChecked = e.target.checked;
		this.props.toggleFoodCourt(isChecked);
		this.props.setFoodCourtId('');
		this.props.setFoodCourtName('');
		this.props.setCentralizedBilling('false');

		this.maxDistance = this.props.filters.distance;
		this.vegOnly = this.props.filters.vegOnly;

		fetchRestaurants(
			this.restaurantAPI,
			this.lat,
			this.lng,
			this.maxDistance,
			this.category,
			this.limit,
			this.cuisineIds,
			isChecked,
			this.vegOnly
		).then(result => {
			result.length === 0 ? result = [{ isEmpty: true }] : '';
			this.props.setRestaurants(result);
		});

		setTimeout(() => {
			this.props.history.push('/restaurants');
			this.props.toggleLoader(false);
		}, 2000);
	}


	handleVegOnlyChange = (e) => {
		this.props.toggleLoader(true);
		let isChecked = e.target.checked;
		this.props.toggleVegOnly(isChecked);

		this.maxDistance = this.props.filters.distance;
		this.outletType = this.props.filters.foodcourtOnly;

		fetchRestaurants(
			this.restaurantAPI,
			this.lat,
			this.lng,
			this.maxDistance,
			this.category,
			this.limit,
			this.cuisineIds,
			this.outletType,
			isChecked
		).then(result => {
			result.length === 0 ? result = [{ isEmpty: true }] : '';
			this.props.setRestaurants(result);
		});

		setTimeout(() => {
			this.props.history.push('/restaurants');
			this.props.toggleLoader(false);
		}, 2000);
	}

	render() {
		return (
			<div id="mobile-filters">
				<div id="child-mobile-filters">
					<div className="filter_type">
						<h6>Distance (0-50 km)</h6>
						<div className='slider-horizontal'>
							<Slider
								min={0}
								max={50}
								value={this.props.filters.distance}
								onChange={this.handleChange}
							/>
						</div>

						{/*<h6>Food Type</h6>
						<ul>
							<li>Veg Only</li>
							<li>
								<label className="switch">
									<input 
										type="checkbox" 
										onChange={(e) => this.handleVegOnlyChange(e)}
										{...this.props.filters.vegOnly ? {checked: true} : {}}
									/>
									<span className="slider round"></span>
								</label>
							</li>
						</ul>*/}

						<h6>Outlet Type</h6>
						<ul>
							<li>Food Courts only</li>
							<li>
								<label className="switch">
									<input
										type="checkbox"
										className="foodCourt"
										onChange={(e) => this.handleFoodCourtChange(e)}
										{...this.props.filters.foodcourtOnly ? { checked: true } : {}}
									/>
									<span className="slider round"></span>

								</label>
							</li>
						</ul>
					</div>

					<div className="filter_type">
						<h6>Cuisines</h6>
						<ul className="nomargin">
							{
								this.props.cuisines.map((cuisine) => {
									return (<li key={cuisine.id}>
										<label className="checkbox-container">
											{cuisine.name}

											<input
												type="checkbox"
												value={cuisine.id}
												className="cuisineCheckbox"
												onChange={(e) => this.handleCheckBoxChange(e)}
												{...this.cuisineStatus(cuisine.id) ? { checked: true } : {}}
											/>
											<span className="checkmark"></span>
										</label>
									</li>)
								})
							}

						</ul>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = {
	setSelectedCuisine,
	removeSelectedCuisine,
	setRestaurants,
	setUpdate,
	toggleFoodCourt,
	toggleVegOnly,
	updateDistance,
	toggleLoader,
	setFoodCourtId,
	setFoodCourtName,
	setCentralizedBilling,
	openModal,
	closeModal
};

export default withRouter(connect(state => state, mapStateToProps)(FilterOptions));