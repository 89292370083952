import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect, withRouter, NavLink } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import Footer from './sections/Footer';
import QRCode from 'qrcode.react';

import logoImage from './../../src/images/gokhana-logo.png';
import { loadJsLibraries } from './../utils/LoadLibrary';
import { openModal, closeModal } from './../actions/modalActions';
import { selectTab, setGokash } from './../actions/customerActions';
import { resetCustomer } from './../actions/orderActions';
import { getGokash } from './../utils/CustomerLibrary';
import ModalPopup from './sections/ModalPopup';


class OrderConfirmation extends React.Component {

    constructor(props) {
        super(props);
        if (props.customer.isAuthenticated) {
            this.gokashBalance();
        }
        loadJsLibraries();
    }

    componentDidMount() {
        const { restaurantcustomer } = this.props.order;
        if (restaurantcustomer == "") {
            this.props.resetCustomer();
        }
    }

    gokashBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getGokash(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokash(res.data) : 0 };
            })
    }

    handleClose = () => {
        if (window.screen.width > 991) {
            this.props.closeModal();
        }
        else {
            this.props.closeModal();
            this.props.history.go(-1);
        }
    }

    render() {
        let showButton = true;
        if (this.props.restaurant.id == '5ece3e3280184677c45b5075') {
            showButton = false;
        }
        const { orderResponse } = this.props.order;
        const { city } = this.props.location;
        const mainCookies = this.props.cookies;
        const { cookies } = mainCookies;
        return (
            <div>
                {
                    !orderResponse ? <Redirect to='/' /> : ''
                }
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col--md-4 col-sm-4 col-xs-4">
                                {showButton ? <Link to="/" id="logo">
                                    <img src={logoImage} height="25" alt="" data-retina="true" style={{ marginTop: "8px" }} />
                                </Link> :
                                    <Link to="/restaurant/5ece3e3280184677c45b5075" id="logo">
                                        <img src={logoImage} height="25" alt="" data-retina="true" style={{ marginTop: "8px" }} />
                                    </Link>
                                }
                            </div>
                            <nav className="col--md-8 col-sm-8 col-xs-8">
                                {/* Mobile device */}

                                {showButton && <NavLink
                                    to="#"
                                    title={this.props.location.city}
                                    className="mobile-city"
                                    id="mobile-change-location"
                                    onClick={e => e.preventDefault()}
                                >
                                    <i className="icon-location-7"></i>
                                    {this.props.location.city && this.props.location.city.length > 10 ? `${this.props.location.city.substring(0, 10)}...` : this.props.location.city}
                                    <i className="icon-down-open-mini"></i>
                                </NavLink>}

                                <Link to="/my-account" className="mobile-myaccount">
                                    <i className="icon-user-7"></i>
                                </Link>

                                {/* Big devices */}
                                <div className="main-menu">
                                    <div id="header_menu">
                                        <img src={logoImage} height="30" alt="" data-retina="true" />
                                    </div>
                                    <NavLink
                                        to="#"
                                        className="open_close"
                                        id="close_in"
                                        onClick={e => e.preventDefault()}
                                    ><i className="icon_close"></i></NavLink>
                                    <ul>
                                        {
                                            !!mainCookies.get('token') ? (
                                                <li className="submenu">
                                                    <NavLink
                                                        to="#"
                                                        className="show-submenu"
                                                        onClick={e => e.preventDefault()}>
                                                        <i className="icon-user"></i>Account<i className="icon-down-open-mini"></i>
                                                    </NavLink>
                                                    <ul>
                                                        <li>
                                                            <Link to="/my-account" onClick={() => this.props.selectTab('profile')}>
                                                                {cookies.name !== 'null' ? <p>{cookies.name}</p> : ''}
                                                                {cookies.phoneno !== 'null' ? <p>{cookies.phoneno}</p> : ''}
                                                                {cookies.email !== 'null' ? <p>{cookies.email}</p> : ''}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/my-account" onClick={() => this.props.selectTab('gokash')}><i className="icon-money"></i>
                                                                GoKash: &nbsp;
                                                            ₹{this.props.customer.gokash}
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/my-account" onClick={() => this.props.selectTab('orders')}><i className="icon-shop"></i>
                                                                My Orders
                                                    </Link>
                                                        </li>
                                                        <li><Link to="/logout"><i className="icon-logout"></i>Logout</Link></li>
                                                    </ul>
                                                </li>
                                            ) : ''
                                        }

                                        {
                                            !mainCookies.get('token') ? (
                                                <li>
                                                    <NavLink
                                                        to="#"
                                                        onClick={(e) => { e.preventDefault(); this.props.openModal('UserLogin', { buttonClick: true }) }}
                                                    >
                                                        Login
                                            </NavLink>
                                                </li>
                                            ) : ''
                                        }

                                        {
                                            !mainCookies.get('token') ? (
                                                <li>
                                                    <NavLink
                                                        to="#"
                                                        onClick={(e) => { e.preventDefault(); this.props.openModal('UserRegister', { buttonClick: true }) }}
                                                    >Register
                                            </NavLink>
                                                </li>
                                            ) : ''
                                        }

                                        {
                                            city ? (
                                                <li>
                                                    <NavLink
                                                        to="#"
                                                        title={this.props.location.city}
                                                        className="show-submenu"
                                                        onClick={e => e.preventDefault()}
                                                    >
                                                        <i className="icon-location-7"></i>
                                                        {this.props.location.city.length > 20 ? `${this.props.location.city.substring(0, 20)}...` : this.props.location.city}
                                                        <i className="icon-down-open-mini"></i>
                                                    </NavLink>
                                                    <ul>
                                                        <li>
                                                            <NavLink
                                                                to="#"
                                                                onClick={e => e.preventDefault()}
                                                                id="change-location"
                                                            >
                                                                <input
                                                                    type="submit"
                                                                    className="btn_1"
                                                                    value="Change Location"
                                                                />
                                                            </NavLink>
                                                        </li>
                                                    </ul>

                                                </li>
                                            ) : ''
                                        }

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>

                <div className="container margin_80_35">
                    <div className="row">
                        <div className="col-md-offset-3 col-md-6">
                            <div className="box_style_2">
                                <h2 className="inner">Order Confirmed!</h2>
                                <div id="confirm">
                                    <QRCode value={orderResponse.orderid} />
                                    <h3 style={{ color: '#538c53' }}> {orderResponse.tokenno}</h3>
                                    <p>
                                        Thank you placing an order at GoKhana. We strive hard to deliver the best food and make dining simple. We appreciate your support. Please find the confirmation of your order details below.
                                    </p>
                                </div>
                                <div>
                                    <table className="table table-striped nomargin">
                                        <tbody>
                                            <tr><td><strong><h4>Order# : {orderResponse.orderid}</h4></strong></td>
                                                <td><a className="btn btn-default btn-sm" role="button" data-toggle="collapse" href="#collapseContent" aria-expanded="false" aria-controls="collapseContent" style={{ margin: '4.4px', float: 'right' }}><strong className="pull-right">Order Details</strong></a></td></tr>
                                        </tbody>
                                        <tbody className="collapse" id="collapseContent">
                                            {
                                                orderResponse && (
                                                    orderResponse.items.length > 0 && (
                                                        orderResponse.items.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <strong>{item.count}x</strong>&nbsp;
                                                            {item.menuitemname.length > 70 ? `${item.menuitemname.substring(0, 70)}...` : item.menuitemname}
                                                                        {
                                                                            item.features.length > 0 ? (
                                                                                item.features.map((option, index) => {
                                                                                    return <div key={index} title={option.name}><i className="icon-check-1"></i> {option.name.length > 18 ? `${option.name.substring(0, 18)}...` : option.name}</div>
                                                                                })
                                                                            )
                                                                                : <div></div>
                                                                        }
                                                                        {
                                                                            item.customeitems.length > 0 ? (
                                                                                item.customeitems.map((option, index) => {
                                                                                    return (
                                                                                        <div key={index} title={option.categoryname}>
                                                                                            <i className="icon-check-1"></i>
                                                                                            {option.categoryname.length > 50 ? `${option.categoryname.substring(0, 50)}...` : option.categoryname}
                                                                                            {
                                                                                                option.options.map((opt, index) => {
                                                                                                    return <div key={index} className="sub-options" title={opt.name}><i className="icon-minus"></i> {opt.name}</div>
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            )
                                                                                : <div></div>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        <strong className="pull-right"> &#8377; {item.totalprice}</strong>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                )
                                            }
                                            {
                                                orderResponse && (
                                                    orderResponse.taxamount > 0 && (
                                                        <tr>
                                                            <td>
                                                                TAXES
                                                </td>
                                                            <td>
                                                                <strong className="pull-right">&#8377; {orderResponse.taxamount}</strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                            {
                                                orderResponse && (
                                                    orderResponse.parcelcharge > 0 && (
                                                        <tr>
                                                            <td>
                                                                Parcel Charge
                                                </td>
                                                            <td>
                                                                <strong className="pull-right">&#8377; {orderResponse.parcelcharge}</strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                            {
                                                orderResponse && (
                                                    (orderResponse.discountfromcompany > 0) && (
                                                        <tr style={{ color: 'green' }}>
                                                            <td>
                                                                Discount From Company
                                                </td>
                                                            <td>
                                                                <strong className="pull-right">&#8377; {orderResponse.discountfromcompany.toFixed(2)}</strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                            {
                                                orderResponse && (
                                                    orderResponse.discountfromcredit > 0 && (
                                                        <tr style={{ color: 'green' }}>
                                                            <td>
                                                                Gokash Credit Applied!
                                                </td>
                                                            <td>
                                                                <strong className="pull-right">&#8377; {orderResponse.discountfromcredit.toFixed(2)}</strong>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                            {
                                                orderResponse && (
                                                    orderResponse.items.length > 0 && (
                                                        <tr>
                                                            <td className="total_confirm">
                                                                TOTAL
                                                </td>
                                                            <td className="total_confirm">
                                                                <span className="pull-right">&#8377; {orderResponse.totalprice.toFixed(2)}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {cookies.token ?
                                    <Link to="/my-account" onClick={() => this.props.selectTab('orders')}><button style={{ boxShadow: 'rgb(221, 221, 221) 6px 6px 6px' }} type="button" className="btn btn-success btn-lg"><strong>Your Orders</strong></button>
                                    </Link> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {((this.props.modal.modalType === "UserLogin"
                    || this.props.modal.modalType === "VerifyUser"
                    || this.props.modal.modalType === "ResetPassword"
                    || this.props.modal.modalType === "UserRegister") && this.props.modal.isModalOpen) ?
                    <ModalPopup
                        isModalOpen={this.props.modal.isModalOpen}
                        handleClose={this.handleClose}
                        modalType={this.props.modal.modalType}
                        data={this.props.modal.data}
                    /> : <div></div>
                }
                <Footer />
            </div>
        );
    }

}
const mapDispatchToProps = {
    openModal,
    closeModal,
    selectTab,
    setGokash,
    resetCustomer
};

export default withRouter(withCookies(connect(state => state, mapDispatchToProps)(OrderConfirmation)));