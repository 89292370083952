import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import uuid4 from 'uuid';

import { fetchMenu } from '../../utils/ApiLibrary';
import { getGokash, getCreditDiscount } from '../../utils/CustomerLibrary';

import { setMenu } from '../../actions/menuActions';
import { toggleFoodCourt } from './../../actions/filterActions';
import { setSelectedOrder, setGokashDiscount, setGokash } from './../../actions/customerActions';
import { openCloseCart } from './../../actions/cartActions';
// import { calculateGokashDiscount } from '../../utils/CalculateGokash';
import { reOrder, setChecksum } from './../../actions/orderActions';
import { openModal, closeModal } from './../../actions/modalActions';
import { setDisabledItems } from './../../actions/itemActions';
import { css } from 'glamor';

class Order extends React.Component {

	componentDidMount() {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 1000)
	}

	viewDetail = (e) => {
		e.preventDefault();
		const id = e.target.id;
		this.props.setSelectedOrder(id);
	}

	reorder = (e) => {
		e.preventDefault();
		this.gokashCreditDiscount();
		this.gokashBalance();
		const { singleOrder } = this.props;
		const id = e.target.id;
		const length = this.props.order.items.length;
		const checksum = uuid4();
		this.props.setChecksum(checksum);
		let order = this.props.order;
		order = { ...order, checksum: checksum }

		fetchMenu(singleOrder.parentId).then(result => {
			if (!result.length) {
				this.notify();
				return;
			}

			if (!length) {
				this.reorderLogic(checksum);
			} else {
				if (order.parentId === singleOrder.parentId) {
					this.reorderLogic(checksum);
				}
				else {
					this.props.openCloseCart(true);
					this.props.openModal('reOrder', { buttonClick: true, orderid: id, singleOrder });
				}
			}
		});

	}

	notify = () => toast.error('Oops! This restaurant is unavailable at the moment', {
		className: css({
			marginTop: '50px',
			fontSize: '16px'
		})
	});

	reorderLogic = async (checksum) => {
		await this.gokashCreditDiscount();
		await this.gokashBalance();

		const { singleOrder } = this.props;
		let menuItem;
		let taxGroup;
		let taxItems;
		let taxes;
		let netPrice;
		let taxAmount = 0;
		let reorderData;

		fetchMenu(singleOrder.parentId).then(result => {
			this.props.setMenu(result);
			let disabledItems = [];
			const store = this.props;
			reorderData = {
				'parentId': singleOrder.parentId,
				'restaurantname': singleOrder.restaurantname,
				'appId': '5825d15cf009c4bc37fd7b9f',
				'entityId': '5825d66df009c4bc37fd7bae',
				'customername': singleOrder.customername,
				'customerno': singleOrder.customerno,
				'items': [],
				'itemscount': 0,
				'totalprice': 0,
				'taxamount': 0,
				'taxitems': [],
				'grossamount': 0,
				'paidamount': 0,
				'parcelcharge': 0,
				'paid': false,
				'discountsplit': [],
				'paymenttransactions': [],
				'discountfromcredit': 0,
				'promotion': null,
				'creditdiscount': "",
				'restaurantcustomer': singleOrder.restaurantcustomer,
				'ordertype': 'takeaway',
				'orderstatus': 'requested',
				'createdplatform': 'gokhanacustomerweb',
				'checksum': checksum
			}
			if (singleOrder.foodcourt) {
				reorderData['foodcourt'] = singleOrder.foodcourt;
				reorderData['foodcourtname'] = singleOrder.foodcourtname;
				this.props.toggleFoodCourt(true);
			}

			for (let oi of singleOrder.items) {
				if (oi && oi.status != 'cancelled' && oi.status != 'paid-and-cancelled') {
					let item = _.omit(oi, ['id', 'lastupdatedby', 'status', 'delivered', 'totalprice', 'taxAmount', 'parcelchargetype', 'parcelchargevalue']);
					item.status = 'requested';
					item.id = uuidv4();
					menuItem = this.getMenuItem(item.restaurantmenuitem);
					if (menuItem) {
						item.itemprice = menuItem.price;
						item.totalprice = this.getTotalPrice(menuItem.price, item.features, item.customeitems) * item.count;

						if (menuItem.parcelchargevalue && menuItem.parcelchargevalue > 0) {
							item.parcelchargetype = menuItem.parcelchargetype;
							item.parcelchargevalue = menuItem.parcelchargevalue;
							if (menuItem.parcelchargetype === "flat" && menuItem.parcelchargevalue > 0) {
								item.parcelcharge = item.count * menuItem.parcelchargevalue;
							}
							else if (menuItem.parcelchargetype === "percentage" && menuItem.parcelchargevalue > 0) {
								item.parcelcharge = (menuItem.parcelchargevalue * 0.01 * item.totalprice);
							}

						}

						taxGroup = menuItem.taxgroup.items;
						taxes = this.getTaxAmountArray(item.totalprice, taxGroup);

						taxAmount = 0;
						taxes.forEach(tax => taxAmount += tax.amount);
						item.taxAmount = taxAmount;
						reorderData.items.push(item);
					}
					else {
						if (this.props.disabledItems.length) {
							const restItem = this.props.disabledItems.find(it => it.restaurantmenuitem === item.restaurantmenuitem);
							if (!restItem) {
								disabledItems.push(item);
							}
							this.props.setDisabledItems(disabledItems);
						}
						else {
							disabledItems.push(item);
							this.props.setDisabledItems(disabledItems);
						}
					}
				}

			}
			// let discountSplit = {};
			netPrice = this.billing(reorderData.items);
			// if (store.customer.gokashDiscount.length) {
			// discountSplit = {
			// id: store.customer.gokashDiscount.length ? store.customer.gokashDiscount[0].id : '',
			// name: store.customer.gokashDiscount.length ? store.customer.gokashDiscount[0].name : '',
			// promotedby: "gokhana",
			// promotedgroup: "gokhana",
			// amount: store.order.discountfromcredit ? store.order.discountfromcredit : '',
			// ontax: 0
			// }
			// discountSplit[store.customer.gokashDiscount[0].valuetype] = store.customer.gokashDiscount[0].value;
			// } else {
			// discountSplit = '';
			// }

			reorderData.itemscount = netPrice.itemscount;
			reorderData.totalprice = netPrice.totalprice;
			reorderData.taxamount = netPrice.taxamount;
			reorderData.grossamount = netPrice.grossamount;
			// reorderData.discountfromcredit = netPrice.discountapplied || 0;
			store.customer.gokashDiscount.length ? reorderData.creditdiscount = store.customer.gokashDiscount[0].id : "";
			// netPrice.discountapplied !== 0 ? reorderData.discountsplit.push(discountSplit) : [];
			netPrice.parcelcharges > 0 ? reorderData.parcelcharge = netPrice.parcelcharges : 0;
			taxItems = this.createTaxItems(reorderData.items);
			reorderData.taxitems = taxItems;

			this.props.reOrder(reorderData);

			this.props.openCloseCart(true);
			this.props.history.push(`/restaurant/${reorderData.parentId}`, { response: 'reorder' });

		});
	}

	gokashCreditDiscount = () => {
		const { singleOrder } = this.props;
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token)
			return getCreditDiscount(singleOrder.parentId, cookie.token).then(res => {
				this.props.setGokashDiscount(res.data);
			})
	}

	gokashBalance = () => {
		const cookie = this.props.cookies.cookies;
		if (!_.isEmpty(cookie) && cookie.token)
			return getGokash(cookie.id, cookie.token).then(res => {
				if (res) { res.data ? this.props.setGokash(res.data) : 0 };
			})

	}

	getMenuItem = (id) => {
		let menuItem = {};
		this.props.menu.map(category => {
			if (category.menuitems.length !== 0) {
				category.menuitems.forEach(item => item.id === id ? menuItem = { ...item } : '')
			}
			if (category.subcategories.length !== 0) {
				category.subcategories.forEach(subcat => {
					subcat.menuitems.forEach(item => item.id === id ? menuItem = { ...item } : '')
				})
			}
		});
		return menuItem;
	}

	getTaxAmountArray = (price, taxgroup) => {
		let taxAmount = [];
		let taxobj = {};
		taxgroup.length && (
			taxgroup.forEach(tax => {
				taxobj = {};
				taxobj.name = tax.name;
				if (tax.type === 'percentage') {
					taxobj.amount = Math.round(price * tax.value * 0.01);
				} else if (tax.type === 'flat') {
					taxobj.amount = Number(tax.value);
				}
				taxAmount = [...taxAmount, taxobj];
			})
		)

		return taxAmount;
	}

	billing = (items) => {
		// const store = this.props;
		let netPrice = {
			itemscount: 0,
			totalprice: 0,
			grossamount: 0,
			taxamount: 0,
			parcelcharges: 0,
		};

		netPrice.grossamount = this.getGrossAmount(items);
		netPrice.taxamount = this.getTax(items);
		// netPrice.discountapplied = calculateGokashDiscount(store.customer.gokashDiscount, netPrice.grossamount, netPrice.taxamount, store.customer.gokash);

		netPrice.parcelcharges = this.getParcelCharges(items);

		netPrice.totalprice = netPrice.grossamount + netPrice.taxamount + netPrice.parcelcharges;
		netPrice.itemscount = items.length;
		return netPrice;
	}

	getGrossAmount = (items) => {
		let amt = 0;
		if (items.length !== 0) {
			items.forEach(item => amt += item.totalprice)
		};
		return amt;
	}

	getTax = (items) => {
		let amt = 0;
		if (items.length !== 0) {
			items.forEach(item => amt += item.taxAmount)
		};
		return amt;
	}

	getParcelCharges = (items) => {
		let parcelcharges = 0;
		items.length !== 0 ? (
			items.forEach(item => {
				if (item.parcelchargetype === "flat" && item.parcelchargevalue > 0) {
					parcelcharges = parcelcharges + item.parcelchargevalue;
				}
				else if (item.parcelchargetype === "percentage" && item.parcelchargevalue > 0) {
					parcelcharges = parcelcharges + (item.parcelchargevalue * 0.01 * item.totalprice);
				}
			})
		) : '';
		return parcelcharges;
	}

	createTaxItems = (items) => {
		let newTax = [];
		let singleItemTax;
		items.forEach(item => {
			let taxRule = this.getMenuItem(item.restaurantmenuitem);

			singleItemTax = this.getTaxAmountArray(item.totalprice, taxRule.taxgroup.items);
			if (newTax.length === 0) {
				newTax = singleItemTax;
			} else {
				for (let i = 0; i < newTax.length; i++) {
					if (newTax[i].name === singleItemTax[i].name) newTax[i].amount += singleItemTax[i].amount;
				}
			}
		});
		return newTax;
	}

	getTotalPrice = (price, addonitems, customeitems) => {
		if (addonitems && addonitems.length !== 0) {
			addonitems.forEach(item => price += Number(item.price));
		}

		if (customeitems && customeitems.length !== 0) {
			customeitems.forEach(item => {
				if (item.options.length > item.freechoicescount) {
					price += ((item.options.length - item.freechoicescount) * item.price)
				};
			});
		}

		return price;
	}

	render() {
		const { singleOrder } = this.props;
		return (
			<div className="strip_list wow fadeIn" data-wow-delay="0.1s">
				<div className="row">
					<div className="col-md-9 col-sm-9">
						<div className="desc">
							<div className="thumb_strip">
								<NavLink to="#" onClick={e => e.preventDefault()}><img src={singleOrder.imageUrl} alt="" /></NavLink>
							</div>
							<div className="desc_text">
								<h3>{singleOrder.restaurantname}</h3>
								<div className="type">
									Order #{singleOrder.orderid}
								</div>
								<div className="location">
									Total Paid - &#8377; {singleOrder.paidamount} | <span className="opening"> Order placed at {moment(singleOrder.placedtime).format('ddd, MMM Do YYYY, h:mm:ss a')}</span>
								</div>
								<ul>
									{
										singleOrder.items.map(item => {
											return <li key={item.restaurantmenuitem}><i className="icon_check_alt2 ok"></i>&nbsp;&nbsp; {item.menuitemname} x{item.count}</li>
										})
									}
								</ul>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-3">
						<div className="go_to">
							<div>
								<NavLink className="btn_full" to="#" id={singleOrder.orderid} onClick={this.viewDetail}>View Details</NavLink>
								<NavLink className="btn_full_outline" to="#" id={singleOrder.orderid} onClick={this.reorder}><i className="icon-right"></i> Reorder </NavLink>
							</div>
						</div>
					</div>

				</div>
				{
					this.props.customer.selectedOrder === singleOrder.orderid ? (
						<div className="view-details">
							<table className="table table_summary">
								<tbody>
									{
										singleOrder.items.map((item, index) => {
											return (
												<tr key={index}>
													<td title={item.menuitemname}>
														<strong>{item.count}x</strong>&nbsp;
{item.menuitemname}
														{
															item.features.length !== 0 ? (
																item.features.map((option, index) => {
																	return <div key={index} title={option.name}><i className="icon-check-1"></i> {option.name}</div>
																})
															)
																: <div></div>
														}
														{
															item.customeitems.length !== 0 ? (
																item.customeitems.map((option, index) => {
																	return (
																		<div key={index} title={option.categoryname}>
																			<i className="icon-check-1"></i>
																			{option.categoryname}
																			{
																				option.options.map((opt, index) => {
																					return <div key={index} className="sub-options" title={opt.name}><i className="icon-minus"></i> {opt.name}</div>
																				})
																			}
																		</div>
																	)
																})
															)
																: <div></div>
														}
													</td>
													<td>
														<strong className="pull-right"> &#8377; {item.totalprice}</strong>
													</td>
												</tr>
											)
										})
									}

								</tbody>
							</table>
							<hr />
							<table className="table table_summary">
								<tbody>
									<tr>
										<td>
											Subtotal <span className="pull-right">&#8377; {singleOrder.grossamount}</span>
										</td>
									</tr>
									{
										singleOrder.items.length !== 0 && (
											singleOrder.taxitems.map((item, index) => {
												if (item.amount > 0) {
													return (
														<tr key={index}>
															<td>
																{item.name}
																<span className="pull-right">
																	&#8377; {item.amount}
																</span>
															</td>
														</tr>
													)
												}
											})
										)
									}
									{
										singleOrder.parcelcharge > 0 && (
											<tr>
												<td>
													Parcel Charge <span className="pull-right">&#8377; {singleOrder.parcelcharge}</span>
												</td>
											</tr>
										)
									}
									{
										singleOrder.discountfromcompany > 0 && (
											<tr style={{ color: 'green' }}>
												<td>
													Discount From Company <span className="pull-right">&#8377; {singleOrder.discountfromcompany.toFixed(2)}</span>
												</td>
											</tr>
										)
									}
									{
										singleOrder.discountfromcredit > 0 && (
											<tr style={{ color: 'green' }}>
												<td>
													Gokash Credit Applied <span className="pull-right">&#8377; {singleOrder.discountfromcredit.toFixed(2)}</span>
												</td>
											</tr>
										)
									}
									<tr>
										<td className="total">
											TOTAL <span className="pull-right">&#8377; {singleOrder.totalprice.toFixed(2)}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					) : ''
				}
			</div>
		);
	}
}

const mapDispatchToProps = {
	setSelectedOrder,
	reOrder,
	openModal,
	closeModal,
	setMenu,
	setChecksum,
	openCloseCart,
	toggleFoodCourt,
	setGokashDiscount,
	setGokash,
	setDisabledItems
};

export default withCookies(withRouter(connect(state => state, mapDispatchToProps)(Order)));

