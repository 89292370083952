import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter, NavLink } from 'react-router-dom';

import Header from './sections/Header';
import Footer from './sections/Footer';
import Filters from './sections/Filters';
import DisplayRestaurants from './sections/DisplayRestaurants';
import FoodcourtBanner from './sections/FoodcourtBanner';
import SearchBarPopup from './sections/SearchBarPopup';

import { setRestaurants } from './../actions/restaurantActions';
import { setFoodCourtId, toggleFoodCourt, setCentralizedBilling, setFoodCourtName } from './../actions/filterActions';
import { setCuisines } from './../actions/cuisineActions';
import { setFoodcourt } from './../actions/foodcourtActions';
import { setRestFoodCourt, emptyCart, setFoodcourtInOrder } from './../actions/orderActions';
import { setSelectedRestaurant } from './../actions/restActions';
import { setCategory } from './../actions/categoryActions';
import { setMenu } from './../actions/menuActions';
import { openModal, closeModal } from './../actions/modalActions';
import { setRestData } from './../actions/restAutoCompleteActions';
import { setDisabledItems } from './../actions/itemActions';

import { loadJsLibraries } from './../utils/LoadLibrary';
import { fetchRestaurant, fetchFoodcourt, fetchCuisines, fetchMenu } from './../utils/ApiLibrary';

import loaderImg from './../images/web-images/loader.gif';

class Foodcourt extends React.Component {

    lat = this.props.location.latitude;
    lng = this.props.location.longitude;
    // lat = 12.9699;
    // lng = 77.6499;
    maxDistance = 50;
    category;
    limit = 100;
    cuisineIds = this.props.filters.cuisines || [];
    outletTypes = this.props.filters.foodcourtOnly;
    vegOnly = this.props.filters.vegOnly;

    restaurantAPI = '/gokhana/restaurant/categorized';

    cuisines = [];

    componentDidMount() {
        loadJsLibraries();
    }

    UNSAFE_componentWillMount() {
        const id = this.props.match.params.id;
        fetchFoodcourt(id).then(res => {
            if (res.length !== 0) {
                fetchCuisines('/gokhana/cuisine').then(result => this.props.setCuisines(result));
                this.props.setFoodcourt(res);
                this.props.setFoodCourtName(res[0].name);
                this.props.setRestaurants(res);
                this.props.setRestData(this.filterRestData(res));
                this.props.toggleFoodCourt(true);
                this.props.setFoodCourtId(id);
                this.props.setFoodcourtInOrder({ id: res[0].id, name: res[0].name });
                this.props.setCentralizedBilling(res.centralizedbilling);
            } else {
                this.props.setFoodCourtName('invalid');
            }
        });

        if (!this.props.location.latitude && !this.props.location.longitude) {
            this.props.history.push('/');
        }
        this.outletTypes = this.props.filters.foodcourtOnly;
        //fetch nearby restaurants
        this.category = 'nearby';
    }

    filterRestData = (data) => {
        let newRestData = [];
        let newFcData = [];

        data.forEach(type => {
            if (type.entityName === 'general' && type.outlets.length !== 0) {
                type.outlets.forEach(outlet => {
                    newRestData = [...newRestData, { id: outlet.id, name: outlet.name }];
                });
            }

            if (!newRestData.length && type.entityName === 'foodcourt') {
                newFcData = [...newFcData, { id: type.id, name: type.name }];
            }
        });

        return newRestData.length ? newRestData : newFcData;
    }

    continueToCart = () => {
        const id = this.props.modal.data.prevSelection;
        this.goToMenuItems(id, 'new');
        this.props.closeModal();
    }

    newSelection = () => {
        this.props.setDisabledItems([]);
        const id = this.props.modal.data.currentSelection;
        this.props.emptyCart();
        this.goToMenuItems(id);
        this.props.closeModal();
    }

    goToMenuItems = (id, type) => {
        let openinghours = [];
        fetchCuisines('/gokhana/cuisine').then(result => {
            this.props.setCuisines(result),
                fetchRestaurant(id).then(result => {
                    if (result.data) {
                        result.data.openinghours.forEach((openingHr) => {
                            if (moment().format('dddd').toLowerCase() === openingHr.day.toLowerCase()) {
                                openinghours.push(`${moment(openingHr.from, ['HH:mm']).format('hh:mm A')} - ${moment(openingHr.to, ['HH:mm']).format('hh:mm A')}`);
                            }
                        });

                        let openStatus;
                        openinghours.length ? openStatus = this.checkIfOpen(openinghours, result.data.open) : openStatus = 'Closed';

                        let restaurantData = {
                            id: result.data.id,
                            name: result.data.name,
                            address: result.data.address,
                            imageUrl: result.data.imageUrl,
                            cuisines: result.data.cuisines,
                            isExist: true,
                            openinghours,
                            openStatus,
                            dinein: result.data.isdineinavailable,
                            takeaway: result.data.istakeawayavailable,
                            delivery: result.data.isdeliveryavailable
                        };

                        let setFoodOrderData = {
                            parentId: result.data.id,
                            restaurantname: result.data.name,
                            foodcourt: result.data.foodcourt ? result.data.foodcourt : '',
                            foodcourtname: result.data.foodcourtRecord ? result.data.foodcourtRecord.name : '',
                            imageUrl: result.data.imageUrl
                        };

                        (this.props.filters.foodcourtOnly && this.props.filters.foodcourtCB == "true") ? (
                            setFoodOrderData.foodcourt = this.props.filters.foodcourtId,
                            setFoodOrderData.foodcourtname = this.props.filters.foodcourtName
                        ) : ''

                        this.props.setRestFoodCourt(setFoodOrderData);
                        this.props.setSelectedRestaurant(restaurantData);

                        fetchMenu(id).then(result => {
                            this.props.setMenu(result);
                            const cat = {
                                id: result[0].id,
                                name: result[0].name
                            }
                            this.props.setCategory(cat);
                            if (window.screen.width < 991 && type == 'new') {
                                this.props.history.push('/order-summary');
                            } else {
                                this.props.history.push(`/restaurant/${id}`);
                                window.location.reload();
                            }
                        });
                    };
                })
        });
    }

    truncate = (string, length) => {
        return string.length > length ?
            string.substring(0, length - 3) + "..." :
            string;
    }

    checkIfOpen = (openinghours, open) => {
        if (!open) { open = false; }
        let openStatus = '';
        openinghours.some((openingHr) => {
            if (openStatus === '' || openStatus === 'Closed') {
                let hoursArray = openingHr.split('-');
                const currentTime = moment();
                const beforeTime = moment(hoursArray[0].trim(), 'hh:mm A');
                let afterTime = moment(hoursArray[1].trim(), 'hh:mm A');
                if (afterTime.isSameOrBefore(beforeTime)) {
                    afterTime = afterTime.add(1, 'd');
                }
                if (currentTime.isBefore(beforeTime) && open) { openStatus = 'Opened Early'; }
                else if (currentTime.isAfter(afterTime) && open) { openStatus = 'Still Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && open) { openStatus = 'Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && !open) { openStatus = 'Closed'; }
                else { openStatus = 'Closed'; }
            }
        });
        return openStatus;
    }

    handleOnClick = (e) => {
        const id = e.currentTarget.id;
        {
            this.props.order.items.length === 0 ? (
                this.goToMenuItems(id)
            ) : (
                id !== this.props.order.parentId ?
                    this.props.openModal('cartNotEmpty', { buttonClick: true, currentSelection: id, prevSelection: this.props.order.parentId })
                    : this.goToMenuItems(id)
            )
        }
    }

    getItemsCount = (items) => {
        let itemscount = 0;
        if (items.length !== 0) {
            items.forEach(item => {
                itemscount = itemscount + item.count;
            })
        }
        return itemscount;
    }

    render() {
        const itemscount = this.getItemsCount(this.props.order.items);
        if (!this.props.filters.foodcourtId && this.props.filters.foodcourtName !== 'invalid') {
            return (<div style={{ textAlign: 'center', marginTop: '200px' }}><img src={loaderImg} style={{ width: '50px' }} /><h2>Loading...</h2></div>);
        }
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header
                    toCart={this.continueToCart}
                    newSelection={this.newSelection}
                />
                <FoodcourtBanner />
                <div className="container margin_60_35">
                    <div className="row">
                        {
                            this.props.filters.foodcourtName === 'invalid' ? (
                                <h3 style={{ textAlign: 'center' }}>Oops! We are unable to connect you to the restaurant at the moment.</h3>
                            ) : (
                                <React.Fragment>
                                    {this.props.order.items.length ? <NavLink to="/order-summary">
                                        <div id="display-mobile-cart">
                                            <div style={{ fontSize: '16px', padding: '20px 40px' }}>
                                                <span>{itemscount} {itemscount > 1 ? 'Items' : 'Item'} | ₹{this.props.order.totalprice.toFixed(2)}</span>
                                                <span style={{ float: 'right' }}>VIEW CART</span>
                                            </div>
                                        </div>
                                    </NavLink> : ''}
                                    <Filters />
                                    <DisplayRestaurants />
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = {
    setRestaurants,
    setFoodCourtId,
    setFoodCourtName,
    setCentralizedBilling,
    toggleFoodCourt,
    setCuisines,
    setFoodcourt,
    setRestFoodCourt,
    setSelectedRestaurant,
    setMenu,
    setCategory,
    openModal,
    closeModal,
    emptyCart,
    setRestData,
    setFoodcourtInOrder,
    setDisabledItems
};

export default withRouter(connect(state => state, mapDispatchToProps)(Foodcourt));