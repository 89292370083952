import React from 'react';
import { connect } from 'react-redux';

import { setDcType, setDcBank } from './../../actions/paymentActions';

class DebitCard extends React.Component {

    setCardType = (e) => {
        const cardType = e.target.value;
        this.props.setDcType(cardType);
    }


    render() {
        return (
            <div className="row">
                <div className="col-md-6 col-sm-6">
                </div>
            </div>

        );
    }
}

export default connect(state => state.paymentpage.paymentoption, { setDcType, setDcBank })(DebitCard);