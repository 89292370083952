import React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter, Redirect, NavLink } from 'react-router-dom';
import moment from 'moment';

import Header from './sections/Header';
import Footer from './sections/Footer';
import Filters from './sections/Filters';
import DisplayRestaurants from './sections/DisplayRestaurants';

import { toggleLoader } from './../actions/loaderActions';
import { setRestaurants } from './../actions/restaurantActions';
import { setTrending } from './../actions/trendingActions';
import { setRestData } from './../actions/restAutoCompleteActions';

import { loadJsLibraries } from './../utils/LoadLibrary';

import DisplayFoodCourts from './sections/DisplayFoodCourts';
import RestAutocomplete from './sections/RestAutocomplete';
import SearchBarPopup from './sections/SearchBarPopup';
import { openModal, closeModal } from './../actions/modalActions';
import { setRestFoodCourt, emptyCart } from './../actions/orderActions';
import { setSelectedRestaurant } from './../actions/restActions';
import { setCategory } from './../actions/categoryActions';
import { setMenu } from './../actions/menuActions';
import { fetchRestaurant, fetchRestaurants, fetchCuisines, fetchMenu } from './../utils/ApiLibrary';
import { setCuisines } from './../actions/cuisineActions';
import { setGokash, setGokashPlus } from './../actions/customerActions';
import { getGokash, getGokashPlus } from './../utils/CustomerLibrary';
import { setDisabledItems } from './../actions/itemActions';

class RestaurantList extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.customer.isAuthenticated) {
            this.gokashBalance();
            this.walletBalance();
        }
    }

    lat = this.props.location ? this.props.location.latitude : '';
    lng = this.props.location ? this.props.location.longitude : '';
    maxDistance = 50;
    category;
    limit = 100;
    cuisineIds = this.props.filters.cuisines || [];
    outletTypes = this.props.filters.foodcourtOnly;
    vegOnly = this.props.filters.vegOnly;

    restaurantAPI = '/gokhana/restaurant/categorized';

    cuisines = [];

    gokashBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getGokash(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokash(res.data) : 0 };
            })
    }

    walletBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getGokashPlus(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokashPlus(res.data) : 0 };
            })
    }

    UNSAFE_componentWillMount() {
        this.props.toggleLoader(true);
        loadJsLibraries();

        if (!this.props.location.latitude && !this.props.location.longitude) {
            this.props.history.push('/');
        }
        this.outletTypes = this.props.filters.foodcourtOnly;
        //fetch nearby restaurants
        this.category = 'nearby';

        fetchRestaurants(
            this.restaurantAPI,
            this.lat,
            this.lng,
            this.maxDistance,
            this.category,
            this.limit,
            this.cuisineIds,
            this.outletTypes,
            this.vegOnly
        ).then(result => {
            this.props.setRestData(this.filterRestData(result));

            result.length === 0 ? result = [{ isEmpty: true }] : '';
            this.props.setRestaurants(result);

        });

    }

    filterRestData = (data) => {
        let newRestData = [];
        let newFcData = [];

        data.forEach(type => {
            if (type.entityName === 'general' && type.outlets.length !== 0) {
                type.outlets.forEach(outlet => {
                    newRestData = [...newRestData, { id: outlet.id, name: outlet.name }];
                });
            }

            if (!newRestData.length && type.entityName === 'foodcourt') {
                newFcData = [...newFcData, { id: type.id, name: type.name }];
            }
        });

        return newRestData.length ? newRestData : newFcData;
    }

    componentDidMount() {

        setTimeout(() => {
            this.props.toggleLoader(false);
        }, 2000);

    }

    continueToCart = () => {
        const id = this.props.modal.data.prevSelection;
        this.goToMenuItems(id, 'new');
        this.props.closeModal();
    }

    newSelection = () => {
        this.props.setDisabledItems([]);
        const id = this.props.modal.data.currentSelection;
        this.props.emptyCart();
        this.goToMenuItems(id);
        this.props.closeModal();
    }
    getCuisineName = (id) => {
        return this.props.cuisines ? (
            this.props.cuisines.find(cuisine => {
                return cuisine.id === id;
            })
        ) : false
    }

    goToMenuItems = (id, type) => {
        let openinghours = [];
        fetchCuisines('/gokhana/cuisine').then(result => {
            this.props.setCuisines(result),
                fetchRestaurant(id).then(result => {
                    if (result.data && result.data.openinghours && result.data.openinghours.length) {
                        result.data.openinghours.forEach((openingHr) => {
                            if (moment().format('dddd').toLowerCase() === openingHr.day.toLowerCase()) {
                                openinghours.push(`${moment(openingHr.from, ['HH:mm']).format('hh:mm A')} - ${moment(openingHr.to, ['HH:mm']).format('hh:mm A')}`);
                            }
                        });

                        let openStatus;
                        openinghours.length ? openStatus = this.checkIfOpen(openinghours, result.data.open) : openStatus = 'Closed';

                        let restaurantData = {
                            id: result.data.id,
                            name: result.data.name,
                            address: result.data.address,
                            imageUrl: result.data.imageUrl,
                            cuisines: result.data.cuisines,
                            isExist: true,
                            openinghours,
                            openStatus,
                            dinein: result.data.isdineinavailable,
                            takeaway: result.data.istakeawayavailable,
                            delivery: result.data.isdeliveryavailable
                        };

                        let setFoodOrderData = {
                            parentId: result.data.id,
                            restaurantname: result.data.name,
                            foodcourt: result.data.foodcourt ? result.data.foodcourt : '',
                            foodcourtname: result.data.foodcourtRecord ? result.data.foodcourtRecord.name : '',
                            imageUrl: result.data.imageUrl
                        };

                        (this.props.filters.foodcourtOnly && this.props.filters.foodcourtCB == "true") ? (
                            setFoodOrderData.foodcourt = this.props.filters.foodcourtId,
                            setFoodOrderData.foodcourtname = this.props.filters.foodcourtName
                        ) : ''

                        this.props.setRestFoodCourt(setFoodOrderData);
                        this.props.setSelectedRestaurant(restaurantData);

                        fetchMenu(id).then(result => {
                            this.props.setMenu(result);
                            const cat = {
                                id: result[0].id,
                                name: result[0].name
                            }
                            this.props.setCategory(cat);
                            if (window.screen.width < 991 && type == 'new') {
                                this.props.history.push('/order-summary');
                            } else {
                                this.props.history.push(`/restaurant/${id}`);
                                window.location.reload();
                            }
                        });
                    };
                })
        });
    }

    truncate = (string, length) => {
        return string.length > length ?
            string.substring(0, length - 3) + "..." :
            string;
    }

    checkIfOpen = (openinghours, open) => {
        if (!open) { open = false; }
        let openStatus = '';
        openinghours.some((openingHr) => {
            if (openStatus === '' || openStatus === 'Closed') {
                let hoursArray = openingHr.split('-');
                const currentTime = moment();
                const beforeTime = moment(hoursArray[0].trim(), 'hh:mm A');
                let afterTime = moment(hoursArray[1].trim(), 'hh:mm A');
                if (afterTime.isSameOrBefore(beforeTime)) {
                    afterTime = afterTime.add(1, 'd');
                }
                if (currentTime.isBefore(beforeTime) && open) { openStatus = 'Opened Early'; }
                else if (currentTime.isAfter(afterTime) && open) { openStatus = 'Still Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && open) { openStatus = 'Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && !open) { openStatus = 'Closed'; }
                else { openStatus = 'Closed'; }
            }
        });
        return openStatus;
    }
    handleOnClick = (e) => {
        const id = e.currentTarget.id;
        {
            this.props.order.items.length === 0 ? (
                this.goToMenuItems(id)
            ) : (
                    id !== this.props.order.parentId ?
                        this.props.openModal('cartNotEmpty', { buttonClick: true, currentSelection: id, prevSelection: this.props.order.parentId })
                        : this.goToMenuItems(id)
                )
        }
    }

    getItemsCount = (items) => {
        let itemscount = 0;
        if (items.length !== 0) {
            items.forEach(item => {
                itemscount = itemscount + item.count;
            })
        }
        return itemscount;
    }

    render() {
        const { restautocomplete, filters } = this.props;
        const foodcourtURL = `/foodcourt/${filters.foodcourtId}`;
        const itemscount = this.getItemsCount(this.props.order.items);
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header
                    toCart={this.continueToCart}
                    newSelection={this.newSelection}
                />
                <RestAutocomplete data={restautocomplete} isFoodCourt={filters.foodcourtOnly} />
                <div className="container margin_60_35">
                    <div className="row">
                        {this.props.order.items.length ? <NavLink to="/order-summary">
                            <div id="display-mobile-cart">
                                <div style={{ fontSize: '16px', padding: '20px 40px' }}>
                                    <span>{itemscount} {itemscount > 1 ? 'Items' : 'Item'} | ₹{this.props.order.totalprice.toFixed(2)}</span>
                                    <span style={{ float: 'right' }}>VIEW CART</span>
                                </div>
                            </div>
                        </NavLink> : ''}
                        <Filters />
                        {
                            filters.foodcourtOnly ?
                                (filters.foodcourtId ? <Redirect to={foodcourtURL} /> : <DisplayFoodCourts />)
                                : <DisplayRestaurants />
                        }

                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = {
    toggleLoader,
    setRestaurants,
    setTrending,
    setRestData,
    openModal,
    closeModal,
    setCuisines,
    setRestFoodCourt,
    setSelectedRestaurant,
    setMenu,
    setCategory,
    emptyCart,
    setGokash,
    setGokashPlus,
    setDisabledItems
};

export default withCookies(withRouter(connect(state => state, mapDispatchToProps)(RestaurantList)));