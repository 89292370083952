import React from 'react';
import Header from './sections/Header';
import Footer from './sections/Footer';
import ImageSubHeader from './sections/ImageSubHeader';

import notFoundImg from './../images/web-images/404.jpg';

class PageNotfound extends React.Component {
    render(){
        return (
            <div>
                <Header />
                <ImageSubHeader title="Error 404" subTitle="Page Not Found" bgImg={notFoundImg} />
                <Footer />
            </div>
        );
    }
}

export default PageNotfound;