import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

import { setLocation } from './../../actions/locationActions';
import { toggleLoader } from './../../actions/loaderActions';

import SearchLocationBar from './SearchLocationBar';
import phoneimg from './../../images/iphon.png';

class ImageHeader extends React.Component {

    changeLocation = (e) => {
        e.preventDefault();
        const loc = e.currentTarget;
        let location = {};
        location.latitude = loc.attributes.getNamedItem('data-lat').value;
        location.longitude = loc.attributes.getNamedItem('data-lng').value;
        location.city = loc.attributes.getNamedItem('data-city').value;
        location.state = '';

        this.props.setLocation(location);
        this.props.toggleLoader(true);
        setTimeout(() => {
            this.props.history.push('/restaurants');
        }, 1000);
    }

    render() {
        return (
            <div className="container margin_60_35">
                <div className="row">
                    <div className="col-md-12">
                        <div id="subheader" style={{ "backgroundColor": "#ffffff", "color": "#000000" }} >
                            <div id="sub_content" style={{ "backgroundColor": "#ffffff", "color": "#000000" }} >
                                <div className="col-md-8 col-sm-12" style={{ "textAlign": "left" }}>
                                    <h1 style={{ marginTop: "100px", color: "#000" }} >Discover & Enjoy Food</h1>
                                    <SearchLocationBar />
                                    <div style={{ "marginTop": "20px", "fontSize": "11px" }}>Locations</div>
                                    <NavLink
                                        to="#"
                                        style={{ "color": "#000000" }}
                                        onClick={this.changeLocation}
                                        data-lat="12.9715987"
                                        data-lng="77.59456269999998"
                                        data-city="Bangalore"
                                    >Bangalore</NavLink> &nbsp;
                                    <NavLink
                                        to="#"
                                        style={{ "color": "#000000" }}
                                        onClick={this.changeLocation}
                                        data-lat="12.9260308"
                                        data-lng="77.6762463"
                                        data-city="Bellandur"
                                    >Bellandur</NavLink> &nbsp;
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <img src={phoneimg} height="500" alt="" data-retina="true" style={{ marginBottom: "50px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = {
    setLocation,
    toggleLoader
}

export default withRouter(connect(undefined, mapStateToProps)(ImageHeader));