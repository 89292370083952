import React from 'react';
import gpayIcon from './../../images/web-images/gpay.png';
import upiIcon from './../../images/web-images/upi.png';
import { connect } from 'react-redux';
import { setUpi } from '../../actions/paymentActions';

class Upi extends React.Component {

    getDeviceOS = () => {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    onVpaAddressChange = (e) => {
        if (e.target.value) this.props.setUpi({ vpaAddress: e.target.value, type: 'vpa' });
    }

    render() {
        const deviceos = this.getDeviceOS();
        const { upi } = this.props.paymentoptions;
        return (
            <div>
                <div className="row" style={{ marginBottom: '20px' }}>
                    <div>
                        {!(deviceos == "unknown") && <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ textAlign: 'center' }}>
                            <input
                                type="radio"
                                name="gpay"
                                {...upi.gpay.isActive ? { checked: true } : {}}
                                onChange={() => this.props.setUpi({ vpaAddress: '', type: 'gpay' })}
                                style={{ verticalAlign: 'middle', height: '15px', width: '15px' }}
                            />
                            <img src={gpayIcon} style={{ marginLeft: '10px', height: '30px' }} />
                        </div>}
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ textAlign: 'center' }}>
                            <input
                                type="text"
                                name="vpa"
                                placeholder="Enter Your VPA ID"
                                onChange={this.onVpaAddressChange}
                            />
                            <img src={upiIcon} style={{ marginLeft: '10px', height: '30px' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        paymentoptions: state.paymentpage.paymentoption
    }
}

export default connect(mapStateToProps, { setUpi })(Upi);