import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadJsLibraries } from './../utils/LoadLibrary';
import { openModal } from './../actions/modalActions';

class OrderCreationFail extends React.Component {

    UNSAFE_componentWillMount() {
        const { action } = this.props;

        loadJsLibraries();
        action && action === 'login' ? this.props.openModal('UserLogin', { buttonClick: false }) : '';
    }

    render() {
        const txnId = this.props.order.paymenttransactions[0].transactionid;
        return (
            <div>
                <div className="container margin_60_35">
                    <div className="row">
                        <div className="col-md-offset-3 col-md-6">
                            <div className="box_style_2">
                                <h2 className="inner">Oops! We were unable to place your order.</h2>
                                <h5 style={{ textAlign: 'center' }}>There is an issue in creating order for the successful payment, refund will be processed within 24 hours. Sorry for the inconvenience</h5>
                                <div id="confirm">
                                    <h5><b>Transaction #:</b> {txnId}</h5>
                                    <i className="icon-frown" style={{ color: '#f14300' }}></i>
                                    <div style={{ textAlign: 'center' }}>
                                        {/* <h4>We sincerely regret the incovenience caused.</h4> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
const mapDispatchToProps = {
    openModal
};

export default withRouter(connect(state => state, mapDispatchToProps)(OrderCreationFail));