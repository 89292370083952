export const setCategory = (data) => ({
    type: 'SET_CATEGORY',
    data
});

export const setMenuItem = (data) => ({
    type: 'SET_MENUITEM',
    data
});

export const toggleMenuCustomItem = (data) => ({
    type: 'TOGGLE_MENU_CUSTOMITEM',
    data
});

export const toggleMenuAddonItem = (data) => ({
    type: 'TOGGLE_MENU_ADDONITEM',
    data
});
