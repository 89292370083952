import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import uuidv1 from 'uuid/v1';
import moment from 'moment';

import { createFoodOrder, sendNotification } from './../utils/PaymentGateway';
import { emptyCart, setOrderData, setOrderId, removeFoodcourtdata, setPerkInfoInOrder } from './../actions/orderActions';
import { latestGokash, setGokashDiscount, setPerkInfo, setGokashPlus } from './../actions/customerActions';
import { setDisabledItems } from './../actions/itemActions';
import { getCreditDiscount, getGokash, getGokashPlus, getEmployeeOffer } from './../utils/CustomerLibrary';
import loader from './../images/web-images/loading.gif';

class Processing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: 'Please wait while we are processing your payment...',
            retry: 0
        }
    }

    getEmployeePerk = (totalprice) => {
        const { parentId, foodcourt } = this.props.order;
        const cookie = this.props.cookies.cookies;
        const body = {
            restaurantid: parentId,
            foodcourtid: foodcourt,
            ordervalue: totalprice,
            orders: this.props.order
        }
        if (!_.isEmpty(cookie) && cookie.token)
            return getEmployeeOffer(body, cookie.token).then(res => {
                if (res && res.data.discountfromcompany) {
                    this.props.setPerkInfo(res.data);
                    this.props.setPerkInfoInOrder(res.data);
                    return res.data.discountfromcompany;
                }
                else {
                    this.props.setPerkInfo({});
                    this.props.setPerkInfoInOrder({});
                    return 0;
                }
            })
    }

    async componentDidMount() {
        await this.gokashBalance();
        await this.walletBalance();
        await this.gokashCreditDiscount();

        const store = this.props;
        const { wallet } = store.paymentpage.paymentoption;
        const { gokashplus } = wallet;
        let plusBalance = false;
        let createTheOrder = false;
        (wallet.isActive && gokashplus.isActive) ? plusBalance = true : false;
        if (plusBalance && (this.props.customer.gokashPlus >= this.props.order.totalprice)) {
            createTheOrder = true;
        }
        let notificationmessage = {
            notificationid: uuidv1(),
            appId: '5825d15cf009c4bc37fd7b9f',
            profile: 'gokhana',
            targets: [],
            message: '',
            title: "New food order",
            info: {
                orderid: '',
                restaurant: 'restaurant',
                orderstatus: 'requested'
            }
        };
        const { cookies } = this.props.cookies;
        const order = { ...this.props.order };
        delete order.orderResponse;
        const placedTime = moment().toISOString();
        const orderData = {
            placedTime,
            paidamount: order.paidamount
        };


        this.props.setOrderData(orderData);
        order.placedtime = orderData.placedTime;
        order.paidamount = order.totalprice;
        order.paid = true;

        //create the food order
        // setTimeout(() => {

        let price = order.grossamount + order.parcelcharge + order.taxamount;
        let perkDiscount = await this.getEmployeePerk(price);

        if (order.discountfromcompany && !order.discountfromcredit) {
            const empdiscount = order.discountsplit.find(dis => dis.id === store.customer.perkInfo.perk);
            if (empdiscount && (order.discountfromcompany == perkDiscount)) {
                this.createOrder(order.parentId, order, cookies.token, notificationmessage);
            }
            else {
                this.props.history.push(`/restaurant/${this.props.order.parentId}`);
            }
        }
        else if (!order.discountfromcompany && order.discountfromcredit) {
            const godiscount = order.discountsplit.find(dis => dis.id === store.customer.gokashDiscount[0].id);
            if (godiscount && (store.customer.gokash === store.customer.latestgokash)) {
                this.createOrder(order.parentId, order, cookies.token, notificationmessage);
            }
            else {
                this.props.history.push(`/restaurant/${this.props.order.parentId}`);
            }
        }
        else if (order.discountfromcompany && order.discountfromcredit) {
            const empdiscount = order.discountsplit.find(dis => dis.id === store.customer.perkInfo.perk);
            const godiscount = order.discountsplit.find(dis => dis.id === store.customer.gokashDiscount[0].id);

            if ((empdiscount && (order.discountfromcompany == perkDiscount)) && (godiscount && (store.customer.gokash === store.customer.latestgokash))) {
                this.createOrder(order.parentId, order, cookies.token, notificationmessage);
            }
            else {
                this.props.history.push(`/restaurant/${this.props.order.parentId}`);
            }
        }
        else if (!order.discountfromcompany && !order.discountfromcredit) {
            if (createTheOrder) {
                this.createOrder(order.parentId, order, cookies.token, notificationmessage);
            }
            else {
                this.props.history.push(`/restaurant/${this.props.order.parentId}`);
            }
        }
    }

    gokashBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            return getGokash(cookie.id, cookie.token).then(res => {
                res.data ? this.props.latestGokash(res.data) : '';
            })
    }

    walletBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            return getGokashPlus(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokashPlus(res.data) : 0 };
            })
    }

    gokashCreditDiscount = () => {
        const restaurantid = this.props.order.parentId;
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            return getCreditDiscount(restaurantid, cookie.token).then(res => {
                this.props.setGokashDiscount(res.data);
            })
    }

    createOrder = (parentId, order, token, notificationmessage) => {
        createFoodOrder(parentId, order, token).then(result => {
            if (result.status == 200) {
                var orderId = ""
                if (result && result.data && result.data.orderid) {
                    orderId = result.data.orderid
                    notificationmessage.targets.push(result.data.parentId);
                    notificationmessage.info.orderid = orderId;
                    notificationmessage.info.restaurant = result.data.parentId;
                    notificationmessage.info.orderstatus = result.data.orderstatus;
                    notificationmessage.message = `Customer has placed a new order: ${orderId}. Please start preparing it`;
                }
                result.data.orderid ? (
                    this.props.emptyCart(),
                    this.props.setDisabledItems([]),
                    this.props.removeFoodcourtdata(),
                    this.props.setOrderId(result.data),
                    sendNotification(notificationmessage, token)
                        .then(res => {
                            this.props.history.push('/order-confirmation');
                        })
                ) : ''
            }
        }).catch(e => {
            if (e.response) {
                if (e.response.status == 404) {
                    this.retryCreateOrder(10000, parentId, order, token, notificationmessage);
                } else if (e.response.status == 504) {
                    this.retryCreateOrder(10000, parentId, order, token, notificationmessage);
                }
            } else if (e.code) {
                if (e.code == 'ECONNABORTED') {
                    this.retryCreateOrder(10000, parentId, order, token, notificationmessage);
                }
            }
        });
    }


    retryCreateOrder = (timeout, parentId, order, token, notificationmessage) => {
        if (this.state.retry < 6) {
            this.setState({ retry: this.state.retry + 1, message: 'Just a moment we are trying to place your order...' });
            setTimeout(() => {
                this.createOrder(parentId, order, token, notificationmessage);
            }, timeout);

        } else {
            this.props.history.push('/order-failed');
        }
    }

    render() {
        return (
            <div style={{ textAlign: 'center' }}>
                <img src={loader} width="120px" />
                <h4>{this.state.message}</h4>
                <h5>(Do not press the refresh or back button)</h5>
            </div>
        );
    }

}

const mapDispatchToProps = {
    emptyCart,
    removeFoodcourtdata,
    setOrderId,
    setOrderData,
    latestGokash,
    setGokashDiscount,
    setDisabledItems,
    setPerkInfo,
    setPerkInfoInOrder,
    setGokashPlus
};

export default withCookies(withRouter(connect(state => state, mapDispatchToProps)(Processing)));
