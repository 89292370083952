import React from 'react';
import { connect } from 'react-redux';

import { selectOption, showPaymentInfoModal } from '../../actions/paymentActions';
import Modal from 'react-modal';
import { loadJsLibraries } from '../../utils/LoadLibrary';
import CreditCard from './CreditCard';
import NetBanking from './NetBanking';
import Wallet from './Wallet';
import Upi from './Upi';
import DebitCard from './DebitCard';
import MealVoucher from './MealVoucher';

class PaymentOptions extends React.Component {
    componentDidMount() {
        loadJsLibraries();
    }

    closeModal = () => {
        this.props.showPaymentInfoModal(false);
    }

    render() {
        const { cc, dc, nb, upi, wallet, mealvouchers } = this.props.paymentoptions;
        let showButton = true;
        if (this.props.restaurant.id == '5ece3e3280184677c45b5075') {
            showButton = false;
        }
        return (
            <div>
                <div className="payment_select">
                    <label className="radio-container">Credit Card
                        <input
                            type="radio"
                            value=""
                            name="payment_method"
                            {...cc.isActive ? { checked: true } : {}}
                            onChange={() => this.props.selectOption('cc')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <i className="icon_creditcard"></i>
                </div>

                {cc.isActive ? <CreditCard /> : ''}

                <div className="payment_select">
                    <label className="radio-container">Debit Card
                        <input
                            type="radio"
                            value=""
                            name="payment_method"
                            {...dc.isActive ? { checked: true } : {}}
                            onChange={() => this.props.selectOption('dc')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <i className="icon_creditcard"></i>
                </div>

                {dc.isActive ? <DebitCard /> : ''}

                <div className="payment_select">
                    <label className="radio-container"> Net Banking
                        <input
                            type="radio"
                            value=""
                            name="payment_method"
                            {...nb.isActive ? { checked: true } : {}}
                            onChange={() => this.props.selectOption('nb')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <i className="icon_laptop"></i>
                </div>

                {nb.isActive ? <NetBanking /> : ''}

                <div className="payment_select">
                    <label className="radio-container"> UPI
                        <input
                            type="radio"
                            value=""
                            name="payment_method"
                            {...upi.isActive ? { checked: true } : {}}
                            onChange={() => this.props.selectOption('upi')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <i className="icon_laptop"></i>
                </div>

                {upi.isActive ? <Upi /> : ""}

                <div className="payment_select">
                    <label className="radio-container"> Wallet
                        <input
                            type="radio"
                            value=""
                            name="payment_method"
                            {...wallet.isActive ? { checked: true } : {}}
                            onChange={() => this.props.selectOption('wallet')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <i className="icon_wallet"></i>
                </div>

                {wallet.isActive ? <Wallet /> : ""}

                {showButton && <div className="payment_select">
                    <label className="radio-container"> Meal Vouchers
                        <input
                            type="radio"
                            value=""
                            name="payment_method"
                            {...mealvouchers.isActive ? { checked: true } : {}}
                            onChange={() => this.props.selectOption('mealvouchers')}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <i className="icon-credit-card-4"></i>
                </div>}

                {mealvouchers.isActive ? <MealVoucher /> : ''}

                <Modal
                    isOpen={this.props.showModal}
                    contentLabel="Common Modal"
                    className="Modal"
                    overlayClassName="Overlay"
                    closeTimeoutMS={200}
                    ariaHideApp={false}

                >
                    <div className="popup-form" id="message">
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <p style={{ color: 'chocolate', fontSize: '18px' }}>
                                Payment will be collected by GoKhana ("Tobox Ventures Pvt. Ltd.) on behalf of {this.props.restaurantName}.
                            </p>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-submit" onClick={this.closeModal} style={{ background: '#39c069' }}>OK</button>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        paymentoptions: state.paymentpage.paymentoption,
        restaurant: state.restaurant,
        showModal: state.paymentpage.showPaymentInfoModal,
        restaurantName: state.restaurant.name
    }
};

export default connect(mapStateToProps, { selectOption, showPaymentInfoModal })(PaymentOptions);