import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import veg from './../../images/web-images/veg.png';
import nonveg from './../../images/web-images/nonveg.png';

class Mitem extends React.Component {

    state = {
        checkedCount: 0
    }

    remove = false;
    sameItem = false;

    getMatchedItemCount = (matcheditem) => {
        let count = 0;
        matcheditem.forEach(item => {
            count += item.count;
        })
        return count;
    }

    render() {
        const { item } = this.props;
        const matchedItems = this.props.order.items.filter(it => it.restaurantmenuitem == item.id);
        const addonItem = this.props.order.items.find(ite => (ite.restaurantmenuitem == item.id) && (ite.customeitems.length || ite.features.length));
        const count = this.getMatchedItemCount(matchedItems);
        return (
            <tr>
                <td>
                    <img src={item.isVeg ? veg : nonveg} width="20px" />
                </td>
                <td style={{ "width": "70%" }}>
                    <figure className="thumb_menu_list"><img src={item.isVeg ? 'veg' : 'nonveg'} alt="thumb" /></figure>
                    <h5>{item.name}</h5>
                </td>
                <td style={{ "width": "16%" }}>
                    <strong>&#8377; {item.price}</strong>
                </td>
                <td className="options">
                    {matchedItems.length ? (
                        <div style={{ width: '70px', height: '25px', fontSize: '16px', padding: '0.5px 6px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid', borderRadius: '20px' }}>
                            <NavLink
                                to="#"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="true"
                                id={item.id}
                                onClick={(e) => { e.preventDefault(); this.remove = true; this.sameItem = false; this.props.displayItems(e) }}
                            >-</NavLink>
                            &nbsp;&nbsp;&nbsp;<strong style={{ fontSize: '11px' }}>{count}</strong>&nbsp;&nbsp;&nbsp;
                            <NavLink
                                to="#"
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="true"
                                id={item.id}
                                onClick={(e) => { e.preventDefault(); this.remove = false; this.sameItem = true; this.props.handleDispAddons(e) }}
                            >+</NavLink>
                        </div>
                    )
                        : <NavLink
                            to="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="true"
                            id={item.id}
                            onClick={(e) => { e.preventDefault(); this.remove = false; this.props.handleDispAddons(e) }}
                        >
                            <div style={{ width: '70px', height: '25px', fontSize: '12px', padding: '3px 6px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid', borderRadius: '20px' }}>ADD</div>
                        </NavLink>
                    }
                    {
                        !!this.props.category.menuitem ? (
                            this.props.category.menuitem.id === item.id ? (
                                this.props.category.menuitem.addonitems.length || this.props.category.menuitem.customeitems.length ?
                                    (
                                        this.sameItem ? (
                                            <div className="dropdown dropdown-options open">
                                                <div className="dropdown-menu">
                                                    <p style={{ textAlign: 'justify' }}>Please choose if you wish to continue with previous customization or to create different customization</p>
                                                    <NavLink
                                                        to="#"
                                                        className="add_to_basket"
                                                        id={item.id}
                                                        onClick={e => { e.preventDefault(); this.sameItem = false; this.props.handleDispAddons(e) }}
                                                    >Create New</NavLink>
                                                    <NavLink
                                                        to="#"
                                                        className="add_to_basket"
                                                        name="add"
                                                        // data-mid={matcheditem.id}
                                                        id={item.id}
                                                        onClick={e => { e.preventDefault(); this.props.addToCart(e); }}
                                                    >Keep Same</NavLink>
                                                </div>
                                            </div>
                                        )
                                            : (addonItem && this.remove ? (
                                                <div className="dropdown dropdown-options open">
                                                    <div className="dropdown-menu">
                                                        {
                                                            matchedItems.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td title={item.menuitemname}>
                                                                            <NavLink
                                                                                to="#"
                                                                                className="dropdown-toggle"
                                                                                data-toggle="dropdown"
                                                                                aria-expanded="true"
                                                                                id={item.restaurantmenuitem}
                                                                                onClick={(e) => { e.preventDefault(); this.props.removeFromCart(e) }}
                                                                            >
                                                                                <i className="icon_minus_alt" style={{ fontSize: '15px' }}></i>
                                                                            </NavLink>
                                                                        &nbsp;<strong style={{ verticalAlign: 'text-bottom' }}>{item.count}</strong>&nbsp;
                {/* <NavLink
                                                                            to="#"
                                                                            className="remove_item"
                                                                            id={item.id}
                                                                            data-mid={item.restaurantmenuitem}
                                                                            data-price={item.totalprice}
                                                                            onClick={this.addItem}
                                                                        >
                                                                            <i className="icon_plus_alt"></i>
                                                                        </NavLink> */}
                                                                        &nbsp;
                {item.menuitemname.length > 20 ? `${item.menuitemname.substring(0, 20)}...` : item.menuitemname}
                                                                            {
                                                                                item.features.length !== 0 ? (
                                                                                    item.features.map(option => {
                                                                                        return <div title={option.name}><i className="icon-check-1" style={{ fontSize: '15px' }}></i>{option.name.length > 18 ? `${option.name.substring(0, 18)}...` : option.name}</div>
                                                                                    })
                                                                                )
                                                                                    : <div></div>
                                                                            }
                                                                            {
                                                                                item.customeitems.length !== 0 ? (
                                                                                    item.customeitems.map(option => {
                                                                                        return (
                                                                                            <div title={option.categoryname}>
                                                                                                <i className="icon-check-1"></i>
                                                                                                {option.categoryname.length > 18 ? `${option.categoryname.substring(0, 18)}...` : option.categoryname}
                                                                                                {
                                                                                                    option.options.map(opt => {
                                                                                                        return <div className="sub-options" title={opt.name}><i className="icon-minus"></i>{opt.name}</div>
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                )
                                                                                    : <div></div>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <strong className="pull-right"> &#8377;{item.totalprice}</strong>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        <NavLink
                                                            to="#"
                                                            className="add_to_basket"
                                                            id={item.id}
                                                            onClick={e => { e.preventDefault(); this.props.cancel(e); }}
                                                        >Cancel</NavLink>
                                                    </div>
                                                </div>
                                            ) : (
                                                    <div className="dropdown dropdown-options open">
                                                        <div className="dropdown-menu">
                                                            {
                                                                this.props.category.menuitem.addonitems.length !== 0 ? (
                                                                    <div>
                                                                        <h5>Select Add-ons</h5>
                                                                        {
                                                                            this.props.category.menuitem.addonitems.map((addonitem, index) => {
                                                                                return (<label key={index} title={addonitem.name}>
                                                                                    <input
                                                                                        type={this.props.category.menuitem.addonchoicetype === 'singlechoice' ? 'radio' : 'checkbox'}
                                                                                        defaultValue={addonitem.name}
                                                                                        onClick={this.props.handleMenuAddonItem}
                                                                                        name="selectoption"
                                                                                        {...addonitem.included ? { checked: true } : {}}
                                                                                    />{addonitem.name.length >= 15 ? `${addonitem.name.substring(0, 15)}...` : addonitem.name}
                                                                                    {addonitem.price !== 0 ? <span>+ &#8377; {addonitem.price}</span> : ''}
                                                                                </label>)
                                                                            })
                                                                        }

                                                                    </div>
                                                                ) : ''
                                                            }
                                                            {
                                                                this.props.category.menuitem.customeitems.length !== 0 ? (
                                                                    <div>
                                                                        {
                                                                            this.props.category.menuitem.customeitems.map((customeitem, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <h5>{customeitem.categoryname}</h5>
                                                                                        {
                                                                                            customeitem.options.map(option => {
                                                                                                return (
                                                                                                    option.active ? (
                                                                                                        <label title={option.name}>
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name={customeitem.categoryname}
                                                                                                                id={option.id}
                                                                                                                value={customeitem.maxchoice}
                                                                                                                onClick={this.props.handleMenuCustomItem}
                                                                                                                checked={option.included}
                                                                                                            // {...option.included ? { checked: true } : {}}
                                                                                                            />{option.name}
                                                                                                            {option.price !== 0 ? <span>+ &#8377;{option.price}</span> : ''}
                                                                                                        </label>
                                                                                                    ) : ''
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                )

                                                                            })
                                                                        }

                                                                    </div>
                                                                ) : ''
                                                            }
                                                            <NavLink
                                                                to="#"
                                                                className="add_to_basket"
                                                                id={item.id}
                                                                onClick={e => { e.preventDefault(); this.props.addToCart(e); }}
                                                            >Add to cart</NavLink>
                                                            <NavLink
                                                                to="#"
                                                                className="add_to_basket"
                                                                id={item.id}
                                                                onClick={e => { e.preventDefault(); this.props.cancel(e); }}
                                                            >Cancel</NavLink>
                                                        </div>
                                                    </div>))
                                    ) : ''
                            ) : ''

                        ) : ''
                    }
                </td>
            </tr>
        );
    }
}

export default connect(state => state)(Mitem);