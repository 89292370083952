import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

import SearchLocationBar from './SearchLocationBar';

import { setLocation } from './../../actions/locationActions';
import { toggleLoader } from './../../actions/loaderActions';

class searchBarPopup extends React.Component {

    changeLocation = (e) => {
        e.preventDefault();
        const loc = e.currentTarget;
        let location = {};
        location.latitude = loc.attributes.getNamedItem('data-lat').value;
        location.longitude = loc.attributes.getNamedItem('data-lng').value;
        location.city = loc.attributes.getNamedItem('data-city').value;
        location.state = '';

        this.props.setLocation(location);
        this.props.toggleLoader(true);
        setTimeout(() => {
            this.props.history.push('/restaurants');
        }, 1000);
    }

    render() {
        return (
            <div className="row" id="search-location-popup">
                <div className="col-md-12">
                    <NavLink
                        to="#"
                        id="change-mobile-location-close"
                        onClick={e => e.preventDefault()}
                    >
                        <span
                            style={{ marginTop: '8px', marginRight: '8px', fontSize: '26px', float: 'right' }}
                            className="glyphicon glyphicon-remove-circle"
                        >
                        </span>
                    </NavLink>
                    <div id="subheader" style={{ "backgroundColor": "#ffffff", "color": "#000000", "paddingTop": "50px" }} >
                        <div id="sub_content" style={{ "backgroundColor": "#ffffff", "color": "#000000" }} >
                            <div className="col-md-8 col-sm-12" style={{ "textAlign": "left" }}>
                                <SearchLocationBar />
                                <div style={{ "marginTop": "20px", "fontSize": "11px" }}>Locations</div>
                                <NavLink
                                    to="#"
                                    style={{ "color": "#000000" }}
                                    onClick={this.changeLocation}
                                    data-lat="12.9715987"
                                    data-lng="77.59456269999998"
                                    data-city="Bangalore"
                                >Bangalore</NavLink> &nbsp;
                                <NavLink
                                    to="#"
                                    style={{ "color": "#000000" }}
                                    onClick={this.changeLocation}
                                    data-lat="12.9260308"
                                    data-lng="77.6762463"
                                    data-city="Bellandur"
                                >Bellandur</NavLink> &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {
    setLocation,
    toggleLoader
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(searchBarPopup));
