
export const calculateGokashDiscount = (gokashDiscount, totalprice, gokashBalance) => {
    if (gokashBalance > 0) {
        if (gokashBalance && gokashDiscount && gokashDiscount.length) {
            let discountFromCredit = 0;
            if (gokashDiscount[0].valuetype === "percentage") {
                discountFromCredit = (gokashDiscount[0].value * (totalprice) * 0.01);
            }
            else if (gokashDiscount[0].valuetype === "flat") {
                discountFromCredit = (gokashDiscount[0].value);
            }
            let discountApplied = 0;
            if (gokashBalance <= discountFromCredit) {
                if (totalprice >= gokashBalance) discountApplied = gokashBalance;
                else discountApplied = totalprice;
            }
            else if (gokashBalance > discountFromCredit) {
                if (discountFromCredit <= totalprice) discountApplied = discountFromCredit;
                else discountApplied = totalprice;
            }
            return discountApplied;
        } else return 0;
    }
    else return 0;
}