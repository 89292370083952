import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

import { selectInfo } from './../../actions/paymentActions';

class CheckoutSidebar extends React.Component {
    mobile;

    UNSAFE_componentWillMount() {
        this.setActive('payment');
        if (window.screen.width < 991) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }
    }

    setActive = (data) => {
        data === 'contact' ?
            this.props.selectInfo({ contactdetails: true, paymentdetails: false }) :
            this.props.selectInfo({ contactdetails: false, paymentdetails: true })
    }

    backToMenu = (e) => {
        e.preventDefault();
        const id = this.props.order.parentId;
        this.mobile ? this.props.history.push(`/order-summary`) : this.props.history.push(`/restaurant/${id}`);
        window.location.reload();
    }

    render() {
        const { contactdetails, paymentdetails } = this.props.paymentpage.selectinfo;
        const { restaurantcustomer } = this.props.order;
        return (
            <div className="col-md-3">
                <p><NavLink
                    to="#"
                    className="btn_side"
                    onClick={this.backToMenu}
                >{this.mobile ? 'Back to Cart' : 'Back to Menu'}
                </NavLink>
                </p>
                <div className="box_style_1">
                    <ul id="cat_nav">
                        {restaurantcustomer ? (<li>
                            <NavLink
                                to="#"
                                onClick={(e) => { e.preventDefault(); this.setActive('contact') }}
                                {...contactdetails ? { className: 'active' } : {}}
                            >Contact Details</NavLink>
                        </li>) : ''}
                        <li>
                            <NavLink
                                to="#"
                                onClick={(e) => { e.preventDefault(); this.setActive('payment') }}
                                {...paymentdetails ? { className: 'active' } : {}}
                            >Payment Details</NavLink>
                        </li>
                    </ul>
                </div>

                <div className="box_style_2 hidden-xs" id="help">
                    <i className="icon_lifesaver"></i>
                    <h4>Need <span>Help?</span></h4>
                    <a href="tel://+918884417543" className="phone">+918884417543</a>
                    <small>Monday to Friday 9.00am - 7.30pm</small>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectInfo
};

export default withRouter(connect(state => state, mapDispatchToProps)(CheckoutSidebar));
