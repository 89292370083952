import React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import moment from 'moment';

import Header from './sections/Header';
import Footer from './sections/Footer';
import MenuItemsGroup from './sections/MenuItemsGroup';
import OrderSummary from './sections/OrderSummary';
import MenuCategories from './sections/MenuCategories';
import RestaurantBanner from './sections/RestaurantBanner';
import SearchBarPopup from './sections/SearchBarPopup';
import Redirect from './Redirect';

import { toggleLoader } from './../actions/loaderActions';
import { setMenu } from './../actions/menuActions';
import { setCategory } from './../actions/categoryActions';
import { setSelectedRestaurant } from './../actions/restActions';
import { setRestFoodCourt, emptyCart } from './../actions/orderActions';
import { setCuisines } from './../actions/cuisineActions';
import { toggleFoodCourt, setFoodCourtId, toggleVegOnly } from './../actions/filterActions';

import { loadJsLibraries } from './../utils/LoadLibrary';
import { fetchMenu } from './../utils/ApiLibrary';
import { fetchRestaurant, fetchCuisines } from './../utils/ApiLibrary';
import { getCreditDiscount, getCreditBackOffer, getGokash, getGokashPlus } from './../utils/CustomerLibrary';
import { setGokashDiscount, setGokashCreditBack, setGokash, setGokashPlus } from './../actions/customerActions';
import { showButtons } from './../actions/cartActions';

import loaderImg from './../images/web-images/loader.gif';

class RestaurantMenu extends React.Component {
    constructor(props) {
        super(props);
        this.props.showButtons(true);
        if (this.props.customer.isAuthenticated) {
            this.gokashCreditDiscount();
            this.gokashCreditBack();
            this.gokashBalance();
            this.walletBalance();
        }
        this.state = {
            reorder: false,
            smallScreen: false
        }
    }

    checkIfOpen = (openinghours, open) => {
        if (!open) { open = false; }
        let openStatus = '';
        openinghours.some((openingHr) => {
            if (openStatus === '' || openStatus === 'Closed') {
                let hoursArray = openingHr.split('-');
                const currentTime = moment();
                const beforeTime = moment(hoursArray[0].trim(), 'hh:mm A');
                let afterTime = moment(hoursArray[1].trim(), 'hh:mm A');
                if (afterTime.isSameOrBefore(beforeTime)) {
                    afterTime = afterTime.add(1, 'd');
                }
                if (currentTime.isBefore(beforeTime) && open) { openStatus = 'Opened Early'; }
                else if (currentTime.isAfter(afterTime) && open) { openStatus = 'Still Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && open) { openStatus = 'Open'; }
                else if (currentTime.isBetween(beforeTime, afterTime) && !open) { openStatus = 'Closed'; }
                else { openStatus = 'Closed'; }
            }
        });
        return openStatus;
    }

    gokashCreditDiscount = () => {
        const restaurantid = this.props.order.parentId;
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getCreditDiscount(restaurantid, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokashDiscount(res.data) : '' };
            })
    }

    gokashCreditBack = () => {
        const restaurantid = this.props.order.parentId;
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getCreditBackOffer(restaurantid, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokashCreditBack(res.data) : '' };
            })
    }

    gokashBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getGokash(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokash(res.data) : 0 };
            })
    }

    walletBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            return getGokashPlus(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokashPlus(res.data) : 0 };
            })
    }

    UNSAFE_componentWillMount() {
        const { state } = this.props.history.location;
        if (state && state.response == 'reorder') {
            this.setState({ reorder: true });
            this.props.toggleLoader(true);
            this.props.history.push(`/restaurant/${this.props.match.params.id}`, { response: '' });
            setTimeout(() => {
                this.props.toggleLoader(false);
            }, 3000);
        }

        const id = this.props.match.params.id;
        const restId = this.props.restaurant.id;

        let openinghours = [];
        if (id) {
            if (id !== restId) {
                fetchCuisines('/gokhana/cuisine').then(result => {
                    this.props.setCuisines(result);
                    fetchRestaurant(id).then(result => {
                        if (result.data) {
                            result.data.openinghours.forEach((openingHr) => {
                                if (moment().format('dddd').toLowerCase() === openingHr.day.toLowerCase()) {
                                    openinghours.push(`${moment(openingHr.from, ['HH:mm']).format('hh:mm A')} - ${moment(openingHr.to, ['HH:mm']).format('hh:mm A')}`);
                                }
                            });

                            let openStatus;
                            openinghours.length ? openStatus = this.checkIfOpen(openinghours, result.data.open) : openStatus = 'Closed';
                            let restaurantData = {
                                id: result.data.id,
                                name: result.data.name,
                                address: result.data.address,
                                imageUrl: result.data.imageUrl,
                                cuisines: result.data.cuisines,
                                isExist: true,
                                openinghours,
                                openStatus,
                                dinein: result.data.isdineinavailable,
                                takeaway: result.data.istakeawayavailable,
                                delivery: result.data.isdeliveryavailable
                            };

                            let setFoodOrderData = {
                                parentId: result.data.id,
                                restaurantname: result.data.name,
                                foodcourt: result.data.foodcourt ? result.data.foodcourt : '',
                                foodcourtname: result.data.foodcourtRecord ? result.data.foodcourtRecord.name : '',
                                imageUrl: result.data.imageUrl
                            };

                            if (this.props.filters.foodcourtOnly && this.props.filters.foodcourtCB == "true") {
                                setFoodOrderData.foodcourt = this.props.filters.foodcourtId;
                                setFoodOrderData.foodcourtname = this.props.filters.foodcourtName;
                            };

                            this.props.setRestFoodCourt(setFoodOrderData);
                            this.props.setSelectedRestaurant(restaurantData);

                            fetchMenu(id).then(result => {

                                this.props.setMenu(result);
                                const cat = {
                                    id: result[0].id,
                                    name: result[0].name
                                };
                                this.props.setCategory(cat);

                            });
                        } else {
                            this.props.setSelectedRestaurant({ isExist: false });
                        }
                    })
                })
            }
        } else {
            this.props.history.push('/')
        };
    }

    resize() {
        let currentScreen = (window.innerWidth <= 770);
        if (currentScreen !== this.state.hideNav) {
            this.setState({ smallScreen: currentScreen });
        }
    }

    componentDidMount() {
        loadJsLibraries();
        window.addEventListener("resize", this.resize);
        this.resize();
        if (this.props.order.parentId !== this.props.match.params.id) {
            this.props.emptyCart();
        }

        window.addEventListener('scroll', this.handleOnScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleOnScroll);
        window.removeEventListener("resize", this.resize);
    }

    backToRestaurants = (e) => {
        e.preventDefault();
        this.props.history.push('/restaurants');
    }

    handleVegOnlyChange = (e) => {
        this.props.toggleVegOnly(e.target.checked);
    }

    handleOnScroll = (e) => {

        let trackingElement;
        let result;
        let computedPositions = [];
        let catIds = [];
        let catNames = [];
        let i = 0;
        let id;
        let name;
        let topValue;
        let menuItem;

        const allElements = document.querySelectorAll('.cat_menu_items');
        const itemsLength = allElements.length;

        while (i < itemsLength) {
            catIds.push(allElements[i].getAttribute('data-categoryid'));
            catNames.push(allElements[i].getAttribute('data-categoryname'));

            trackingElement = document.querySelector(`#${allElements[i].id}`).getBoundingClientRect();
            result = trackingElement.top - 60;
            computedPositions.push(result);
            i++;
        }

        computedPositions.forEach((pos, index) => {
            if (pos > -20 && pos < 30) {
                id = catIds[index];
                name = catNames[index];
                this.props.setCategory({ id, name });
            }
        });

        //on page scroll keep the category box fixed
        const element = document.getElementById('myScrollspy');
        topValue = document.body.getBoundingClientRect();
        if (topValue.top <= -60) {
            element && element.classList.add('catonscroll');
            document.getElementById('menu-cat-back-button') && document.getElementById('menu-cat-back-button').classList.add('hide-element');
            document.getElementById('veg-only-filter') && document.getElementById('veg-only-filter').classList.add('hide-element');
        } else {
            element && element.classList.remove('catonscroll');
            document.getElementById('menu-cat-back-button') && document.getElementById('menu-cat-back-button').classList.remove('hide-element');
            document.getElementById('veg-only-filter') && document.getElementById('veg-only-filter').classList.remove('hide-element');
        }

        menuItem = document.getElementById('menu-items-group-parent').getBoundingClientRect();
        result = menuItem.height + menuItem.top;
        let eleHeight;
        if (result <= 700) {
            if (element.getBoundingClientRect().height > 600) {
                eleHeight = element.getBoundingClientRect().height / 1.14;
            }
            else {
                eleHeight = element.getBoundingClientRect().height / 2.5;
            }
            element.style.position = 'absolute';
            element.style.top = Math.abs(menuItem.top + eleHeight) + 'px';
        } else {
            element.style.position = '';
            element.style.top = '';
        }
    }

    getItemsCount = (items) => {
        let itemscount = 0;
        if (items.length !== 0) {
            items.forEach(item => {
                itemscount = itemscount + item.count;
            })
        }
        return itemscount;
    }

    render() {
        const { items } = this.props.order;
        let showButton = true;
        if (this.props.restaurant.id == '5ece3e3280184677c45b5075') {
            showButton = false;
        }
        const itemscount = this.getItemsCount(this.props.order.items);
        if (!this.props.category.id) {
            return (<div style={{ textAlign: 'center', marginTop: '200px' }}><img alt="Loader" src={loaderImg} style={{ width: '50px' }} /><h2>Loading...</h2></div>);
        }
        // if (this.state.smallScreen) {
        //     return <Redirect
        //         url={`https://eat.gokhana.com/#/restaurant/${this.props.restaurant.id}`}
        //     />
        // }
        // else 
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header />
                <RestaurantBanner />
                <div className="container margin_60_35" id="menu-items-content">
                    <div className="row">
                        {/* Mobile view */}
                        {showButton && <div className="col-sm-12" id="mobile-back-rest-button">
                            <p>
                                <NavLink
                                    to="#"
                                    className="btn_side"
                                    onClick={this.backToRestaurants}
                                >Back to Restaurants
                                </NavLink>
                            </p>
                        </div>}

                        <div className="col-sm-12" id='mobile-veg-only-filter'>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="col-xs-5">
                                    </div>
                                    <div className="col-xs-7" style={{ padding: 0 }}>
                                        <div className="col-xs-7" style={{ textAlign: 'right' }}>
                                            <h5>Only Veg</h5>
                                        </div>
                                        <div className="col-xs-5" id="mobile-veg-filter">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => this.handleVegOnlyChange(e)}
                                                    {...this.props.filters.vegOnly ? { checked: true } : {}}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                            <NavLink
                                to="#"
                                className="mobile-cart"
                                id="m-cart"
                                onClick={(e) => { e.preventDefault(); }}
                            >
                                <i className="icon_cart_alt" id="m-cart-i"></i>
                                {
                                    this.props.order.items.length !== 0 ? (
                                        <sup>{this.props.order.itemscount}</sup>
                                    ) : ''
                                }
                            </NavLink>
                        </div> */}

                        {items.length ? <NavLink to="/order-summary">
                            <div id="display-mobile-cart">
                                <div style={{ fontSize: '16px', padding: '20px 40px' }}>
                                    <span>{itemscount} {itemscount > 1 ? 'Items' : 'Item'} | ₹{this.props.order.totalprice.toFixed(2)}</span>
                                    <span style={{ float: 'right' }}>VIEW CART</span>
                                </div>
                            </div>
                        </NavLink> : ''}

                        <div id="mobile-menu-cat-button">
                            <NavLink
                                to="#"
                                onClick={e => e.preventDefault()}
                            >
                                <i className="icon-book-open"></i>
                            </NavLink>
                        </div>

                        {
                            this.props.restaurant.isExist === false ? (
                                <h3 style={{ textAlign: 'center' }}>Oops! We are unable to connect you to the restaurant at the moment.</h3>
                            ) : (
                                <div>
                                    <div id="disp-mobile-menu-cat">
                                        <MenuCategories />
                                    </div>
                                    <div id="menu-cat-overlay"></div>
                                    <MenuItemsGroup handleOnScroll={this.handleOnScroll} />
                                    <div className="col-md-3" id="sidebar">
                                        <OrderSummary />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <Footer
                    reorder={this.state.reorder} />
            </div>
        );
    }

}

const mapDispatchToProps = {
    setMenu,
    setCategory,
    setSelectedRestaurant,
    setRestFoodCourt,
    setCuisines,
    emptyCart,
    toggleLoader,
    toggleFoodCourt,
    setFoodCourtId,
    toggleVegOnly,
    setGokashDiscount,
    setGokashCreditBack,
    setGokash,
    setGokashPlus,
    showButtons
};

export default withCookies(withRouter(connect(state => state, mapDispatchToProps)(RestaurantMenu)));