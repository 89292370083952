import React from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { setMobileField, setPasswordField, setEmail, setName } from './../../actions/formActions';
import { setLoginType } from './../../actions/customerActions';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        const { loginType } = this.props.customer;
        loginType === 'resetpassword' || loginType === 'password' ? this.props.setLoginType('password') : this.props.setLoginType('otp');
    }

    validateMobile = (e) => {
        let mobileNum = e.target.value;
        const isValid = validator.isMobilePhone(mobileNum, 'en-IN');
        !isValid ?
            this.props.setMobileField({ value: mobileNum, isValid: false, error: 'Enter a valid 10 digit mobile number.' }) :
            this.props.setMobileField({ value: mobileNum, isValid: true, error: '' });
    }
    validateEmail = (e) => {
        let email = e.target.value;
        const isValid = validator.isEmail(email);
        !isValid ?
            this.props.setEmail({ value: email, isValid: false, error: 'Enter a valid email address.' }) :
            this.props.setEmail({ value: email, isValid: true, error: '' });
    }
    storeName = (e) => {
        let name = e.target.value;
        this.props.setName({ value: name, isValid: true, error: '' });
    }

    setPassword = (e) => {
        let password = e.target.value;
        this.props.setPasswordField({ value: password, isValid: false, error: '' });
    }

    componentDidMount() {
        this.props.setMobileField({ value: '', isValid: false, error: '' });
        this.props.setPasswordField({ value: '', isValid: false, error: '' });
    }

    handleLogin = (e) => {
        const isValid = this.props.mobile.isValid;

        !isValid ? this.props.setMobileField({ value: '', isValid: false, error: 'Enter a valid 10 digit mobile number.' }) :
            this.props.action(e);
    }


    render() {
        const { action, showRegisterForm } = this.props;
        const { msg } = this.props.modal.data;
        const { loginType } = this.props.customer;

        const loginCss = {
            fontSize: '14px'
        }
        const checkoutCss = {
            fontSize: '16px',
            backgroundColor: '#F7DF4E',
            color: '#000000'
        }
        return (
            <form onSubmit={e => e.preventDefault()} className="popup-form" id="myLogin">
                {msg === 'checkout' ?
                    (<div className="text-center" style={{ marginBottom: '30px' }}>
                        <NavLink
                            to="#"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.setLoginType('guestLogin');
                            }}
                        > {loginType === 'guestLogin' ? '' : <button type="button" style={checkoutCss} className="btn">Continue As Guest</button>}
                        </NavLink>
                    </div>)
                    : ''
                }
                <div className="login_icon"><i className="icon_lock_alt"></i></div>
                {msg === 'Please login' ? <strong style={{ color: 'red' }}>Your number is already registered with GoKhana, Please login</strong> : ''}
                {msg === 'Password login' && loginType === 'password' ? <strong style={{ color: 'green' }}>Login with your new password</strong> : ''}
                {loginType === 'guestLogin' ?
                    <div>
                        <p style={{ color: 'chocolate', fontSize: '12px' }}>
                            Enter correct phone number to make payment and place order without registering. SMS will be sent with token and order number.
                   </p>
                        <input
                            type="text"
                            id="mobilenumber"
                            className="form-control form-white"
                            placeholder="Contact Number"
                            defaultValue={this.props.mobile.value ? this.props.mobile.value : ''}
                            onInput={this.validateMobile}
                            {...this.props.mobile.error ? { style: { borderColor: '#fd0000' } } : {}}
                        />
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.props.mobile.error}</p>

                        <input
                            type="email"
                            className="form-control form-white"
                            placeholder="Email"
                            onInput={this.validateEmail}
                            {...this.props.email.error ? { style: { borderColor: '#fd0000' } } : {}}
                        />
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.props.email.error}</p>

                        <input
                            type="text"
                            className="form-control form-white"
                            placeholder="Name"
                            onInput={this.storeName}
                        />
                    </div>
                    : <div><input
                        type="text"
                        id="mobilenumber"
                        className="form-control form-white"
                        placeholder="Contact Number"
                        onInput={this.validateMobile}
                        {...this.props.mobile.error ? { style: { borderColor: '#fd0000' } } : {}}
                    />
                        <p style={{ fontSize: '14px', color: '#fd0000', textAlign: 'left' }}>{this.props.mobile.error}</p></div>}

                {
                    loginType === 'password' ?
                        (<input
                            type="password"
                            id="password"
                            className="form-control form-white"
                            placeholder="Password"
                            onInput={this.setPassword}
                            {...this.props.password.error ? { style: { borderColor: '#fd0000' } } : {}}
                            {...this.props.password.error === 'Login success' ? { style: { borderColor: '#0a820f' } } : {}}
                        />) : ''}

                <p {...this.props.password.error === 'Login success' ? { style: { fontSize: '14px', color: '#0a820f', textAlign: 'left' } } : { style: { fontSize: '14px', color: '#fd0000', textAlign: 'left' } }}>{this.props.password.error}</p>


                {loginType === 'resetpassword' || loginType === 'guestLogin' ? '' : (<div className="text-left">
                    <NavLink
                        to="#"
                        onClick={(e) => { e.preventDefault(); showRegisterForm(''); }}
                    ><strong>Don't have account? Register here.</strong></NavLink>
                </div>)}

                {loginType === 'password' ? (<div className="text-right">
                    <NavLink
                        to="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.setPasswordField({ value: '', isValid: false, error: '' });
                            this.props.setLoginType('resetpassword');
                        }
                        }
                    ><strong>Forgot Password ?</strong></NavLink>
                </div>) : ''}

                {loginType === 'resetpassword' ? (<button
                    type="submit"
                    className="btn btn-submit"
                    onClick={this.handleLogin}
                >Send OTP</button>) : (loginType === 'guestLogin') ? (<button
                    type="submit"
                    className="btn btn-submit"
                    onClick={this.handleLogin}
                >GUEST CHECKOUT</button>) : (<button
                    type="submit"
                    className="btn btn-submit"
                    onClick={this.handleLogin}
                >LOGIN</button>)
                }

                {loginType === 'resetpassword' || loginType === 'guestLogin' ? '' : (<div className="text-center" style={{ marginTop: '30px' }}>
                    <NavLink
                        to="#"
                        onClick={(e) => {
                            e.preventDefault();
                            loginType === 'otp' ? this.props.setLoginType('password') : this.props.setLoginType('otp');
                        }}
                    >{loginType === 'password' ? <strong style={loginCss}>Login via OTP</strong> : <strong style={loginCss}>Login via Password</strong>}
                    </NavLink>
                </div>)}
            </form>
        );
    }
}

const mapDispatchToProps = {
    setMobileField,
    setName,
    setEmail,
    setLoginType,
    setPasswordField
};

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        mobile: state.formfields.mobile,
        email: state.formfields.email,
        password: state.formfields.password,
        customer: state.customer
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
