import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import { setCategory } from './../../actions/categoryActions';
import { setSelectedRestaurant } from './../../actions/restActions';
import { toggleFoodCourt, setFoodCourtId, toggleVegOnly } from './../../actions/filterActions';

class MenuCategories extends React.Component {

    displayCategory = (menu) => {
        const { vegOnly } = this.props.filters;
        if (vegOnly) {
            return menu.map((category, index) => {
                let newCat = {
                    ...category,
                    subcategories: [],
                    menuitems: []
                };

                if (category.subcategories.length) {
                    //subcategories
                    category.subcategories.map((subcat, index) => {
                        const newMI = this.filterItems(subcat.menuitems);
                        newMI.length &&
                            newCat.subcategories.push({
                                id: subcat.id,
                                name: subcat.name,
                                menuitems: newMI
                            });
                    });
                } else {
                    //menuitems
                    newCat.menuitems = this.filterItems(category.menuitems);
                }

                if (newCat.subcategories.length !== 0 || newCat.menuitems.length !== 0) {
                    return (<li key={newCat.id}>
                        <a
                            href={`#m${index}`}
                            {...this.props.category.id === newCat.id ? { className: 'active' } : ''}
                            onClick={this.handleSelectCategory}
                            id={newCat.id}
                            name={newCat.name}
                        >{newCat.name}
                        </a>
                    </li>)
                }


            });
        } else {
            return menu.map((category, index) => {
                return (<li key={category.id}>
                    <a
                        href={`#m${index}`}
                        {...this.props.category.id === category.id ? { className: 'active' } : ''}
                        onClick={this.handleSelectCategory}
                        id={category.id}
                        name={category.name}
                    >{category.name}
                    </a>
                </li>)
            });
        }

    }

    filterItems = (items) => {
        let filteredItems = items.filter(item => {
            return item.isVeg;
        });

        return filteredItems;
    }

    handleSelectCategory = (e) => {
        const id = e.currentTarget.id;
        const name = e.currentTarget.name;
        const cat = { id, name };
        this.props.setCategory(cat);
    }

    backToRestaurants = (e) => {
        e.preventDefault();
        this.props.history.push('/restaurants');
    }

    handleVegOnlyChange = (e) => {
        this.props.toggleVegOnly(e.target.checked);
    }

    render() {
        const { menu } = this.props;
        let showButton = true;
        if (this.props.restaurant.id == '5ece3e3280184677c45b5075') {
            showButton = false;
        }
        return (
            <div>

                <div className="col-md-3" id="menu-cat">
                    {showButton && <p id="menu-cat-back-button">
                        <NavLink
                            to="#"
                            className="btn_side"
                            onClick={this.backToRestaurants}
                        >Back to Restaurants
                        </NavLink>
                    </p>}

                    <div id="veg-only-filter">
                        <h5>Show Veg Only</h5>
                        <label className="switch">
                            <input
                                type="checkbox"
                                onChange={(e) => this.handleVegOnlyChange(e)}
                                {...this.props.filters.vegOnly ? { checked: true } : {}}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <nav className="box_style_1" id="myScrollspy">
                        <ul className="nav nav-pills nav-stacked" id="cat_nav">
                            {this.displayCategory(menu)}
                        </ul>
                    </nav>
                </div>

                {/* mobile device */}

                <div className="col-md-3" id="mobile-menu-cat">
                    <div className="box_style_1">
                        <ul id="cat_nav" style={{ 'height': '250px', 'overflowX': 'hidden', 'overflowY': 'scroll' }}>
                            {this.displayCategory(menu)}
                        </ul>
                        <div id="close-menu-cat"><i className="icon-cancel-circle-2"></i></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setCategory,
    setSelectedRestaurant,
    toggleFoodCourt,
    setFoodCourtId,
    toggleVegOnly
}

export default withRouter(connect(state => state, mapDispatchToProps)(MenuCategories));