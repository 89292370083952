import React from 'react';

export default () => {
    return (
        <div className="container margin_60_35">
            <hr className="more_margin" />
            <div className="main_title">
                    <h2 className="nomargin_top">Quick food quality feautures</h2>
                    <p>
                        Cum doctus civibus efficiantur in imperdiet deterruisset.
                    </p>
                </div>
            <div className="row">
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="feature">
                        <i className="icon_building"></i>
                        <h3><span>+ 1000</span> Restaurants</h3>
                        <p>
                            Lorem ipsum dolor sit amet, vix erat audiam ei. Cum doctus civibus efficiantur in. Nec id tempor imperdiet deterruisset, doctus volumus explicari qui ex, appareat similique an usu.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.2s">
                    <div className="feature">
                        <i className="icon_documents_alt"></i>
                        <h3><span>+1000</span> Food Menu</h3>
                        <p>
                            Lorem ipsum dolor sit amet, vix erat audiam ei. Cum doctus civibus efficiantur in. Nec id tempor imperdiet deterruisset, doctus volumus explicari qui ex, appareat similique an usu.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.3s">
                    <div className="feature">
                        <i className="icon_bag_alt"></i>
                        <h3><span>Delivery</span> or Takeaway</h3>
                        <p>
                            Lorem ipsum dolor sit amet, vix erat audiam ei. Cum doctus civibus efficiantur in. Nec id tempor imperdiet deterruisset, doctus volumus explicari qui ex, appareat similique an usu.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.4s">
                    <div className="feature">
                        <i className="icon_mobile"></i>
                        <h3><span>Mobile</span> support</h3>
                        <p>
                            Lorem ipsum dolor sit amet, vix erat audiam ei. Cum doctus civibus efficiantur in. Nec id tempor imperdiet deterruisset, doctus volumus explicari qui ex, appareat similique an usu.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.5s">
                    <div className="feature">
                        <i className="icon_wallet"></i>
                        <h3><span>Cash</span> payment</h3>
                        <p>
                            Lorem ipsum dolor sit amet, vix erat audiam ei. Cum doctus civibus efficiantur in. Nec id tempor imperdiet deterruisset, doctus volumus explicari qui ex, appareat similique an usu.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 wow fadeIn" data-wow-delay="0.6s">
                    <div className="feature">
                        <i className="icon_creditcard"></i>
                        <h3><span>Secure card</span> payment</h3>
                        <p>
                            Lorem ipsum dolor sit amet, vix erat audiam ei. Cum doctus civibus efficiantur in. Nec id tempor imperdiet deterruisset, doctus volumus explicari qui ex, appareat similique an usu.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}