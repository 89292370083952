import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;
    const { customer, cookies } = this.props;
    return <Route
      {...rest}
      render={props =>
        !!cookies.get('token') ? (
          <Component {...props} />
        ) : (
            <Component {...props} action="login" />
          )
      }
    />;
  }
}

export default withCookies(connect(state => state)(PrivateRoute));

