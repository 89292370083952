import React from 'react';
import Modal from 'react-modal';

import { connect } from 'react-redux';

import loaderImg from './../../images/web-images/loader.gif';

class Loader extends React.Component {

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.loader.isOpen}
                    contentLabel="Loader Modal"
                    className="Loader"
                    overlayClassName="Overlay"
                    closeTimeoutMS={200}
                    ariaHideApp={false}
                >
                    <div className="modal-dialog">
                        <div className="modal-content loader-popup">
                            <img src={loaderImg} />
                            <h4 style={{ color: '#fff', fontWeight: 'bold' }}>Loading...</h4>
                        </div>
                    </div>

                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Loader);