import React from 'react';
import { connect } from 'react-redux';

class RestaurantBanner extends React.Component {

    getCuisineName = (id) => {
        return this.props.cuisines.find(cuisine => {
            return cuisine.id === id;
        });
    }

    render() {
        const { openStatus } = this.props.restaurant;
        const green = {
            color: '#4dbd74'
        }
        const red = {
            color: '#ff0000'
        }
        return (
            <section style={{ "minHeight": "100px", "marginTop": "50px", "backgroundColor": "#f9f9f9", "borderBottom": "1px solid #ddd", "borderTop": "1px solid #ddd" }}>
                <div className="container">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ "width": "150px" }}>
                                    <div className="thumb" >
                                        <img src={this.props.restaurant.imageUrl} alt="" style={{ width: '120px' }} />
                                    </div>
                                </td>
                                <td>
                                    <h3>{this.props.restaurant.name}</h3>
                                    <div>
                                        {
                                            this.props.restaurant.id ? this.props.restaurant.cuisines.map((id) => {
                                                return <em key={id}>{this.getCuisineName(id) ? this.getCuisineName(id).name : ''} &nbsp;&nbsp;</em>
                                            }) : ''
                                        }
                                    </div>
                                    {/* {this.props.restaurant.address} */}
                                    <p style={openStatus === 'Closed' ? red : green}>{openStatus}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </section>
        );
    }
}

export default connect(state => state)(RestaurantBanner);