import axios from 'axios';

const baseURL = 'https://api.ampcome.com/api';
const accessToken = 'iyvclEkLFYAlBHW5O3aJiXqAQuwEZR5IWshjbweLtHbuA1MGjRdLEICmq6xGfCkk';

export const registerUser = (phoneno, email, name) => {
    const api = "/gokhana/restaurantcustomer";
    return axios.post(`${baseURL}${api}`, { phoneno, email, name }, {
        headers: { "Authorization": accessToken }
    }).then(res => res).catch(e => console.log(e));
}

export const checkUserExist = (phoneno) => {
    const api = `/gokhana/restaurantcustomer/exists?phoneno=${phoneno}&clientapp=gokhanacustomerweb`;
    return axios.get(`${baseURL}${api}`).then(res => res).catch(e => e);
}

export const sendOTP = (phoneno, email) => {
    const api = "/gokhana/sendotp";

    return axios.post(`${baseURL}${api}`, { phoneno, email }, {
        headers: { "Authorization": accessToken }
    }).then(res => res).catch(e => console.log(e));
}

export const verifyOTP = (phoneno, otp) => {
    const api = "/gokhana/verifyotp";

    return axios.post(`${baseURL}${api}`, { phoneno, otp, includeuser: true }, {
        headers: { "Authorization": accessToken, "Content-Type": "application/json" }
    }).then(res => res);
}

export const verifyPassword = (phoneno, password) => {
    const api = "/gokhana/restaurantcustomer/login";

    return axios.post(`${baseURL}${api}`, { phoneno, password, includeuser: true }, {
        headers: { "Authorization": accessToken, "Content-Type": "application/json" }
    }).then(res => res);
}

export const resetPassword = (newpassword, accountid) => {
    const api = "/gokhana/restaurantcustomer/resetpassword";

    return axios.post(`${baseURL}${api}`, { newpassword, accountid }, {
        headers: { "Authorization": accessToken, "Content-Type": "application/json" }
    }).then(res => res);
}

export const getGokash = (id, token) => {
    const api = `/gokhana/restaurantcustomer/${id}/gokashcredit?clientapp=gokhanacustomerweb`;

    return axios.get(`${baseURL}${api}`, {
        headers: { "Authorization": token }
    }).then(res => res);
}