import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';

import { authenticateUser, setGokashBackup, setGokashDiscount, setPerkInfo, setCompanyInfo, getSetCustDetails } from './../../actions/customerActions';
import { resetCustomer, emptyCart } from './../../actions/orderActions';
import { setDisabledItems } from './../../actions/itemActions';
import { setLocation } from './../../actions/locationActions';

class Logout extends React.Component {
    componentDidMount() {
        const { cookies } = this.props;
        this.props.authenticateUser(false);
        if (this.props.restaurant.id == '5ece3e3280184677c45b5075') {
            this.props.history.push('/restaurant/5ece3e3280184677c45b5075');
        }
        else this.props.history.push('/');

        cookies.remove('id', { path: '/' });
        cookies.remove('name', { path: '/' });
        cookies.remove('email', { path: '/' });
        cookies.remove('phoneno', { path: '/' });
        cookies.remove('token', { path: '/' });
        cookies.remove('gokash', { path: '/' });
        cookies.remove('userid', { path: '/' });
        cookies.remove('companyInfo', { path: '/' });


        this.props.resetCustomer();
        this.props.getSetCustDetails({});
        this.props.emptyCart();
        this.props.setDisabledItems([]);
        this.props.setLocation({});
        this.props.setGokashBackup([]);
        this.props.setGokashDiscount([]);
        this.props.setPerkInfo({});
        this.props.setCompanyInfo({});
    }

    render() {
        return (
            <div></div>
        );
    }
}

const mapDispatchToProps = {
    authenticateUser,
    resetCustomer,
    emptyCart,
    setLocation,
    setDisabledItems,
    setGokashDiscount,
    setGokashBackup,
    setPerkInfo,
    setCompanyInfo,
    getSetCustDetails
};

export default withCookies(connect(state => state, mapDispatchToProps)(Logout));