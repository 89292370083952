const cartReducer = (state = [], { type, data }) => {
    switch (type) {
        case 'OPEN_CLOSE_CART':
            return {
                ...state,
                isCartOpen: data
            };
        case 'SHOW_BUTTONS':
            return {
                ...state,
                showButtons: data
            };
        default:
            return state;

    }
};

export default cartReducer;