import React from 'react';

export default () => {
    return (
        <div className="container margin_60_35">
            <div className="row" id="contacts">
                <div className="col-md-6 col-sm-6">
                    <div className="box_style_2">
                        <h2 className="inner">Customer service</h2>
                        <p className="add_bottom_30">Adipisci conclusionemque ea duo, quo id fuisset prodesset, vis ea agam quas. <strong>Lorem iisque periculis</strong> id vis, no eum utinam interesset. Quis voluptaria id per, an nibh atqui vix. Mei falli simul nusquam te.</p>
                        <p><a href="tel://004542344599" className="phone"><i className="icon-phone-circled"></i>  +45 423 445 70</a></p>
                        <p className="nopadding"><a href="mailto:customercare@quickfood.com"><i className="icon-mail-3"></i> customercare@quickfood.com</a></p>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6">
                    <div className="box_style_2">
                        <h2 className="inner">Restaurant Support</h2>
                        <p className="add_bottom_30">Quo ex rebum petentium, cum alia illud molestiae in, pro ea paulo gubergren. Ne case constituto pro, ex vis delenit complectitur, per ad <strong>everti timeam</strong> conclusionemque. Quis voluptaria id per, an nibh atqui vix.</p>
                        <p><a href="tel://004542344599" className="phone"><i className="icon-phone-circled"></i>  +45 423 445 99</a></p>
                        <p className="nopadding"><a href="mailto:customercare@quickfood.com"><i className="icon-mail-3"></i> support@quickfood.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}