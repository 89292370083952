import React, { Component } from 'react';
import { getGokash, getGokashTransactions } from './../../utils/CustomerLibrary';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { setGokash, setGokashTransaction } from './../../actions/customerActions';
import moment from 'moment';
import Pagination from "react-js-pagination";
require("bootstrap/dist/css/bootstrap.min.css");


class Gokash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            countPerPage: 7
        };
        this.gokashBalance();
        this.gokashTransaction();
    }

    gokashBalance = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getGokash(cookie.id, cookie.token).then(res => {
                if (res) { res.data ? this.props.setGokash(res.data) : 0 };
            })
    }

    gokashTransaction = () => {
        const cookie = this.props.cookies.cookies;
        if (!_.isEmpty(cookie) && cookie.token)
            getGokashTransactions(cookie.id, cookie.token).then(res => {
                this.props.setGokashTransaction(res.data)
            })
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }

    render() {
        const { gokashTransaction } = this.props;
        const { activePage, countPerPage } = this.state;
        let filteredGokashTransaction = gokashTransaction ? (gokashTransaction.length ? gokashTransaction.filter((gokash, index) => (index >= ((activePage * countPerPage) - countPerPage) && (index < countPerPage * activePage))) : '') : '';

        const gokashCss = {
            textAlign: "center",
            fontWeight: 'bold'
        };
        const greenCircle = {
            height: '70px',
            width: '70px',
            backgroundColor: 'green',
            borderRadius: '50%',
            padding: '4px',
            display: 'inline-block',
            marginLeft: '25px'
        }
        const redCircle = {
            height: '70px',
            width: '70px',
            backgroundColor: 'red',
            borderRadius: '50%',
            padding: '4px',
            display: 'inline-block',
            marginLeft: '25px'
        }
        const paginationCss = {
            float: "right"
        };
        return (
            <div>
                <div style={gokashCss}>
                    <h2 style={{ margin: '0' }}>Gokash Transactions </h2>
                    <h4>Balance: ₹{this.props.gokash > 0 ? this.props.gokash.toFixed(2) : 0}</h4>
                    <hr />
                </div>
                {
                    filteredGokashTransaction && filteredGokashTransaction.map((txn, index) => (
                        <div key={index} className="row" style={{ marginBottom: '20px' }}>
                            <div className="col-md-4 col-xs-2" style={txn.transactiontype === 'credit' ? greenCircle : redCircle}>
                                <h6 style={{ color: 'white', textAlign: "center", padding: '4px', margin: '0px', marginTop: '10px' }}>{txn.transactiontype}</h6>
                                <h6 style={{ color: 'white', textAlign: "center", padding: '4px', margin: '0px' }}>₹{Math.round(txn.amount)}.00</h6>
                            </div>
                            <div className="col-md-9 col-xs-8 col-sm-9">
                                <h6 style={{ padding: '3px', margin: '0px', marginTop: '4%' }}>Initiated On {moment(txn.initiatedat).format('LLL')}</h6>
                                <h6 style={{ padding: '3px', fontWeight: 'bold', margin: '0px' }}>{txn.name}</h6>
                            </div>
                        </div>
                    ))
                }
                <div style={paginationCss}>
                    <Pagination
                        pageRangeDisplayed={7}
                        activePage={activePage}
                        itemsCountPerPage={countPerPage}
                        totalItemsCount={gokashTransaction ? gokashTransaction.length : 0}
                        onChange={this.handlePageChange}
                    /></div>
            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        gokash: state.customer.gokash,
        gokashTransaction: state.customer.gokashTransactions
    }
}
const mapDispatchToProps = {
    setGokash,
    setGokashTransaction
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Gokash));