const customerReducer = (state = [], { type, data }) => {
    switch (type) {
        case 'AUTHENTICATE_USER':
            return { ...state, isAuthenticated: data };
        case 'SELECT_TAB':
            return {
                ...state,
                selecttab: {
                    orders: data === 'orders',
                    profile: data === 'profile',
                    gokash: data === 'gokash',
                    gokashplus: data === 'gokashplus'
                }
            }
        case 'SET_SELECTED_ORDER':
            return {
                ...state,
                selectedOrder: data
            }
        case 'GET_SET_CUSTOMER_DETAILS':
            return {
                ...state,
                updateCustomerDetails: data
            }
        case 'SET_COMPANY_INFO':
            return {
                ...state,
                companyInfo: data
            }
        case 'SET_PERK_INFO':
            return {
                ...state,
                perkInfo: data
            }
        case 'SET_GOKASH':
            return {
                ...state,
                gokash: data.credit
            }
        case 'SET_GOKASH_PLUS':
            return {
                ...state,
                gokashPlus: data.credit
            }
        case 'SET_LATEST_GOKASH':
            return {
                ...state,
                latestgokash: data.credit
            }

        case 'SET_GOKASH_DISCOUNT':
            return {
                ...state,
                gokashDiscount: data
            }
        case 'SET_GOKASH_DISCOUNT':
            return {
                ...state,
                perkInfo: data
            }

        case 'SET_GOKASH_CONSUMED':
            return {
                ...state,
                discountfromcredit: data
            }

        case 'SET_GOKASH_BACKUP':
            return {
                ...state,
                gokashBackup: data
            }
        case 'SET_COMPANY_INFO':
            return {
                ...state,
                companyInfo: data
            }
        case 'SET_GOKASH_CREDIT_BACK':
            return {
                ...state,
                gokashCreditBack: data
            }

        case 'SET_GOKASH_TRANSACTION':
            return {
                ...state,
                gokashTransactions: data
            }
        case 'SET_GOKASH_PLUS_TRANSACTION':
            return {
                ...state,
                gokashPlusTransactions: data
            }
        case 'SET_EMAIL':
            return {
                ...state,
                updateCustomerDetails: {
                    ...state.updateCustomerDetails,
                    email: data
                }
            }
        case 'SET_NAME':
            return {
                ...state,
                updateCustomerDetails: {
                    ...state.updateCustomerDetails,
                    name: data
                }
            }
        case 'SET_EMPID':
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    employeeid: data
                }
            }
        case 'SET_DOMAIN':
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    workemaildomain: data
                }
            }
        case 'SET_WORKEMAIL':
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    workemail: data
                }
            }
        case 'SET_CUBICAL':
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    cubicle: data
                }
            }
        case 'SET_FLOOR':
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    floor: data
                }
            }
        case 'SET_BUILDING':
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    bulding: data
                }
            }
        case 'TOGGLE_ISUPDATED':
            return {
                ...state,
                isUpdated: data
            }
        case 'SET_LOGIN_TYPE':
            return {
                ...state,
                loginType: data
            }
        default:
            return state;
    }
}

export default customerReducer;