import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { css } from 'glamor';

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
        toastId1;
        toastId2;
        isConnected;
        state = {
            isDisconnected: false
        }
        // componentWillMount() {
        //     this.isConnected = false;
        // }
        componentDidMount() {
            this.handleConnectionChange();
            window.addEventListener('online', this.handleConnectionChange);
            window.addEventListener('offline', this.handleConnectionChange);
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.handleConnectionChange);
            window.removeEventListener('offline', this.handleConnectionChange);
        }

        notify = (msg, net) => {
            if (net === 0) {
                if (!toast.isActive(this.toastId1)) {
                    toast.dismiss(this.toastId1);
                    toast.dismiss(this.toastId2);
                    this.toastId1 = toast.error(msg, {
                        className: css({
                            marginTop: '170px',
                            fontSize: '16px'
                        }),
                        autoClose: false
                    });
                }
            } else {
                if (!toast.isActive(this.toastId2)) {
                    toast.dismiss(this.toastId1);
                    toast.dismiss(this.toastId2);
                    this.toastId2 = toast.success(msg, {
                        className: css({
                            marginTop: '170px',
                            fontSize: '16px'
                        }),
                        autoClose: 3000
                    });
                }
            }
        }

        dismiss = () => toast.dismiss(this.toastId1);

        handleConnectionChange = () => {
            const condition = navigator.onLine ? 'online' : 'offline';
            if (condition === 'online') {
                const webPing = setInterval(
                    () => {
                        fetch('//google.com', {
                            mode: 'no-cors',
                        })
                            .then(() => {
                                this.setState({ isDisconnected: false }, () => {
                                    this.dismiss();
                                    setTimeout(() => {
                                        if (this.isConnected) this.notify('Back Online', 1);
                                    }, 700);
                                    return clearInterval(webPing)
                                });
                            }).catch(() => this.setState({ isDisconnected: true }))
                    }, 2000);
                return;
            }

            return this.setState({ isDisconnected: true });
        }

        render() {
            const { isDisconnected } = this.state;
            return (
                <div>
                    {isDisconnected &&
                        // (
                        (this.notify('Internet connection lost, check connection and reload', 0),
                            this.isConnected = true)
                        // <div className="internet-error">
                        //     <p>Internet connection lost, check connection and reload</p>
                        // </div>
                        // )
                    }
                    <ComposedComponent {...this.props} />
                </div>
            );
        }
    }

    return NetworkDetector;
}