import loadjs from 'loadjs';

export const loadJsLibraries = () => {
    loadjs('/js/modernizr.js', () => {});
    loadjs('/js/common_scripts_min.js', () => {});
    loadjs('/js/cat_nav_mobile.js', () => {});
    loadjs('/js/ion.rangeSlider.js', () => {});
    loadjs('/js/theia-sticky-sidebar.js', () => {});
    loadjs('/js/bootstrap3-wysihtml5.min.js', () => {});
    loadjs('/js/dropzone.min.js', () => {});
    loadjs('/js/functions.js', () => {});
    loadjs('/js/custom.js', () => {});
    loadjs('/js/tabs.js', () => {});
};