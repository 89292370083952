import React from 'react';
import {withCookies} from 'react-cookie';

class OrderUserInfo extends React.Component {
    render() {
        const {cookies} = this.props.cookies;
        return (
            <div className="col-md-6">
                <div className="box_style_2" id="order_process">
                    <h2 className="inner">Your Contact Details</h2>
                    <div className="form-group">
                        <label>Name</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="firstname_order" 
                            value={cookies.name === 'null' ? '-' : cookies.name}
                            disabled
                        />
                    </div>

                    <div className="form-group">
                        <label>Mobile</label>
                        <input type="text" id="tel_order" className="form-control" value={cookies.phoneno} disabled/>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" id="email_booking_2" className="form-control" value={cookies.email === 'null' ? '-' : cookies.email} disabled/>
                    </div>

                </div>
            </div>
        );
    }
}

export default withCookies(OrderUserInfo);