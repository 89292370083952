export const setSelectedCuisine = (data) => ({
    type: 'SET_SELECTEDCUISINE',
    data
});

export const removeSelectedCuisine = (data) => ({
    type: 'REMOVE_SELECTEDCUISINE',
    data
});


export const toggleFoodCourt = (data) => ({
    type: 'TOGGLE_FOODCOURT',
    data
});

export const toggleVegOnly = (data) => ({
    type: 'TOGGLE_VEGONLY',
    data
});

export const updateDistance = (data) => ({
    type: 'UPDATE_DISTANCE',
    data
});

export const setFoodCourtId = (data) => ({
    type: 'SET_FOODCOURTID',
    data
});

export const setFoodCourtName = (data) => ({
    type: 'SET_FOODCOURTNAME',
    data
});

export const setCentralizedBilling = (data) => ({
    type: 'SET_CENTRALIZED_BILLING',
    data
});