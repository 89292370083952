import React from 'react';
import paytmImg from './../../images/web-images/paytm.png';
import amazonIcon from './../../images/web-images/amazon.png';
import gokashPlusIcon from './../../images/gokashpluswallet.png';
import { connect } from 'react-redux';
import { setwallet } from '../../actions/paymentActions';

class Wallet extends React.Component {
    render() {
        const { wallet } = this.props.paymentoptions;
        const { gokashplus } = wallet;
        let plusBalance = false;
        let showError = false;
        (wallet.isActive && gokashplus.isActive) ? plusBalance = true : false;
        if (plusBalance && (this.props.gokashPlus < this.props.order.totalprice)) {
            showError = true;
        }
        return (
            <div>
                {/* {(this.props.customer.isAuthenticated) &&
                    (<React.Fragment> <div className="row" style={{ marginBottom: '20px' }}>
                        <div>
                            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8" style={{ textAlign: 'center', width: '57.66%' }}>
                                <input
                                    type="radio"
                                    name="wallet"
                                    {...wallet.gokashplus.isActive ? { checked: true } : {}}
                                    onChange={() => this.props.setwallet('gokashplus')}
                                    style={{ verticalAlign: 'middle', height: '15px', width: '15px' }}
                                />
                                <img src={gokashPlusIcon} style={{ marginLeft: '10px', height: '30px' }} />
                                <span>Gokash Plus Wallet</span><br />
                                <p style={{ margin: '0px 0px 0px 36%', fontSize: '9.5px' }}>Available Balance : &#8377;<strong>{this.props.gokashPlus > 0 ? this.props.gokashPlus.toFixed(2) : 0}</strong></p>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ textAlign: 'left', width: '42.44%' }}>
                                {showError && <p style={{ color: 'red' }}>Recharge your wallet to pay with Gokash+</p>}
                            </div>
                        </div>
                    </div>
                        <hr /></React.Fragment>)} */}
                <div className="row" style={{ marginBottom: '20px' }}>
                    <div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ textAlign: 'center' }}>
                            <input
                                type="radio"
                                name="wallet"
                                {...wallet.paytm.isActive ? { checked: true } : {}}
                                onChange={() => this.props.setwallet('paytm')}
                                style={{ verticalAlign: 'middle', height: '15px', width: '15px' }}
                            />
                            <img src={paytmImg} style={{ marginLeft: '10px', height: '30px' }} />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ textAlign: 'center' }}>
                            <input
                                type="radio"
                                name="wallet"
                                {...wallet.amazonpay.isActive ? { checked: true } : {}}
                                onChange={() => this.props.setwallet('amazonpay')}
                                style={{ verticalAlign: 'middle', height: '15px', width: '15px' }}
                            />
                            <img src={amazonIcon} style={{ marginLeft: '10px', height: '30px' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        paymentoptions: state.paymentpage.paymentoption,
        gokashPlus: state.customer.gokashPlus,
        order: state.order,
        customer: state.customer
    }
}

export default connect(mapStateToProps, { setwallet })(Wallet);