import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

const baseURL = 'https://api.ampcome.com/api';
const accessToken = 'iyvclEkLFYAlBHW5O3aJiXqAQuwEZR5IWshjbweLtHbuA1MGjRdLEICmq6xGfCkk';

export const fetchCuisines = (api) => {
    let cuisines = [];

    return axios.get(`${baseURL}${api}`, {
        headers: { "Authorization": accessToken }
    })
        .then((result) => {
            result.data.forEach((cuisine) => {
                cuisines = [
                    ...cuisines,
                    {
                        id: cuisine.id,
                        name: cuisine.name
                    }
                ]
            });
            return cuisines;
        })
        .catch(e => console.log(e));
}

const arrayStringReprestation = (arr) => {
    let str = "[";
    arr.forEach((id) => {
        str = `${str}"${id}",`;
    });
    return `${str.slice(0, -1)}]`;
}

export const fetchRestaurants = (api, lat, lng, maxDistance, category, limit, cuisineIds, outletTypes, vegonly) => {
    let restaurants = [];
    let outlets = [];
    let foodCourt = {};
    let foodCourts = [];
    let geopoint;
    outletTypes = outletTypes ? `&outlettypes=["foodcourt"]` : `&outlettypes=["restaurant","foodcourt"]`;

    const cuisineField = (cuisineIds.length !== 0) ? `&cuisineids=${arrayStringReprestation(cuisineIds)}` : '';

    limit = limit ? `&limit=${limit}` : '';
    vegonly = vegonly ? '&vegonly=true' : '';
    geopoint = (lat && lng && maxDistance) ? `&geopoint={"near":{"lat":${lat},"lng":${lng}},"maxDistance":${maxDistance},"unit":"kilometers"}` : '';
    let params = `categories=["${category}"]${outletTypes}&clientapp=gokhanacustomerweb${geopoint}${limit}${cuisineField}${vegonly}`;
    const url = `${baseURL}${api}?${params}`;
    return axios.get(url, {
        headers: {
            "Authorization": accessToken,
            "Cache-Control": "no-cache"
        }
    })
        .then((result) => {
            if (result.data.records[0]) {
                result.data.records[0].records.forEach((data) => {
                    if (data.entityId == "5825d3e5f009c4bc37fd7ba1") {
                        outlets = [...outlets, createOutlet(data)];
                    } else if (data.entityId == "58ba6d7387613c1a410c30bf") {
                        foodCourt = {};

                        foodCourt.entityName = 'foodcourt';
                        foodCourt.id = data.id;
                        foodCourt.name = data.name;
                        foodCourt.address = data.address;
                        foodCourt.imageUrl = data.imageUrl;
                        foodCourt.centralizedbilling = data.centralizedbilling;
                        data.distance ? (foodCourt.distance = (data.distance / 1000).toFixed(2) + ' Km(s) away') : '';
                        foodCourt.outlets = (data.foodoutlets && data.foodoutlets.length) ? (
                            data.foodoutlets.map((outlet) => {
                                return createOutlet(outlet);
                            })
                        ) : [];

                        foodCourts = [...foodCourts, foodCourt];
                    }
                });

                restaurants = [...restaurants, { entityName: 'general', outlets }, ...foodCourts];
                return restaurants;
            } else {
                return [];
            }

        })
        .catch(e => {
            console.log(e);
            return [];
        });
}

const createOutlet = (data) => {
    let outlet = {};
    let cuisines = [];
    outlet.id = data.id;
    outlet.name = data.name;
    outlet.address = data.address;
    outlet.imageUrl = data.imageUrl;
    outlet.openinghours = [];
    outlet.open = false;
    outlet.isCloudstore = data.is_cloudstore ? data.is_cloudstore : false;
    data.distance ? (outlet.distance = (data.distance / 1000).toFixed(2) + ' Km(s) away') : '';

    data.cuisines && data.cuisines.forEach((cuisineData) => {
        cuisineData !== null ?
            cuisines = [...cuisines, cuisineData.id ? cuisineData.id : cuisineData]
            : ''
    });
    outlet.cuisines = cuisines;

    data.open && (outlet.open = true);

    data.openinghours && data.openinghours.forEach((openingHr) => {
        if (moment().format('dddd').toLowerCase() === openingHr.day.toLowerCase()) {
            outlet.openinghours.push(`${moment(openingHr.from, ['HH:mm']).format('hh:mm A')} - ${moment(openingHr.to, ['HH:mm']).format('hh:mm A')}`);
        }
    });

    return outlet;
}

export const fetchMenu = (restId) => {
    const api = `/gokhana/restaurant/${restId}/categorizedmenu`;
    const url = `${baseURL}${api}`;

    let menu = [];
    let category = {};

    return axios.get(url, {
        headers: { "Authorization": accessToken }
    }).then((result) => {
        result.data.forEach(cat => {
            category = {};
            category.id = cat.id;
            category.name = cat.name;
            category.subcategories = cat.subcategories ? createSubCategories(cat.subcategories) : [];
            category.menuitems = cat.menuitems ? createMenuItems(cat.menuitems) : [];
            menu = [...menu, category]
        });
        return menu;
    });
}

const createSubCategories = (subCatData) => {
    let subCategories = [];
    let subCategory = {};

    subCatData.forEach(subCat => {
        subCategory = {};
        subCategory.id = subCat.id;
        subCategory.name = subCat.name;
        subCategory.menuitems = subCat.menuitems ? createMenuItems(subCat.menuitems) : [];

        subCategories = [...subCategories, subCategory];
    });

    return subCategories;
}

const createMenuItems = (menuItemData) => {
    let menuItems = [];
    let menuItem = {};

    menuItemData.forEach(item => {
        menuItem = {};
        menuItem.id = item.id;
        menuItem.name = item.name;
        menuItem.price = item.price;
        menuItem.isVeg = item.isVeg;
        item.parcel_charge_type ? menuItem.parcelchargetype = item.parcel_charge_type : "";
        item.parcel_charge_value ? menuItem.parcelchargevalue = item.parcel_charge_value : 0;
        menuItem.addonchoicetype = item.addonchoicetype || '';
        menuItem.addonitems = (!!item.addonitems && item.addonitems.length !== 0) ? item.addonitems : [];
        menuItem.customeitems = (!!item.customeitems && item.customeitems.length !== 0) ? item.customeitems : [];
        menuItem.taxgroup = getTaxes(item.taxgroup);

        menuItems = [...menuItems, menuItem];
    });

    return menuItems;
}

const getTaxes = (taxes) => {
    let taxgroup = {};

    if (taxes) {
        taxgroup.id = taxes.id;
        taxgroup.name = taxes.name;
        taxgroup.items = taxes.items;
    }

    return taxgroup;
}

export const fetchRestaurant = (restId) => {
    const api = `/gokhana/restaurant/${restId}?clientapp=gokhanacustomerweb&loadrelationrecord=true`;
    const url = `${baseURL}${api}`;

    return axios.get(url, {
        headers: { "Authorization": accessToken }
    }).then(result => {
        return result;
    });
}

export const fetchFoodcourt = (id) => {
    const api = `/gokhana/foodcourt/${id}?fetchoutlet=true`;
    const url = `${baseURL}${api}`;
    let foodCourt = {};
    let activeOutlets = [];

    return axios.get(url, {
        headers: { "Authorization": accessToken }
    }).then(res => {
        if (!_.isEmpty(res.data)) {
            foodCourt = {};
            activeOutlets = res.data.foodoutlets.filter(f => f.active);
            foodCourt.entityName = 'foodcourt';
            foodCourt.id = res.data.id;
            foodCourt.name = res.data.name;
            foodCourt.address = res.data.address;
            foodCourt.imageUrl = res.data.imageUrl;
            foodCourt.centralizedbilling = res.data.centralizedbilling;
            res.data.distance ? (foodCourt.distance = (res.data.distance / 1000).toFixed(2) + ' Km(s) away') : '';

            foodCourt.outlets = activeOutlets ? (
                activeOutlets.map((outlet) => {
                    return createOutlet(outlet);
                })
            ) : [];
            return [foodCourt];
        } else {
            return [];
        }



    });
}